import { media, style } from 'typestyle';

const SlideDown = style(
  {
    transformOrigin: 'bottom',
    transitionDuration: '.7s',
    transitionProperty: 'transform',
    $nest: {
      '&[open]': {
        transform: 'translateY(0px)',
        $nest: {
          '@media (prefers-reduced-motion: no-preference)': {
            $nest: {
              '@starting-style': {
                transform: 'translateY(-100vh)',
              },
            },
          },
        },
      },
    },
    $debugName: 'DialogAnimations_SlideDown',
  },
  media({ minWidth: 768 }, {}),
);

const Opacity = style({
  transition: 'opacity 250ms',
  opacity: 0,
  $nest: {
    '&[open]': {
      opacity: 1,
      $nest: {
        '@starting-style': {
          opacity: 0,
        },
      },
    },
  },
});

export const DialogAnimations = {
  Opacity,
  SlideDown,
};
