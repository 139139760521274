import { style } from 'typestyle';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import color = Tokens.color;
import styled from 'styled-components';

const ChevronRightURISVG =
  "url('data:image/svg+xml;utf8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2224%22 height=%2224%22 viewBox=%220 0 24 24%22 fill=%22none%22%3E%3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 d=%22M7.8295 4.4545C8.26884 4.01517 8.98116 4.01517 9.4205 4.4545L16.1705 11.2045C16.6098 11.6438 16.6098 12.3562 16.1705 12.7955L9.4205 19.5455C8.98116 19.9848 8.26884 19.9848 7.8295 19.5455C7.39017 19.1062 7.39017 18.3938 7.8295 17.9545L13.784 12L7.8295 6.0455C7.39017 5.60616 7.39017 4.89384 7.8295 4.4545Z%22 fill=%22%23999999%22/%3E%3C/svg%3E')";

export const ActionSheetClassStyle = style({
  $debugName: 'action-sheet-style',
  ['--backdrop-opacity' as string]: '0.7',
  $nest: {
    '& .action-sheet-group': { borderRadius: '6px 6px 0 0' },
    '& .action-sheet-icon': { marginRight: '12px' },
    '& .action-sheet-button:not(:last-child)': { borderBottom: '1px solid #e6e6e6' },
    '& .action-sheet-button': {
      fontWeight: 600,
      position: 'relative',
      $nest: {
        '&::before': {
          content: ChevronRightURISVG,
          position: 'absolute',
          fontSize: '20px',
          fontWeight: 100,
          color: '#999999',
          right: '16px',
          top: '25%',
        },
      },
    },
  },
});

export const DangerButtonClassStyle = style({
  $debugName: 'danger-button-style',
  $nest: {
    '&': {
      color: `${color.danger}!important`,
    },
    '&:hover': {
      color: `${color.dangerHover}!important`,
    },
  },
});
export const DefaultButtonClassStyle = style({
  $debugName: 'default-button-style',
  $nest: {
    '&': {
      color: `${color.primary}!important`,
    },
    '&:hover': {
      color: `${color.primaryHover}!important`,
    },
  },
});

export const DialogWrapper = styled.dialog`
  background-color: ${color.white};
  position: absolute;
  z-index: 1000;
  border: 1px solid ${color.border};
  box-shadow: 0 4px 12px #00000026;
  border-radius: 6px;
  outline: none;
  min-width: 300px;
  margin: unset;
  left: 55%;
  top: 70%;
`;
