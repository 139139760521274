import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

let id_increment = 0;
type RenderComponent = React.ComponentType<{}>;
type HookStore = Record<string, HookRenderRegistration[]>;

const hooks = create(subscribeWithSelector<HookStore>(() => ({})));

interface HookableRender {
  identifier: string;
}

// Renders all render hooks associated with a given identifier
export function HookableRender({ identifier }: HookableRender) {
  const registrations = hooks((state) => state[identifier]);

  if (!registrations) return null;

  return <>{registrations.map((component) => component.render())}</>;
}

/**
 * Registers a new render hook.
 *
 * @param identifier a given identifier for where this hook should be rendered
 * @param component the component to render
 * @param selfIdentifier an unique string used to identify the registration caller, this is used to prevent duplicated renders hooks when using live reloading
 * @returns registration object
 */
export function registerRenderHook(identifier: string, component: RenderComponent, selfIdentifier: string): HookRenderRegistration {
  const newRegistration = new HookRenderRegistration(component, selfIdentifier);

  hooks.setState((state) => {
    const registrations = state[identifier] ?? [];

    console.log('registrations', registrations);

    const duplicateIndex = registrations.findIndex((r) => r.selfId === selfIdentifier);
    if (duplicateIndex !== -1) {
      console.log(`Backbone/HookableRenders: Replaced render with self id ${selfIdentifier}`);
      registrations[duplicateIndex] = newRegistration;
    } else {
      registrations.push(newRegistration);
    }

    return {
      [identifier]: registrations,
    };
  });

  return newRegistration;
}

class HookRenderRegistration {
  public readonly id: number;
  public readonly selfId: string;
  private component: RenderComponent;

  constructor(component: RenderComponent, selfId: string) {
    this.selfId = selfId;
    this.id = id_increment++;
    this.component = component;
  }

  /**
   * Renders this render hook
   *
   * @returns Rendered react component
   */
  public render(): JSX.Element {
    return <this.component key={`hookable-render-${this.id}`} />;
  }
}
