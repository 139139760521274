import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useProgramList } from '../program/useProgramList';
import { ProgramCard } from './ProgramCard';
import { Wrap } from '../../common/utils';
import { FadeInUpDiv } from './HomeScreen.styles';
import { GetButton } from '../program/SmartBanner.styles';
import { I18N } from '../../internationalization/AppText';
import { tracker } from '@visikon/tracker/src';
import { setShowArchivedPrograms } from '../../actions/resourcesActions';
import { Fader } from '../../common/Fader';
import * as Styles from './HomeScreen.styles';
import { IState } from '../../reducers/reducer';
import { ROOT_ROUTE } from '../../components/MainContent';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import { Unstyled } from '../../components/Unstyled';

type ProgramlistProps = {
  showMergeDialog: () => void;
};

export const Programlist = ({ showMergeDialog }: ProgramlistProps) => {
  const history = useHistory();
  const programListContainer = React.useRef<HTMLDivElement>(null);
  const showArchived = useSelector<IState, boolean>((state) => !!state.resources.showArchivedPrograms);
  const { programList } = useProgramList();
  const dispatch = useDispatch();
  const archivedPrograms = programList
    .filter((p) => p.archived)
    .map((program, index) => (
      <li key={program.id}>
        <ProgramCard program={program} index={index} />
      </li>
    ));
  const hasArchivedPrograms = archivedPrograms.length > 0;
  const [activePopup, setActivePopup] = React.useState<number | null>(null);
  const programCardList = programList
    .filter((p) => !p.archived)
    .map((program, index) => (
      <li key={program.id}>
        <ProgramCard
          program={program}
          index={index}
          activePopup={activePopup}
          changeActivePopup={(dismiss) => {
            if (dismiss || activePopup === index) setActivePopup(null);
            else setActivePopup(index);
          }}
        />
      </li>
    ));
  const archivedHeader = React.useRef<HTMLDivElement>(null);

  const [isProgramListAtBottom, setIsProgramListAtBottom] = React.useState(false);
  const hasActivePrograms = programCardList.length > 0;

  // @ts-ignore
  const showAnimation = history.location.state?.from === ROOT_ROUTE;

  React.useEffect(() => {
    function handleScroll() {
      const programList = programListContainer?.current;
      const rect = programList?.getBoundingClientRect();

      const isAtBottom = rect && rect.bottom >= window.innerHeight - 80;
      setIsProgramListAtBottom(!!isAtBottom);
    }

    window.addEventListener('resize', handleScroll);
    handleScroll();
    return () => window.removeEventListener('resize', handleScroll);
  }, [programListContainer.current, showArchived]);

  const addProgramsButton = (isProgramListAtBottom: boolean) => (
    <Wrap
      expression={showAnimation}
      decorator={(children) => (
        <FadeInUpDiv timeout={1000} showAnimation={showAnimation} halfway>
          {children}
        </FadeInUpDiv>
      )}
    >
      <Styles.AddButton isProgramListAtBottom={isProgramListAtBottom}>
        <GetButton onClick={showMergeDialog} style={{ textTransform: 'none' }}>
          <PlusIcon />
          <I18N text={'home_add_new_program_title'} />
        </GetButton>
      </Styles.AddButton>
    </Wrap>
  );

  const handleToggleArchived = () => {
    tracker.trackEvent('HomeScreen', 'ToggleArchivedPrograms', showArchived ? 'Hide' : 'Show');
    dispatch(setShowArchivedPrograms(!showArchived));
    if (!showArchived) {
      setTimeout(() => {
        if (archivedHeader.current) {
          if (archivedHeader.current.getBoundingClientRect().bottom > window.innerHeight) {
            archivedHeader.current.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 300);
    }
  };

  const noActiveProgramsDisclaimer = programCardList.length === 0 && !showArchived && (
    <Styles.FlexCenter>
      <Fader timeout={500}>
        <Styles.NoActiveProgramsContainer>
          <Styles.NoActiveProgramsTitle>
            <I18N text={'home_no_active_programs_title'} />
          </Styles.NoActiveProgramsTitle>
          <Styles.NoActiveProgramsBody>
            <I18N text={'home_no_active_programs_body'} />
          </Styles.NoActiveProgramsBody>
          <Styles.NoActiveProgramsButtons style={{}}>
            <GetButton onClick={showMergeDialog} style={{ textTransform: 'none' }}>
              <I18N text={'home_add_new_program_title'} />
            </GetButton>
            <GetButton islink={'true'} variant={'ghost'} size="small" onClick={handleToggleArchived}>
              <I18N text={'home_see_archived_programs'} />
            </GetButton>
          </Styles.NoActiveProgramsButtons>
        </Styles.NoActiveProgramsContainer>
      </Fader>
    </Styles.FlexCenter>
  );

  const programs = (
    <>
      <Styles.ProgramListContainer ref={programListContainer}>
        <Wrap
          expression={showAnimation}
          decorator={(children) => (
            <Styles.HeaderDecorator>
              <Fader timeout={50}>{children}</Fader>
            </Styles.HeaderDecorator>
          )}
        >
          {hasActivePrograms && (
            <ProgramListHeader text="home_your_programs" bold={true}>
              {hasArchivedPrograms && (
                <Styles.Archived onClick={handleToggleArchived}>
                  <I18N text={showArchived ? 'home_hide_archived' : 'home_show_archived'} />
                </Styles.Archived>
              )}
            </ProgramListHeader>
          )}
        </Wrap>
        <Wrap
          expression={showAnimation}
          decorator={(children) => (
            <Styles.ProgramCardDecorator>
              <Fader timeout={500}>{children}</Fader>
            </Styles.ProgramCardDecorator>
          )}
        >
          <Unstyled.UnorderedList>{programCardList}</Unstyled.UnorderedList>
        </Wrap>

        {showArchived && hasArchivedPrograms && (
          <>
            <ProgramListHeader ref={archivedHeader} text="home_your_archived_programs" bold={false} />
            <Unstyled.UnorderedList>{archivedPrograms}</Unstyled.UnorderedList>
          </>
        )}
      </Styles.ProgramListContainer>
      <Styles.SpaceDivider extraSpace={isProgramListAtBottom} />
      {addProgramsButton(isProgramListAtBottom)}
    </>
  );

  return noActiveProgramsDisclaimer || programs;
};

interface HeaderProps {
  text: TextKeys;
  bold: boolean;
}

const ProgramListHeader = React.forwardRef<HTMLDivElement, React.PropsWithChildren<HeaderProps>>(({ text, bold, children }, ref) => {
  return (
    <Styles.Header ref={ref}>
      <Styles.Title bold={bold}>
        <I18N text={text} />
      </Styles.Title>
      {children}
    </Styles.Header>
  );
});

const PlusIcon = () => (
  <span style={{ display: 'flex', marginRight: '10px' }} aria-hidden>
    {Styles.plusSVG}
  </span>
);
