import * as React from 'react';
import { SetDocumentTitle } from '../../internationalization/AppText';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers/reducer';
import { setShowArchivedPrograms } from '../../actions/resourcesActions';
import { SmartBanner } from '../program/SmartBanner';
import { unsetNavLink } from '../../actions/userActions';
import * as Styles from './HomeScreen.styles';
import { useProgramList } from '../program/useProgramList';
import { Programlist } from './ProgramList';
import { SetDocumentThemeColor } from '../../common/DocumentThemeColor';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import { CodeMergeModal } from './CodeMergeModal';

export const HOME_ROUTE = '/home';

export const HomeScreen = () => {
  const dispatch = useDispatch();
  const { programList } = useProgramList();
  const mergeDialogRef = React.useRef<HTMLDialogElement>(null);
  const hasArchivedPrograms = programList.some((p) => p.archived);
  const showArchived = useSelector<IState, boolean>((state) => !!state.resources.showArchivedPrograms);

  useEffect(() => {
    dispatch(unsetNavLink());
  }, []);

  useEffect(() => {
    if (showArchived && !hasArchivedPrograms) {
      dispatch(setShowArchivedPrograms(false));
    }
  }, [showArchived, programList]);

  const showMergeDialog = () => {
    mergeDialogRef.current?.showModal();
  };

  return (
    <Styles.HomeScreenContainer>
      <SetDocumentTitle text={'home_your_programs'} />
      <SetDocumentThemeColor color={Tokens.color.homeBackground} />
      <SmartBanner />
      <Styles.Container>
        <Programlist showMergeDialog={showMergeDialog} />
      </Styles.Container>

      <CodeMergeModal dialogRef={mergeDialogRef} />
    </Styles.HomeScreenContainer>
  );
};
