import { useBackbone } from '@visikon/backbone/src';
import { CustomError } from 'ts-custom-error';
import axios, { AxiosError } from 'axios';

export class HttpError extends CustomError {
  public constructor(public status: number, message?: string) {
    super(message);
  }

  public static axiosError(error: AxiosError | unknown) {
    if (axios.isAxiosError(error) && error.response?.data?.error) {
      return new HttpError(error.response.status, error.response.data.error);
    }
    return error;
  }
}

export function useApiClient() {
  const { baseURL, apiKey, client, authToken, languageCode } = useBackbone((state) => ({
    baseURL: state.hosts.core,
    apiKey: state.apiKey,
    client: state.client,
    authToken: state.authToken,
    languageCode: state.country?.languageCode,
  }));

  const headers = {
    'Vcore-API-Key': apiKey,
    'mitforlob-app-version': `${client?.version} web`,
    'vcore-language-code': languageCode,
    'Content-Type': 'application/json',
  };

  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  return axios.create({
    baseURL,
    headers,
  });
}
