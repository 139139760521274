import { Action, LanguageCode } from '@visikon/core-models/content';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { logOut, logOutSuccessful } from '../actions/authActions';
import * as Actions from '../actions/resourcesActions';
import * as AuthActions from '../actions/authActions';
import { FetchProgramPayload, fetchProgramsError, FetchProgramsPayload } from '../actions/resourcesActions';
import { videoPlayerSrc } from '../components/video/VideoCard';
import { Action as TypescriptAction } from 'typescript-fsa';
import { backbone } from '@visikon/backbone/src';
import * as Helper from './resourcesSaga.helpers';
import { WithId } from '@visikon/core-models/base';
import { HasTranslations } from '@visikon/core-models/typeUtils';
import { IState } from '../reducers/reducer';
import { CountryStorage } from '../local-storage/language-storage';
import { ProgramsStorage, StoredPrograms } from '../local-storage/programs-storage';
import { getProgramPath, resolvePreview } from '../common/selectors';
import { fetchBlobJson } from '@visikon/utils/src/utils';
import { MitforlobPack } from '../reducers/resourcesReducer';
import { getVideoInfo } from '../common/utils';
import { Country } from '@visikon/core-models/i18n/languages';
import { setProgramsData } from '../actions/userActions';

export type Content = WithId & HasTranslations & Partial<Helper.WithTypeDescriptor> & { data?: any };
export const getCurrentProgram = (state: IState) => state.resources.activeProgram;

// TODO: Replace with getTranslationFromArray() from core-models
export function getTranslation<T extends Content>(elem: T | undefined, lang: LanguageCode): Helper.Translation<T> {
  if (!elem || !elem.translations || elem.translations.length === 0) {
    return undefined;
  }

  const metaData = {
    _id: elem._id,
    typeDescriptor: elem.typeDescriptor,
  };

  // Return wanted language
  let data = elem.translations.find((t: any) => t.language === lang);

  // Fallback to english
  if (!data) {
    data = elem.translations.find((t: any) => t.language === 'en');
  }

  if (data?.data?.content instanceof Array) {
    data.data.content = data.data.content.map((cont) => getTranslation(cont, lang));
  }

  // If everything fails, return whatever we have
  return {
    ...(data ?? elem.translations[0]),
    ...metaData,
  };
}

function* fetchProgramsPreequesites(id?: string) {
  // 1. Fetch available programs from local storage or server
  const token = backbone.store.getState().authToken;
  if (!token) {
    console.log('No token, not fetching programs');
    return;
  }
  const programs: StoredPrograms = yield call(Helper.getAvailablePrograms);
  // 2. Update local storage
  yield ProgramsStorage.set(programs);

  // 2.5 update userData programsData
  yield put(setProgramsData(programs.availablePrograms.programs));

  // Return program id
  return id ?? programs.activeProgramId;
}

function* fetchPrograms(action: TypescriptAction<FetchProgramsPayload>) {
  try {
    const embedInfo = backbone.store.getState().embedInfo;
    const programId = embedInfo ? embedInfo.programId : yield call(fetchProgramsPreequesites, action.payload.id);

    //Check has preview
    const { isPreview } = yield resolvePreview(programId);
    console.log('Fetching program with id', programId);
    yield put(Actions.fetchProgram({ id: programId, showPreview: isPreview }));
  } catch (e: any) {
    if (e.message === 'No token' || e.message === 'Authentication Error') {
      yield put(logOut());
    }
    yield put(fetchProgramsError(e.message));
  }
}

function* fetchProgramSaga(action: TypescriptAction<FetchProgramPayload>) {
  console.log('fetchProgramSaga', action.payload);
  const path = yield getProgramPath(action.payload);
  console.log('Path', path);
  if (path) {
    yield put(Actions.fetchProgramFromBlob({ id: action.payload.id!, path }));
  }
}

function* fetchProgramFromBlob({ payload }: TypescriptAction<Actions.FetchProgramFromBlobPayload>) {
  // 1. Get program from BlobStorage
  const program: MitforlobPack[0] = yield call(fetchBlobJson, payload.path);
  console.log('Fetched program', program);

  // 2. Extract translation
  const translation = yield Helper.extractProgramTranslation(program);

  // 3. Update state
  yield put(Actions.fetchProgramSuccess(translation));
  yield ProgramsStorage.setActiveProgram(payload.id);
}

const SET_TIMEOUT_MS = 200;

// Actions could be dispatched like ordinary react actions, and reducers/sagas could be configured to handle them
// eslint-disable-next-line require-yield
function executeActions(actions: Action[], history, content) {
  let videoFound = false;
  const launchVideo = (videoId: string, timeout: number) => {
    const videoInfo = getVideoInfo(videoId, content);
    if (!videoFound && videoInfo) {
      videoFound = true;
      const videoUrl = videoPlayerSrc(content._id, videoId);
      setTimeout(() => history.push(videoUrl), timeout);
    }
  };
  actions.forEach((action, index) => {
    const WAIT_MS = SET_TIMEOUT_MS * index;
    switch (action.type) {
      case 'NAVIGATE': {
        setTimeout(() => history.push(action.payload), WAIT_MS);
        break;
      }
      case 'VIDEO': {
        const videoId = action.payload;
        launchVideo(videoId, WAIT_MS);
        break;
      }
      case 'VIDEO_FULLSCREEN': {
        const videoId = action.payload;
        launchVideo(videoId, WAIT_MS);
        break;
      }
      case 'TOGGLE': {
        if (action.payload === 'topmenu') {
          const currentClient = backbone.store.getState().client!;
          backbone.update({ client: { ...currentClient, type: 'embed' } });
        }
      }
    }
  });
}

function* runActions() {
  const loginProcess = yield select((state: IState) => state.auth.loginProcess);
  const { content, programInfo, history } = yield select((state) => state.resources);

  if (!programInfo?.actions || !(content?.name === programInfo.name) || !loginProcess?.startCode) {
    return;
  }

  executeActions(programInfo.actions, history, content);

  // Making sure that the actions are ran once per authentication
  yield put(AuthActions.setStartCode(''));
}

function* clearLoginProcess() {
  yield put(AuthActions.clearLoginProgress());
}

function* fetchResourcesProxy({ payload }: TypescriptAction<FetchProgramsPayload>) {
  yield put(Actions.fetchPrograms(payload));
}

function* clearResourceSagaData() {
  yield put(Actions.clearResourceContent());
}

function* fetchResourcesErrorSaga(action: TypescriptAction<string>) {
  const errorCode = action.payload;
  yield put(logOut());
  yield take(logOutSuccessful);
  yield put(Actions.setError(errorCode));
}

function* changeCountrySaga(action: TypescriptAction<Country>) {
  const country = action.payload;

  yield CountryStorage.set(country);
  backbone.update({ country });
}

export function* resourcesSaga() {
  yield takeLatest(Actions.fetchPrograms, fetchPrograms);
  yield takeLatest(Actions.fetchProgram, fetchProgramSaga);
  yield takeLatest(Actions.fetchProgramFromBlob, fetchProgramFromBlob);
  yield takeLatest(Actions.changeProgram, fetchResourcesProxy);
  yield takeLatest(Actions.fetchProgramSuccess, runActions);
  yield takeLatest(Actions.fetchProgramSuccess, clearLoginProcess);
  yield takeEvery(logOut as any, clearResourceSagaData);
  yield takeLatest(Actions.fetchProgramsError, fetchResourcesErrorSaga);
  yield takeLatest(Actions.changeCountry, changeCountrySaga);
}
