import getStore from './store';
import jwtDecode from 'jwt-decode';
import { UserInfo } from './models';
import { Session, SessionStorage } from './session-storage';

const store = getStore();

export const backbone = {
  store,
  useIsEmbedded: () => useBackbone((state) => state.embedInfo !== undefined),
  isEmbed: () => store.getState().embedInfo !== undefined,
  update: store.setState,
  setAuthToken: (session: Session) => {
    // activatedAt is not present in the token, referencedUser may be present
    const user = jwtDecode<Omit<UserInfo, 'activatedAt' | 'referencedUser'>>(session.token);

    store.setState((state) => ({
      ...state,
      authToken: session.token,
      session: session,
      user: {
        ...user,
        activatedAt: session.activatedAt,
        referencedUser: session.referencedUser,
      },
    }));

    SessionStorage.set(session);

    return user;
  },
  getLanguageCode: () => store.getState().country.languageCode,
  getToken: () => store.getState().authToken,
};

export const useBackbone = store;

export * from './hookableRenders';
export * from './dialogOrchestrator';
export * from './locationHooks';
