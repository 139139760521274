import * as React from 'react';
import { MediaUtils } from '@visikon/mytreatment/src/common/utils';

type Props = { imageUrl?: string } & React.HTMLProps<HTMLImageElement>;

export function LazyImage({ imageUrl, ...rest }: Props) {
  const url = useImagePreloader(imageUrl);
  const imgSrc = url ? url : MediaUtils.BLANK_PIXEL;

  return <img src={imgSrc} alt="" {...rest} />;
}

export function useImagePreloader(url?: string) {
  const [imageUrl, setImageUrl] = React.useState('');

  React.useEffect(() => {
    if (url) {
      MediaUtils.preloadResource(url, (canLoad) => {
        if (canLoad) setImageUrl(url);
      });
    }
  }, [url]);

  return imageUrl;
}
