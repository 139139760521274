import * as React from 'react';
import { Link } from 'react-router-dom';
import { NavigationIcon } from '../../images/icons';
import * as Styles from './NavigationBar.styles';
import { useContentKeys, useLanguage, useWholeContent } from '../../common/selectors';
import { contentConfig } from '../../common/ContentConfig';
import { i18n } from '../../internationalization/i18n';
import { IonFooter, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { AppSpecificPaths, ROOT_ROUTE, getPath } from '../../components/MainContent';
import { backbone } from '@visikon/backbone';

function useMenuKeys(): { name: string; path: string; icon: string }[] {
  const contentKeys = useContentKeys();
  const language = useLanguage();
  const content = useWholeContent();
  const programId = backbone.store.getState().program?.id;
  const getTabName = (key: string) => {
    return content[key]?.data?.menuLabel || i18n(contentConfig[key].translationKeys.menu, language);
  };

  if (!programId) return [];

  return contentKeys.map((key) => ({
    name: getTabName(key),
    path: `${programId}/${contentConfig[key].path}`,
    icon: contentConfig[key].icon,
  }));
}

const MENU_ITEM_KEY = 'MENU_ITEM_KEY';

export function NavigationBar() {
  const validTabs = useMenuKeys();
  const oldActiveTab = sessionStorage.getItem(MENU_ITEM_KEY) || '';
  const [chosenPath, setChosenPath] = useState<string>();
  const path = getPath();

  // Remember the "old" path menu item for when navigating to the video player, debug page or into a content container
  useEffect(() => {
    setChosenPath(path);
    if (AppSpecificPaths.includes(path)) {
      setChosenPath(oldActiveTab);
    } else {
      sessionStorage.setItem(MENU_ITEM_KEY, path);
    }
  }, [path]);

  return (
    <Styles.NavContainer aria-label="Menu bar">
      <IonFooter>
        <IonToolbar>
          <Styles.NavigationList>
            {validTabs.map((t, i: number) => {
              return <NavigationItem key={i} tabName={t.name} iconName={t.icon} path={t.path} active={t.path.split(ROOT_ROUTE)[1] === chosenPath} />;
            })}
          </Styles.NavigationList>
        </IonToolbar>
      </IonFooter>
    </Styles.NavContainer>
  );
}

function NavigationItem({ tabName, iconName, path, active }: { tabName: string; iconName: string; path: string; active: boolean }) {
  return (
    <Styles.NavigationListItem active={active}>
      <Link to={`/${path}`}>
        <Styles.LinkContent>
          <div className="iconWrapper">
            <NavigationIcon name={iconName} active={active} />
          </div>
          <div className="labelWrapper">{tabName}</div>
        </Styles.LinkContent>
      </Link>
    </Styles.NavigationListItem>
  );
}
