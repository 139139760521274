import { dialogDismissButton } from './Dialog.styles';
import React from 'react';
import { i18n } from '@visikon/mytreatment/src/internationalization/i18n';
import { SvgIcon } from '@visikon/mytreatment/src/images/svgIcon';
import { ReactComponent as Cross } from '../../icons/DialogCross.svg';

export function DialogDismissButton() {
  function handleDismiss(ev: React.MouseEvent<HTMLButtonElement>) {
    ev.currentTarget.closest('dialog')?.close();
  }

  return (
    <button className={dialogDismissButton} onClick={handleDismiss} aria-label={i18n('close')}>
      <SvgIcon Icon={Cross} width={12} />
    </button>
  );
}
