import * as React from 'react';
import styled from 'styled-components';
import { Spinner } from '../../common/Spinner';
import { CancelSessionButton } from './CancelSessionButton';

const Overlay = styled.div`
  z-index: 100000;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 25px;
  background-color: white;
  border-top: 1px solid #dedede;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingOverlayContainer = () => {
  return (
    <Overlay>
      <ContentWrapper>
        <Spinner />
        <CancelSessionButton timeout={5} />
      </ContentWrapper>
    </Overlay>
  );
};
