import * as React from 'react';
import { PopupContent } from './PopupContent';
import { useWindowSizeMatches, WindowSize } from '@visikon/utils/src/responsive';
import { MobileContentWrapper } from './MobileContentWrapper';

interface IProps {
  visible: boolean;
  content: JSX.Element;
  width?: number;
  height?: number;
  top?: number;
  background?: string;
  overlayAlpha?: number;
  showCloseButton?: boolean;
  confirmDialog?: JSX.Element;
  onOverlayClick?(): void;
  onResize?(width: number, height: number): void;
}

const { Medium } = WindowSize;

export const PopupScreenCenter = ({
  width,
  height,
  onOverlayClick,
  content,
  background,
  overlayAlpha,
  visible,
  showCloseButton,
  confirmDialog,
  onResize,
  top,
}: IProps) => {
  const [positionLeft, setPositionLeft] = React.useState(0);
  const [positionTop, setPositionTop] = React.useState(100);
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const isMoreThanMediumSizedWindow = !useWindowSizeMatches(Medium);

  const updatePosition = () => {
    if (onResize) {
      onResize(window.innerWidth, window.innerHeight);
    }

    const popupWidth = width || 400;
    const positionLeft = (window.innerWidth - popupWidth) / 2;

    let positionTop = top || 100; // Defaults to 100
    if (height) {
      positionTop = (window.innerHeight - height) / 2;
    }

    setPositionLeft(positionLeft);
    setPositionTop(positionTop as number);
  };

  React.useEffect(() => {
    if (visible) {
      handleOnTrigger();
    }
  }, [visible]);

  React.useEffect(() => {
    let timer1 = setTimeout(updatePosition, 300);
    if (isPopupOpen) {
      window.addEventListener('resize', updatePosition);
      window.addEventListener('keyup', listenForKeyboard);
      window.addEventListener('popstate', handleBackButton);
    }

    return () => {
      clearTimeout(timer1);
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('keyup', listenForKeyboard);
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [isPopupOpen]);

  function handleBackButton() {
    handleAwayFromOverlay();
  }

  function listenForKeyboard(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      dismissPopup();
    }
  }

  function dismissPopup() {
    setIsPopupOpen(false);
    onOverlayClick?.();
  }

  function handleOnTrigger() {
    setIsPopupOpen(true);

    // Adding a history state to enable back button functionality
    window.history.pushState({}, 'Staff');

    // Lock scroll on background
    const rootElm = document.getElementById('root');
    if (rootElm !== null) {
      rootElm.classList.add('noscroll');
    }
  }

  function handleAwayFromOverlay() {
    dismissPopup();

    // Erase history state when actual back button is not clicked
    window.history.back();

    // Unlock scroll on background
    const rootElm = document.getElementById('root');
    if (rootElm !== null) {
      rootElm.classList.remove('noscroll');
    }
  }

  if (!visible || positionLeft === undefined) {
    return null;
  }

  const popupProps = {
    positionLeft,
    positionTop: positionTop!,
    width,
    background,
    overlayAlpha,
    height,
    showCloseButton,
    handleOverlayClick: handleAwayFromOverlay,
    confirmDialog,
    content,
  };

  if (!isPopupOpen) {
    return null;
  }

  return (
    <>
      {isMoreThanMediumSizedWindow ? (
        <PopupContent {...popupProps} />
      ) : (
        <MobileContentWrapper handleBackClick={handleAwayFromOverlay} content={content} isOpen={isPopupOpen} />
      )}
    </>
  );
};
