import { useEffect, useState } from 'react';
import { LocalStorage } from '../../local-storage/startcodes-storage';
import { Action } from '@visikon/core-models/content';
import { fetchGet, fetchPost } from '@visikon/utils/src/utils';
import { useDispatch } from 'react-redux';
import { setError } from '../../actions/resourcesActions';

export type ProgramInfo = {
  name: string;
  enableDirectAccess: boolean;
  subName: string;
  organization: string;
  department: string;
  logo: { _id: string };
  error?: string;
  actions?: Action[];
};

export function useProgramInfo(startCode: string) {
  const [programInfo, setProgramInfo] = useState<ProgramInfo>();

  useEffect(() => {
    (async () => {
      try {
        const program = await fetchGet(`programInfo/${startCode}`);
        setProgramInfo(program);
      } catch (e) {
        console.log('error', e);
      }
    })();
  }, [startCode]);

  return programInfo;
}

export const UNKNOWN_CODE = 'UNKNOWN_CODE';

export function useAccessCode(startCode: string) {
  const [code, setCode] = useState<string>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (code) {
      return;
    }

    (async () => {
      try {
        const savedAccessCode = await LocalStorage.getAccessCode(startCode);
        if (savedAccessCode) {
          setCode(savedAccessCode);
          return;
        }
        const result = await fetchPost(`users/createStartCodeUser`, {}, { startCode });
        if (!result.ok) {
          throw new Error(result.statusText);
        }
        const user = await result.json();
        const accessCode = user?.code.trim();
        LocalStorage.saveAccessCode({ startCode, accessCode });
        setCode(accessCode);
      } catch (e) {
        dispatch(setError(e as string));
        setCode(UNKNOWN_CODE);
      }
    })();
  }, [startCode, code, setCode]);

  return code;
}
