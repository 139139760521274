import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useImageFromUri } from '@visikon/utils/src/utils';
import Thumbnail from '../../components/Thumbnail';
import { ProgramInfo } from '../../common/listPrograms';
import { ReactComponent as ellipsis } from '../../images/svg/ellipsis-vertical.svg';
import { SvgIcon } from '../../images/svgIcon';
import { ActionDialog, ProgramAction } from './ActionDialog';
import { archiveProgram, restoreProgram, unsetNewProgram } from '../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { changeCountry, changeProgram } from '../../actions/resourcesActions';
import { useEffect, useState } from 'react';
import { LanguageSelectorModal } from '../../internationalization/LanguageSelectorModal';
import { Country, getCountryByLanguageCode } from '@visikon/core-models/i18n/languages';
import { i18n } from '../../internationalization/i18n';
import { RestoreProgramDialog } from './RestoreProgramDialog';
import { ArchiveProgramDialog } from './ArchiveProgramDialog';
import { SHARE_PROGRAM_ROUTE } from '../share/shareProgramContainer';
import { tracker } from '@visikon/tracker/src';
import * as Styles from './ProgramCard.styles';
import { useCountry, useFirstValidContentConfig } from '../../common/selectors';
import { IState } from '../../reducers/reducer';
import { FadeInUpDiv } from './HomeScreen.styles';
import { Wrap } from '../../common/utils';
import { CountryBadge } from '../../internationalization/CountryBadge';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import { Unstyled } from '../../components/Unstyled';

interface IProps {
  program: ProgramInfo;
  index: number;
  activePopup?: number | null;
  changeActivePopup?: (dissmiss?: true) => void;
}

export const ProgramCard = ({ program, index, activePopup, changeActivePopup }: IProps) => {
  const dispatch = useDispatch();
  const confirmArchiveDialogRef = React.useRef<HTMLDialogElement>(null);
  const restoreDialogRef = React.useRef<HTMLDialogElement>(null);
  const commandRef = React.useRef<HTMLDivElement>(null);
  const history = useHistory();
  const imgId = program.image?._id;
  const imgUri = useImageFromUri(imgId);
  const country = useCountry();
  const isNew = useSelector((state: IState) => state.userData.programsData?.find((p) => p.id === program.id)?.isNew);
  const [showLanguageSelectorModal, setshowLanguageSelectorModal] = React.useState(false);
  const [sharingProgram, setSharingProgram] = React.useState(false);
  const [languageCodes, setLanguageCodes] = useState<string[]>([]);
  const [isArchived, setIsArchived] = useState(false);
  const [newlyAdded, setNewlyAdded] = useState(false);
  const actionClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    changeActivePopup?.();
  };
  const firstContentConfig = useFirstValidContentConfig();

  function navigate() {
    history.push(`${program.id}/${firstContentConfig.path}`, { state: { from: 'home' } });
  }

  useEffect(() => {
    if (isNew) {
      setNewlyAdded(true);
      setTimeout(() => {
        dispatch(unsetNewProgram(program.id));
      }, 1000);
    }
  }, []);

  const hasProgramActiveLanguage = () => {
    const programLanguages = program.languages;
    const result = programLanguages.includes(country?.languageCode);

    if (!result) {
      setLanguageCodes(programLanguages || []);
      setshowLanguageSelectorModal(true);
    }
    return result;
  };

  const dialogActions = (): ProgramAction[] => {
    return [
      {
        icon: 'share',
        title: i18n('action_share_title'),
        action: () => {
          setSharingProgram(true);
          if (hasProgramActiveLanguage()) {
            navigateToShareProgram();
          }
          return true;
        },
        level: 'info',
      },
      {
        icon: 'trash',
        title: i18n('action_archive_title'),
        action: () => {
          confirmArchiveDialogRef.current?.showModal();
          return true;
        },
        level: 'danger',
      },
    ];
  };

  const handleProgramKeyClick = (ev: React.KeyboardEvent) => {
    if (ev.target !== ev.currentTarget) return;
    if (ev.key !== 'Enter' && ev.key !== ' ') return;

    handleProgramClick();
  };

  const handleProgramClick = () => {
    if (program.archived) {
      restoreDialogRef.current?.showModal();
      return;
    } else if (!hasProgramActiveLanguage()) {
      return;
    }

    dispatch(changeProgram({ id: program.id }));
    navigate();
  };

  const navigateToShareProgram = () => {
    tracker.trackEvent('HomeScreen', 'ShareWithRelatives');
    dispatch(changeProgram({ id: program.id }));
    history.push(`${program.id}${SHARE_PROGRAM_ROUTE}`);
  };

  const languageSelectorHandler = (country: Country | null) => {
    setshowLanguageSelectorModal(false);
    if (!country) {
      setSharingProgram(false);
      return;
    }
    dispatch(changeCountry(country));

    dispatch(changeProgram({ id: program.id }));
    if (sharingProgram) {
      navigateToShareProgram();
    } else {
      navigate();
    }
  };

  const countries = program.languages.map((language, index) => getCountryByLanguageCode(language));
  const languagesTitle = countries.map((country) => i18n(country.language as TextKeys)).join(', ');

  const result = (
    <Styles.Container>
      <Styles.ContentCard role="link" tabIndex={0} onClick={handleProgramClick} onKeyDown={handleProgramKeyClick}>
        <Styles.LeftCell>{imgId && <Thumbnail thumbUrl={imgUri} aligned={'none'} />}</Styles.LeftCell>
        <Styles.RightCell>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Styles.ProgramTitle>{program.name}</Styles.ProgramTitle>
            <Styles.ProgramFlags title={languagesTitle}>
              {countries.map((country, index) => (
                <CountryBadge imgWidth={'12px'} country={country} key={index} />
              ))}
            </Styles.ProgramFlags>
          </div>
          <Styles.ProgramOrg>{program.subName || ' '}</Styles.ProgramOrg>
        </Styles.RightCell>
        {!program.archived && (
          <Unstyled.Button onClick={actionClicked} aria-label={i18n('home_program_options_accessibility')}>
            <Styles.CommandCell ref={commandRef} id={`open-action-sheet-${index}`}>
              <SvgIcon Icon={ellipsis} height={20} aria-hidden />
            </Styles.CommandCell>
          </Unstyled.Button>
        )}
      </Styles.ContentCard>

      {!program.archived && (
        <ActionDialog
          actions={dialogActions()}
          parentRef={commandRef}
          onHide={() => changeActivePopup?.(true)}
          show={activePopup === index}
          dialogIndex={index}
          manualDismiss={isArchived}
        />
      )}
      {showLanguageSelectorModal && <LanguageSelectorModal onSelectCountry={languageSelectorHandler} languageCodes={languageCodes} />}
      <ArchiveProgramDialog
        dialogRef={confirmArchiveDialogRef}
        program={program}
        onConfirm={() => {
          dispatch(archiveProgram(program.id));
          tracker.trackEvent('HomeScreen', 'ArchiveProgram');
          confirmArchiveDialogRef.current?.close();
          setIsArchived(true);
        }}
        onCancel={() => {
          confirmArchiveDialogRef.current?.close();
          setIsArchived(false);
        }}
      />
      <RestoreProgramDialog
        dialogRef={restoreDialogRef}
        program={program}
        onConfirm={() => {
          dispatch(restoreProgram(program.id));
          tracker.trackEvent('HomeScreen', 'RestoreProgram');
          restoreDialogRef.current?.close();
        }}
      />
    </Styles.Container>
  );
  return <ProgramWithAnimation result={result} showAnimation={newlyAdded} />;
};

const ProgramWithAnimation = (props: { result: JSX.Element; showAnimation: boolean }) => {
  return (
    <Wrap
      expression={props.showAnimation}
      decorator={(children) => (
        <FadeInUpDiv timeout={500} showAnimation={true} halfway>
          {children}
        </FadeInUpDiv>
      )}
    >
      {props.result}
    </Wrap>
  );
};
