import styled from 'styled-components';
import { i18N, I18N } from '../internationalization/AppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle, faX } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';

const AlertTag = styled.div<{ type: AlertType }>`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ type }) => (type === 'warning' ? Tokens.color.warning : Tokens.color.danger)};
  color: ${Tokens.color.white};
  border-width: 1px;
  padding: 16px;
`;

const AlertTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 12px;
  & > div {
    display: flex;
    font-weight: 800;
  }
  & > div > svg {
    font-size: 1.5rem;
    margin-right: 10px;
  }
`;

const AlertBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  white-space: pre-wrap;
  margin-bottom: 5px;
`;

export type AlertType = 'warning' | 'error';

export type Props = PropsWithChildren<{
  type: AlertType;
  title?: string;
  dismissible?: () => void;
}>;
export const Alert = (props: Props) => {
  const [show, setShow] = React.useState(true);
  const alertTitle = props.title ? props.title : props.type === 'warning' ? i18N('warning') : i18N('error');
  const icon = props.type === 'warning' ? faExclamationTriangle : faExclamationCircle;

  if (!show) {
    return null;
  }

  const dismiss = () => {
    if (props.dismissible) {
      props.dismissible();
    }
    setShow(false);
  };

  return (
    <AlertTag type={props.type}>
      <AlertTitle>
        <div>
          <FontAwesomeIcon icon={icon} />
          <div>{alertTitle}</div>
        </div>
        {props.dismissible && <FontAwesomeIcon icon={faX} style={{ cursor: 'pointer' }} width={12} onClick={dismiss} />}
      </AlertTitle>
      <AlertBody>{props.children}</AlertBody>
    </AlertTag>
  );
};
