import styled from 'styled-components';
import { i18N, I18N } from '../../internationalization/AppText';
import { Dialog, DialogAnimations, DialogBody, DialogHeader } from '@visikon/spine';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  min-width: 420px;
  @media (max-width: 420px) {
    min-width: 100%;
  }
`;

const ContactBody = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
`;
const ContactActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

type Props = {
  dialogRef: React.RefObject<HTMLDialogElement>;
};

export const VISIKON_PHONE_NR = '+45 31 47 77 48';
export const VISIKON_SUPPORT_EMAIL = 'support@visikon.com';

export const ContactVisikonDialog = ({ dialogRef }: Props) => {
  return (
    <Dialog className={DialogAnimations.SlideDown} _ref={dialogRef}>
      <DialogHeader>{i18N('contact_popup_title')}</DialogHeader>
      <DialogBody>
        <Container>
          <ContactBody>
            <I18N text={'contact_popup_body'} />
          </ContactBody>
          <ContactActions>
            <div>
              <a href={'tel:' + VISIKON_PHONE_NR.replace(/\s/g, '')}>
                <I18N text={'contact_popup_phone'} replace={{ $PHONE_NO: VISIKON_PHONE_NR }} />
              </a>
            </div>
            <div>
              <a href={'mailto:' + VISIKON_SUPPORT_EMAIL}>{VISIKON_SUPPORT_EMAIL}</a>
            </div>
          </ContactActions>
        </Container>
      </DialogBody>
    </Dialog>
  );
};
