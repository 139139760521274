import { TrackingCategories } from '@visikon/core-models/tracking';
import { MatomoTracker } from './matomo';
import { EnvironmentName } from '@visikon/backbone/src/env';
import { backbone } from '@visikon/backbone/src';

export class Tracker {
  private static instance: Tracker;

  private enabled = false;
  private matomo = new MatomoTracker();

  private constructor() {
    backbone.store.subscribe((state) => {
      if (!state.client || !state.env) return;

      this.enabled = state.env !== EnvironmentName.Local;
    });
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new Tracker();
    }

    return this.instance;
  }

  trackScreen(screenName: string) {
    this.enabled && this.matomo.trackScreen(screenName);
  }

  trackAction(action: string) {
    this.enabled && this.matomo.trackAction(action);
  }

  trackEvent(category: TrackingCategories, action: string, name?: string, value?: string) {
    this.enabled && this.matomo.trackEvent(category, action, name, value);
  }
}

export const tracker = Tracker.getInstance();
