import styled from 'styled-components';

export const UnstyledButton = styled.button`
  background: none;
  border: unset;
  padding: unset;
  margin: unset;
  min-height: unset;
  min-width: unset;

  color: inherit;
  /* font: inherit; */
  appearance: none;

  line-height: unset;
  -webkit-font-smoothing: unset;
  -moz-osx-font-smoothing: unset;
`;
