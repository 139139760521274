import { useEffect, useRef, useState } from 'react';
import { ContentWrapper, Expand, ExpandIconInner, ExpandIconWrapper, Header, Wrapper } from './ExpandPanel.styles';

/**
 * Helper function to continuously call scroll each frame
 * for the duration of animDuration
 *
 * This is needed because the viewport can change size during
 * an expansion animation.
 */
function scrollStart(needToScroll: number, animDuration: number) {
  let start: any;
  const finalTop = window.scrollY + needToScroll;

  const scroll = (timestamp: any) => {
    if (start === undefined) {
      start = timestamp;
    }
    const elapsed = timestamp - start;

    // Request will stop after animDuration ms
    if (elapsed < animDuration) {
      window.scroll({
        top: finalTop,
        left: 0,
        behavior: 'smooth',
      });
      window.requestAnimationFrame(scroll);
    }
  };
  // Fire away!
  window.requestAnimationFrame(scroll);
}

export function scrollWithExpansion(element: HTMLDivElement, animationDuration: number, extraPadding: number = 0): void {
  // Element offset from top regardless of scroll
  const offsetTop = element.offsetTop - window.scrollY;

  // Part of element visible above window bottom
  const partVisible = window.innerHeight - offsetTop;

  // Part of element "stick down" below window bottom
  const stickingDown = element.scrollHeight - partVisible;

  // We need a bit of padding also (20px)
  const needToScroll = stickingDown + 20 + extraPadding;

  // console.log("ScrollInfo: ", { offsetTop, partVisible, stickingDown, needToScroll });

  if (needToScroll > 0) {
    scrollStart(needToScroll, animationDuration);
  }
}

interface ContentProps {
  isOpen: boolean;
  children: JSX.Element;
}

const ContentSection = ({ isOpen, children }: ContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  function scrollContent(ref: HTMLDivElement) {
    if (isOpen) {
      const height = ref.scrollHeight;
      ref.style.opacity = '1';
      ref.style.marginTop = '10px';
      ref.style.marginBottom = '20px';
      ref.style.maxHeight = `${height}px`;
      scrollWithExpansion(ref, 200, 78);
    } else {
      ref.style.opacity = '0';
      ref.style.marginTop = '0';
      ref.style.marginBottom = '0';
      ref.style.maxHeight = '0px';
    }
  }

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        setTimeout(() => {
          contentRef.current!.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 200);
      }

      const ref = contentRef.current;
      try {
        const resizeObserver = new ResizeObserver(() => {
          scrollContent(ref);
        });
        resizeObserver.observe(contentRef.current);
        if (childrenRef.current) {
          resizeObserver.observe(childrenRef.current);
        }
        return () => {
          resizeObserver.disconnect();
        };
      } catch (e) {
        console.log('ResizeObserver not supported');
        scrollContent(ref);
      }
    }
  }, [contentRef.current, isOpen]);

  return (
    <div role="region" aria-label="Expandable content">
      <ContentWrapper ref={contentRef} isOpen={isOpen}>
        <div ref={childrenRef}>{children}</div>
      </ContentWrapper>
    </div>
  );
};

const ExpandIcon = ({ open }: { open: boolean }) => {
  return (
    <ExpandIconWrapper>
      <ExpandIconInner open={open} />
    </ExpandIconWrapper>
  );
};

interface Props {
  cta: any;
  header: string;
}

export const ExpandPanel = ({ cta, header }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  function handleClick() {
    setOpen(!isOpen);
    const action = !isOpen ? 'expand' : 'collapse';
  }

  // const backgroundColor: string = true ? DeprecatedTokens.cardBackground : DeprecatedTokens.whiteBackground;
  const backgroundColor: string = 'white';
  return (
    <Wrapper ref={wrapperRef} background={backgroundColor}>
      <Header className={isOpen ? 'open' : ''} onClick={handleClick} tabIndex={0}>
        <div role="region" aria-label={header}>
          {header}
        </div>
        <Expand>
          <ExpandIcon open={isOpen} />
        </Expand>
      </Header>

      <ContentSection isOpen={isOpen}>{cta.props.children}</ContentSection>
    </Wrapper>
  );
};
