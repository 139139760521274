import { GetStorage } from '@visikon/utils/src/storage';
import { IStorage } from '@visikon/utils/src/storage/IStorage';

export const STORAGE_SESSION_KEY = 'local-storage-session-key';

export type Session = {
  token: string;
  referencedUser: boolean;
  activatedAt: number;
};

export const SessionStorage = {
  get: async (storageApi: IStorage) => {
    const jsonString = await storageApi.getItem(STORAGE_SESSION_KEY);
    return jsonString ? (JSON.parse(jsonString) as Session) : undefined;
  },
  set: async (loginResponse: Session) => {
    const { token, referencedUser, activatedAt } = loginResponse;
    await GetStorage().setItem(STORAGE_SESSION_KEY, JSON.stringify({ token, referencedUser, activatedAt }));
  },
  clear: async () => {
    await GetStorage().removeItem(STORAGE_SESSION_KEY);
  },
};
