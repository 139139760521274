import { MediaPlayer, MediaPlayerInstance, MediaProvider, Track } from '@vidstack/react';
import { defaultLayoutIcons, DefaultLayoutTranslations, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { useLanguage } from '../../../common/selectors';
import { i18N } from '../../../internationalization/AppText';
import { VideoPlayerProps } from './VideoPlayer.types';

function useVidStackTranslations(): DefaultLayoutTranslations {
  const language = useLanguage();

  return {
    'Caption Styles': i18N('vidstack_caption_styles', language),
    'Captions look like this': i18N('vidstack_captions_look_like_this', language),
    'Closed-Captions Off': i18N('vidstack_closed_captions_off', language),
    'Closed-Captions On': i18N('vidstack_closed_captions_on', language),
    'Display Background': i18N('vidstack_display_background', language),
    'Enter Fullscreen': i18N('vidstack_enter_fullscreen', language),
    'Enter PiP': i18N('vidstack_enter_pip', language),
    'Exit Fullscreen': i18N('vidstack_exit_fullscreen', language),
    'Exit PiP': i18N('vidstack_exit_pip', language),
    'Google Cast': i18N('vidstack_google_cast', language),
    'Keyboard Animations': i18N('vidstack_keyboard_animations', language),
    'Seek Backward': i18N('vidstack_seek_backward', language),
    'Seek Forward': i18N('vidstack_seek_forward', language),
    'Skip To Live': i18N('vidstack_skip_to_live', language),
    'Text Background': i18N('vidstack_text_background', language),
    Accessibility: i18N('vidstack_accessibility', language),
    AirPlay: i18N('vidstack_airplay', language),
    Announcements: i18N('vidstack_announcements', language),
    Audio: i18N('vidstack_audio', language),
    Auto: i18N('vidstack_auto', language),
    Boost: i18N('vidstack_boost', language),
    Captions: i18N('vidstack_captions', language),
    Chapters: i18N('vidstack_chapters', language),
    Color: i18N('vidstack_color', language),
    Connected: i18N('vidstack_connected', language),
    Connecting: i18N('vidstack_connecting', language),
    Continue: i18N('vidstack_continue', language),
    Default: i18N('vidstack_default', language),
    Disabled: i18N('vidstack_disabled', language),
    Disconnected: i18N('vidstack_disconnected', language),
    Download: i18N('vidstack_download', language),
    Family: i18N('vidstack_family', language),
    Font: i18N('vidstack_font', language),
    Fullscreen: i18N('vidstack_fullscreen', language),
    LIVE: i18N('vidstack_live', language),
    Loop: i18N('vidstack_loop', language),
    Mute: i18N('vidstack_mute', language),
    Normal: i18N('vidstack_normal', language),
    Off: i18N('vidstack_off', language),
    Opacity: i18N('vidstack_opacity', language),
    Pause: i18N('vidstack_pause', language),
    PiP: i18N('vidstack_pip', language),
    Play: i18N('vidstack_play', language),
    Playback: i18N('vidstack_playback', language),
    Quality: i18N('vidstack_quality', language),
    Replay: i18N('vidstack_replay', language),
    Reset: i18N('vidstack_reset', language),
    Seek: i18N('vidstack_seek', language),
    Settings: i18N('vidstack_settings', language),
    Shadow: i18N('vidstack_shadow', language),
    Size: i18N('vidstack_size', language),
    Speed: i18N('vidstack_speed', language),
    Text: i18N('vidstack_text', language),
    Track: i18N('vidstack_track', language),
    Unmute: i18N('vidstack_unmute', language),
    Volume: i18N('vidstack_volume', language),
  };
}

export default function VidStackPlayer({
  title,
  src,
  loop,
  videoRef,
  subtitles,
  onEnded,
  onPause,
  onVideoLoaded,
  onDurationChange,
  onFullscreenChange,
}: VideoPlayerProps) {
  const translations = useVidStackTranslations();

  return (
    <MediaPlayer
      title={title}
      viewType="video"
      crossOrigin="anonymous"
      ref={videoRef as React.RefObject<MediaPlayerInstance>}
      autoPlay
      src={{ src, type: 'video/mp4' }}
      onPause={({ target }) => onPause?.(target)}
      onEnded={onEnded}
      onLoadedMetadata={({ target }) => onVideoLoaded?.(target)}
      onDurationChange={(_, { target }) => onDurationChange?.(target)}
      onFullscreenChange={onFullscreenChange}
      playsInline
      loop={loop}
    >
      <MediaProvider>
        {subtitles?.map((sub, index) => (
          <Track
            key={sub.src}
            kind="subtitles"
            lang={sub.languageCode}
            language={sub.languageCode}
            src={sub.languageCode}
            label={sub.label}
            default={index === 0}
          />
        ))}
      </MediaProvider>
      <DefaultVideoLayout translations={translations} icons={defaultLayoutIcons} />
    </MediaPlayer>
  );
}
