import Logo_DA from '../images/svg/logo/MitForløb_Da.svg';
import Logo_DE from '../images/svg/logo/MitForløb_De.svg';
import Logo_EN from '../images/svg/logo/MitForløb_En.svg';
import Logo_ES from '../images/svg/logo/MitForløb_Es.svg';
import Logo_FR from '../images/svg/logo/MitForløb_Fr.svg';
import Logo_NO from '../images/svg/logo/MitForløb_No.svg';
import Logo_PT from '../images/svg/logo/MitForløb_Pt.svg';
import Logo_SV from '../images/svg/logo/MitForløb_Sv.svg';
import { LanguageCode } from '@visikon/core-models/content';
import { getDomainLanguage } from '@visikon/core-models/i18n/languages';
import { MobileUtils } from '../common/utils';
import { useLanguage } from '../common/selectors';
import { i18n } from '../internationalization/i18n';

const switchLogo = {
  da: Logo_DA,
  de: Logo_DE,
  en: Logo_EN,
  es: Logo_ES,
  fr: Logo_FR,
  no: Logo_NO,
  pt: Logo_PT,
  sv: Logo_SV,
};

const heightCorrection = {
  da: 17,
  de: 20,
  en: 20,
  es: 16,
  fr: 16,
  no: 21,
  pt: 16,
  sv: 20,
};

type props = { explicitLanguage?: LanguageCode; addedHeight?: number };

export const Logo = ({ explicitLanguage, addedHeight }: props) => {
  const language = useLanguage();

  const { hostname } = window.location;
  const logoLanguage: LanguageCode = MobileUtils.isNative() ? language : getDomainLanguage(hostname);
  const chosenLanguage = explicitLanguage || logoLanguage;
  return (
    <img
      src={switchLogo[chosenLanguage]}
      height={heightCorrection[chosenLanguage] + (addedHeight || 0)}
      alt={i18n('my_treatment_name', chosenLanguage)}
      lang={chosenLanguage}
    />
  );
};
