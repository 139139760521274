export const DeprecatedTokens = {
  primary: '#183C73',
  secondary: '#D41D49',
  secondaryLighter: '#E93D5C',
  dark: '#263238',
  primaryLigher: '#3f6399',
  border: '#eceff1',
  background: '#f9f9f9',
  primaryBackground: '#F1F4F9',
  secondaryBackground: '#D41D49',
  whiteBackground: '#ffffff',
  shadowColor: '#e5e4e5',
  positive: '#AAD282',
  neutral: '#F49C20',
  negative: '#E55354',
  favoriteInactive: '#263238',
  favoriteActive: '#D41D49',
  breakPoint: '64em',
  breakPointMid: '48em',
  breakPointSmall: '27em',
  breakPointExtraSmall: '22.5em',
  progressBarContainer: '#3A474E',
  progressBarGradient: 'linear-gradient(246.66deg, rgba(118, 216, 248, 0.2) 21.69%, rgba(255, 255, 255, 0) 78.15%), #5FC797;',
};
