import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;

export const FlexView = styled.div`
  display: flex;

  @media print {
    display: block;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  height: 100%;

  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    width: 100%;
  }

  @media print {
    display: block;
  }
`;

export const SideBarContent = styled.div<{ isVideoPlayerOpen?: boolean }>`
  display: flex;
  flex-direction: column;

  text-align: ${({ isVideoPlayerOpen }) => (isVideoPlayerOpen ? 'left' : 'center')};
  padding: ${({ isVideoPlayerOpen }) => (isVideoPlayerOpen ? '0 1em' : 'inherit')};

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin: 5px 0;
    line-height: 1.5;
  }
`;

export const HeaderArea = styled.div<{ shouldDisplay?: boolean }>`
  ${(props) => !props.shouldDisplay && 'display: none;'}
`;

export const CCHeader = styled.div`
  & :first-child {
    padding-left: 0;
  }
`;
export const SmallViewSidePadding = styled.div<{ shouldBeWhite?: boolean }>`
  @media all and (max-width: ${Sizes.breakPoint}) {
    padding: 0 16px;
  }
  @media all and (max-width: ${Sizes.breakPoint}) {
    background: ${(props) => (props.shouldBeWhite ? color.white : 'inherit')};
  }
`;
