import {ErrorInfo} from 'react';
import * as React from 'react';

export function ErrorView({ error, errorInfo }: {error?: Error, errorInfo?: ErrorInfo}) {
  return (
    <div>
      <h1>An error occurred: {error?.message}</h1>
      <div>{errorInfo?.componentStack}</div>
    </div>
  );
}
