import { fetchGet } from '@visikon/utils/src/utils';
import { FeatureFlags, getFeatureFlag } from '@visikon/core-models/FeatureFlags';
import { FetchQueryOptions, QueryClient, useQuery } from '@tanstack/react-query';
import React from 'react';

const DefaultFeatureFlags: FeatureFlags = {
  videoPlayer: {
    value: 'html5',
    type: 'string',
  },
};

const QUERY_KEY = ['feature-flags'];
const QUERY_OPTIONS: FetchQueryOptions<FeatureFlags> = {
  staleTime: 1000 * 60 * 60 * 12, // 12 hours
  cacheTime: Infinity,
};

function useServerFeatures() {
  return useQuery<FeatureFlags>({
    queryKey: QUERY_KEY,
    queryFn: () => fetchGet('featureflags'),
    suspense: true,
    refetchIntervalInBackground: true,
    placeholderData: DefaultFeatureFlags,
    ...QUERY_OPTIONS,
  });
}

export function PrefetchFeatureFlags(queryClient: QueryClient) {
  queryClient.prefetchQuery(QUERY_KEY, () => fetchGet('featureflags'), QUERY_OPTIONS);
}

export function useFeature<T extends keyof FeatureFlags>(featureName: T): FeatureFlags[T] {
  const features = useServerFeatures();

  // Because we use suspense, we can assume that features.data is defined
  return getFeatureFlag(features.data!, featureName);
}

interface FeatureSwitchProps<FeatureName extends keyof FeatureFlags, Props extends {} = {}> {
  props: Props;
  featureName: FeatureName;
  components: Record<FeatureFlags[FeatureName]['value'], React.ComponentType<Props>>;

  /**
   * Component to render if the feature flag value is not found in the `components` object.
   */
  defaultComponent: React.ComponentType<Props>;
}

/**
 * Renders different components based on the value of a feature flag.
 *
 * Beware this component suspends when fetching feature flags.
 */
export function FeatureSwitch<FeatureName extends keyof FeatureFlags, Props extends {}>({
  featureName,
  components,
  defaultComponent,
  props,
}: FeatureSwitchProps<FeatureName, Props>) {
  const { value } = useFeature(featureName);
  const Compoent = components[value] || defaultComponent;

  return <Compoent {...props} />;
}
