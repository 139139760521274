import { all } from 'redux-saga/effects';
import { authSaga } from './authSaga';
import { resourcesSaga } from './resourcesSaga';
import { userDataSaga } from './userDataSaga';
import { shareProgramSaga } from './shareProgramSaga';
import { surveySaga } from './surveySaga';

export default function* rootSaga() {
  yield all([authSaga(), resourcesSaga(), userDataSaga(), shareProgramSaga(), surveySaga()]);
}
