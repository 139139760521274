import React, {PropsWithChildren} from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {tracker} from "@visikon/tracker/src";

// Regular expression helpers
/* eslint-disable no-useless-escape */
const rootPage = (name: string) => (`\/${name}\/?$`);
const oneArbitraryParam = (name: string) => (`\/${name}\/[^\/\s]+\/?`);

const routesToMatch = [
    {regEx: RegExp(rootPage("")),                      screenName: "SignIn"},
    {regEx: RegExp(rootPage("program")),               screenName: "Home"},
    {regEx: RegExp(oneArbitraryParam("program")),      screenName: "VideoPlayer"},
    {regEx: RegExp(rootPage("training")),              screenName: "Training"},
    {regEx: RegExp(oneArbitraryParam("training")),     screenName: "VideoPlayer"},
    {regEx: RegExp(rootPage("instructions")),          screenName: "Instructions"},
    {regEx: RegExp(oneArbitraryParam("instructions")), screenName: "VideoPlayer"},
    {regEx: RegExp(rootPage("documents")),             screenName: "Phamplet"},
    {regEx: RegExp(oneArbitraryParam("documents")),    screenName: "PhampletDetails"},
    {regEx: RegExp(rootPage("symptoms")),              screenName: "Symptoms"},
    {regEx: RegExp(rootPage("questions")),             screenName: "FAQList"},
    {regEx: RegExp(rootPage("share")),                 screenName: "Share"},
    {regEx: RegExp(rootPage("help")),                  screenName: "Help"},
    {regEx: RegExp(oneArbitraryParam("videoplayer")),  screenName: "VideoPlayer"},
]

type IProps = RouteComponentProps<{}>;
class MScreenAutoTracking extends React.Component<PropsWithChildren & IProps> {
    componentDidUpdate(prevProps: IProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        const path = this.props.location.pathname;
        const match = routesToMatch.find(route => route.regEx.test(path));
        if (match) {
            tracker.trackScreen(match.screenName);
        }
    }

    render() {
        return this.props.children;
    }
}
export const ScreenAutoTracker = withRouter(MScreenAutoTracking);