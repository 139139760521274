import styled from 'styled-components';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;
import colors = Tokens.colors;
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import { CountryContainer } from '../../internationalization/CountryBadge';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
  position: relative;
`;
export const ContentCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid ${colors.grey[100]};
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  &:hover {
    box-shadow: unset;
  }
`;
export const LeftCell = styled.div`
  width: 100px;
  flex-shrink: 0;
  align-self: stretch;
  overflow: hidden;
  border-right: 1px solid ${colors.grey[100]};
  @media all and (max-width: ${Sizes.breakPointSmall}) {
    display: none;
  }
`;
export const RightCell = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
`;
export const ProgramTitle = styled.div`
  color: ${color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  @media all and (max-width: ${Sizes.breakPointXSmall}) {
    word-break: break-word;
  }
`;
export const ProgramFlags = styled.div`
  display: flex;
  & > ${CountryContainer} {
    padding: 6px 1px 0 1px;
  }
`;

export const ProgramOrg = styled.div`
  color: ${color.text};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  white-space: break-spaces;
`;

export const CommandCell = styled.div`
  width: 38px;
  padding: 12px 0;
  svg {
    padding: 6px;
  }

  svg:hover {
    border-radius: 4px;
    background: #f7f7f7;
  }
`;
