import { style } from 'typestyle';
import { Tokens } from './tokens';

export const Styles = {
  default: {
    text: style({
      color: Tokens.color.text,
      fontFamily: Tokens.text.family.default,
      fontSize: Tokens.size.text.medium,
      margin: 'unset',
    }),
  },
};
