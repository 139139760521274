import { useBackbone } from '@visikon/backbone';
import { LanguageCode } from '@visikon/core-models/languageTypes';

useBackbone.subscribe(
  (state) => state.country.languageCode,
  (languageCode) => {
    UpdateHtmlLanguageCode(languageCode);
  },
  {
    fireImmediately: true,
  },
);

function UpdateHtmlLanguageCode(languageCode: LanguageCode) {
  const html = document.documentElement;
  html.setAttribute('lang', languageCode);
}
