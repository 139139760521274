import * as React from 'react';
import { FAQSection } from '../../reducers/resourcesReducer';
import * as Styles from '../../components/styled/DetailsCard';
import * as FaqStyles from './FaqCardComponent.styles';
import { Fader } from '../../common/Fader';
import { useImageFromUri } from '@visikon/utils/src/utils';
import Thumbnail from '../../components/Thumbnail';
import { Chevron } from '../../components/styled/Chevron';
import { useEffect } from 'react';

interface IProps {
  faq: FAQSection;
  isOpen: boolean;
  onClick: () => void;
}

export const FaqCardComponent = ({ faq, isOpen, onClick }: IProps) => {
  const contentRef = React.useRef<HTMLElement>(null);

  const uri = faq.thumbnail ? useImageFromUri(faq.thumbnail._id) : '';

  const renderTextWithNewline = (text: string) => {
    return text?.split('\n').map((t) => {
      if (t && t.length > 0) {
        return (
          <p key={t} style={{ lineHeight: '1.5' }}>
            {t}
          </p>
        );
      }
      return null;
    });
  };

  const renderDetails = (info: FAQSection['questions'][0]) => {
    return (
      <Fader><FaqStyles.FaqDetails key={info.question}>
        <div style={{ alignItems: 'center', lineHeight: '1.5' }}>
          <span style={{ maxWidth: '100%' }}>{info.question}</span>
        </div>
        <div>{renderTextWithNewline(info.answer)}</div>
      </FaqStyles.FaqDetails>
      </Fader>
    );
  };

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setTimeout(() => {
        contentRef.current!.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200);
    }
  }, [isOpen, contentRef.current]);

  return (
    <Fader>
      <section style={{ margin: 'unset', padding: 'unset' }} ref={contentRef}>
        <Styles.DetailsCard onClick={onClick}>
          <Thumbnail thumbUrl={uri} alt="" />
          <Styles.NameContainer>
            <Styles.DetailsCardName>{faq.name}</Styles.DetailsCardName>
          </Styles.NameContainer>
          <Chevron isOpen={isOpen} />
        </Styles.DetailsCard>
        {isOpen && <Styles.DetailsCardContainer>{faq.questions.map(renderDetails)}</Styles.DetailsCardContainer>}
      </section>
    </Fader>
  );
};
