import { Button } from '@visikon/spine/src';
import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';

export const Outer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${DeprecatedTokens.border};
  padding: 16px 0;
`;

export const Container = styled.div``;

export const Header = styled.div`
  flex: 1;
  margin-left: 8px;
`;

export const LogoWrapper = styled.div`
  & > img {
    border-radius: 22.37%;
  }
`;

export const BannerText = styled.div`
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
  line-height: 20px;
`;

export const BannerHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 18px;
`;

export const DismissCross = styled.button`
  all: unset;
  padding-left: 12px;
  cursor: pointer;
  align-self: start;
  @media screen and (min-width: ${Sizes.breakPointSmall}) {
    align-self: inherit;
  }

  @media screen and (min-width: ${Sizes.breakPointSmall}) {
    padding-left: 12px;
  }

  .img {
    width: 14px;
    margin-right: 4px;
  }
`;

export const BannerWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 12px;
`;

export const GetButton = styled(Button)<{ islink?: string }>`
  cursor: pointer;
  width: 100%;
  ${(props) => (props.islink ? 'background: transparent;' : '')}
  ${(props) => (props.islink ? 'text-transform: none;' : '')}
  @media screen and (min-width: ${Sizes.breakPointSmall}) {
    width: inherit;
  }
`;
