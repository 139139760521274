import { forwardRef, useEffect } from 'react';
import { classes } from 'typestyle';
import { CellStates, codeInputStyles } from './CodeInput.styles';
import { useIsMobileDevice } from '@visikon/utils/src/responsive';

export interface CodeCellProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error: boolean;
}

export const CodeInput = forwardRef<HTMLInputElement, CodeCellProps>(({ className, error, ...props }, ref) => {
  const styleState: CellStates = error ? 'error' : 'empty';
  const style = codeInputStyles({ state: styleState });
  const INPUT_ID = 'CodeInputInputFieldId';

  const isMobileDevice = useIsMobileDevice();

  useEffect(() => {
    if (!isMobileDevice) {
      setTimeout(() => {
        document.getElementById(INPUT_ID)?.focus();
      }, 1000);
    }
  }, [isMobileDevice]);
  // TODO: Replace aria label with translated version
  return <input ref={ref} id={INPUT_ID} className={classes(className, style)} {...props} autoCapitalize="off" />;
});
