import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logOut } from '../../actions/authActions';
import { useEffect } from 'react';

export const LogoutContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logOut());
  }, []);

  return <Redirect to={{ pathname: '/' }} />;
};
