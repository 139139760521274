import * as React from 'react';
import styled from 'styled-components';
import { i18N, I18N } from '../../internationalization/AppText';
import { Button, Dialog, DialogActionContainer, DialogAnimations, DialogBody, DialogHeader } from '@visikon/spine';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;

const LogoutBody1 = styled.div`
  color: ${color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
  margin-bottom: 4px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${color.border};
  margin-top: 24px;
`;

const LogoutBody2 = styled.div`
  color: ${color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

type Props = {
  dialogRef: React.RefObject<HTMLDialogElement>;
  onConfirm: () => void;
};

export const ConfirmLogoutDialog = ({ dialogRef, onConfirm }: Props) => {
  return (
    <Dialog className={DialogAnimations.SlideDown} _ref={dialogRef}>
      <DialogHeader>{i18N('confirm_logout_title')}</DialogHeader>
      <DialogBody>
        <LogoutBody1>
          <I18N text={'confirm_logout_body1'} />
        </LogoutBody1>
        <LogoutBody2>
          <I18N text={'confirm_logout_body2'} />
        </LogoutBody2>
        <Divider />
      </DialogBody>
      <DialogActionContainer>
        <Button variant="danger" size="small" onClick={onConfirm}>
          <I18N text="confirm_logout_yes" />
        </Button>
        <Button variant="ghost" size="small" onClick={() => dialogRef.current?.close()}>
          <I18N text="confirm_logout_no" />
        </Button>
      </DialogActionContainer>
    </Dialog>
  );
};
