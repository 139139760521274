import * as React from 'react';
import { useDispatch } from 'react-redux';
import { setSeenPrivacyPolicy } from '../../actions/userActions';
import { setIsShowingPrivacyPolicy } from '../../actions/resourcesActions';
import { useIsUserDataNotLoaded, usePrivacyPolicyAcceptedEvent, useSeenPrivacyPolicy } from '../../common/selectors';
import { I18N } from '../../internationalization/AppText';
import { Button, Dialog, DialogActionContainer, DialogAnimations, DialogBody, DialogHeadding, DialogHeader } from '@visikon/spine';

const DATA_POLICY_URL = 'http://visikon.com/mitforlob/datapolitik/';

export const PrivacyPopupContainer = () => {
  const dispatch = useDispatch();
  const dialogRef = React.useRef<HTMLDialogElement>(null);
  const seenPrivacyPolicy = useSeenPrivacyPolicy();
  const privacyPolicyAcceptedEvent = usePrivacyPolicyAcceptedEvent();
  const isUserDataNotLoaded = useIsUserDataNotLoaded();

  React.useEffect(() => {
    const dialog = dialogRef.current;
    if (isUserDataNotLoaded || !dialog) return;

    if (seenPrivacyPolicy && dialog.open) {
      dialog.close();
      console.debug('Privacy dialog closed');
    } else if (!seenPrivacyPolicy && !dialog.open) {
      dialog.showModal();
      console.debug('Privacy dialog opened');
    }
  }, [seenPrivacyPolicy, isUserDataNotLoaded]);

  const handleConfirm = () => {
    dispatch(setSeenPrivacyPolicy(new Date()));
    dispatch(setIsShowingPrivacyPolicy(false));

    // Call Callback from store if set
    privacyPolicyAcceptedEvent?.();
  };

  return (
    <Dialog _ref={dialogRef} className={DialogAnimations.SlideDown} dismissable={false}>
      <DialogHeader>
        <DialogHeadding>
          <I18N text="data_policy" />
        </DialogHeadding>
      </DialogHeader>
      <DialogBody>
        <I18N text="data_policy_body" />
        <br />
        <br />
        <a href={DATA_POLICY_URL} target="_blank" rel="noreferrer">
          <I18N text="data_policy_read_more" />
        </a>
      </DialogBody>
      <DialogActionContainer>
        <Button size="small" onClick={handleConfirm}>
          <I18N text="accept" />
        </Button>
      </DialogActionContainer>
    </Dialog>
  );
};
