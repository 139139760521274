import styled from 'styled-components';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import backArrow from '../../images/svg/arrow_back.svg';
const Colors: any = {
  text: '#FFFFFF',
  textGrey: '#515151',
  background: '#F3F5F8',
};
const Icons: any = {
  ArrowGreen: backArrow,
};

export const Wrapper = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  background-color: ${({ background = Colors.background }) => background};
  border-radius: 5px;
  padding: 20px;
  z-index: 1;

  @media all and (max-width: ${Sizes.breakPoint}) {
    margin: unset;
  }
`;

export const Header = styled.button`
  // reset button style
  padding: 0;
  background: inherit;
  font: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  border: none;
  text-align: left;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  text-align: start;
  margin-top: 10px;
  transition-duration: 100ms;
  transition-property: background-color, color, padding;
  border-radius: 5px;
  color: ${Colors.textGrey};
  cursor: pointer;

  div {
    font-weight: bold;
  }

  &.open {
  }
`;

export const Expand = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.53px;
  line-height: 21px;
  text-align: center;
  display: flex;
  margin: 0 0 10px 0;
  cursor: pointer;
`;

export const ExpandIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
`;

export const ExpandIconInner = styled.i<{ open: boolean }>`
  content: url(${Icons.ArrowGreen});
  display: inline-block;
  transform: rotate(${(p) => (p.open ? '90' : '180')}deg);
  margin-top: ${(p) => (p.open ? '5' : '0')}px;

  transition-duration: 200ms;
  transition-property: transform, margin-top;
`;

export const ContentWrapper = styled.div<{ isOpen?: boolean }>`
  padding: 0 15px;
  margin-bottom: 0;
  margin-top: 0;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition-duration: 200ms;
  transition-property: all;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  @media all and (max-width: ${Sizes.breakPoint}) {
    font-size: 11px;
  }
`;
