import * as Styles from './ProgramHeader.styles';
import { useImageFromUri } from '@visikon/utils/src/utils';

// TODO: shared libraries shouldn't import stuff from mytreatment
import { Department } from '@visikon/mytreatment/src/reducers/resourcesReducer';
import { ScreenHeader } from '@visikon/mytreatment/src/components/styled/Texts';
import { TreatmentDateContainer } from './TreatmentDate';
import { SubHeader } from './ProgramHeader.styles';

type ProgramHeaderProps = {
  name: string;
  subName?: string;
  department: Department;
};

function useOrganizationLogo(logoId: string | null) {
  const imgUri = useImageFromUri(logoId);
  return <>{imgUri && <Styles.OrganizationLogo src={imgUri} alt="" />} </>;
}

export function ProgramHeader({ name, subName, department }: ProgramHeaderProps) {
  const organizationLogo = useOrganizationLogo(department?.logo?._id);

  return (
    <Styles.Container>
      <Styles.Header>
        <Styles.HeaderOrganizationSection>
          <div>{organizationLogo}</div>
          {department?.organization ? <Styles.DepartmentHeader>{department?.organization}</Styles.DepartmentHeader> : null}
        </Styles.HeaderOrganizationSection>
        <Styles.HeaderProgramSection>
          <ScreenHeader>{name}</ScreenHeader>
          {subName ? <SubHeader>{subName}</SubHeader> : null}
        </Styles.HeaderProgramSection>
      </Styles.Header>
      <TreatmentDateContainer />
      <Styles.LineDivider />
    </Styles.Container>
  );
}
