import * as React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { tracker } from '@visikon/tracker/src';
import { I18N } from '../../internationalization/AppText';
import { FlexWrapper, SmallViewSidePadding } from '../../components/styled/ContentAreaContainers';
import { SymptomData, SymptomsType } from '../../reducers/resourcesReducer';
import { renderDetails, SymptomCardComponent } from './SymptomCardComponent';
import { useContent } from '../../common/selectors';
import { WithFooter } from '../../components/Footer';
import { ScreenHeader, SubHeader } from '../../components/styled/Texts';
import { DetailsCardContainer } from '../../components/styled/DetailsCard';
import { MOBILE_SIZE, useWindowSizeMatches } from '@visikon/utils/src/responsive';
import { useNavLinkHelper } from '../../common/NavigationHelper';
import { ContentContainerSection } from '../../components/ContentContainerSection';
import { LocationHooks } from '@visikon/backbone';

export const SymptomsComponent: React.FC<PropsWithChildren & { fieldKey: string }> = ({ fieldKey, children: header }) => {
  const [detailView, setDetailView] = useState<SymptomData | undefined>(undefined);
  const content = useContent<SymptomsType>(fieldKey);
  const isMobileView = useWindowSizeMatches(MOBILE_SIZE);
  const getClickHandler = (symptom: SymptomData) => {
    return () => {
      if (detailView !== symptom) {
        tracker.trackScreen('SymptomDetails');
        tracker.trackEvent('Symptom', 'symptomShow', symptom.name);
        setDetailView(symptom);
      } else {
        tracker.trackScreen('Symptoms');
        setDetailView(undefined);
      }
    };
  };

  const renderSymptom = (symptom: SymptomData, index: number) => {
    const isOpen = detailView === symptom;
    return <SymptomCardComponent key={symptom.name + index} symptom={symptom} isOpen={isOpen} onClick={getClickHandler(symptom)} />;
  };

  const symptomDetailView = detailView ? <DetailsCardContainer>{detailView.infoList?.map(renderDetails)}</DetailsCardContainer> : undefined;
  const contentView = symptomDetailView && isMobileView ? symptomDetailView : content?.data.symptoms.map(renderSymptom);

  const subHeaderText = isMobileView && detailView ? '' : content?.data.description;

  const headerText = isMobileView && detailView ? <ScreenHeader>{detailView.name}</ScreenHeader> : header;

  const { isMobileOrHasNoMenu, contentNavLink, translatedPage, updateNavLink } = useNavLinkHelper();
  const level = LocationHooks.useNavLevel();

  const paths = LocationHooks.usePaths();
  const pathComingFrom = paths[paths.length - 1];

  useEffect(() => {
    if (detailView && isMobileOrHasNoMenu) {
      updateNavLink({
        title: translatedPage,
        path: pathComingFrom,
        action: () => {
          setDetailView(undefined);
          updateNavLink(contentNavLink);
        },
      });
    }
  }, [isMobileOrHasNoMenu, detailView, translatedPage, level]);

  return (
    <FlexWrapper>
      <ContentContainerSection style={{ display: 'block' }}>
        <SmallViewSidePadding>
          <WithFooter footerStyle={{ paddingTop: '16px' }}>
            {headerText}
            <SubHeader>{subHeaderText}</SubHeader>
            {!content ? (
              <SubHeader>
                <I18N text="loading_dotdotdot" />
                ...
              </SubHeader>
            ) : null}
            {content && contentView}
          </WithFooter>
        </SmallViewSidePadding>
      </ContentContainerSection>
    </FlexWrapper>
  );
};
