import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../reducers/reducer';
import { MitforlobContent, NavLink } from '../reducers/resourcesReducer';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { i18n } from '../internationalization/i18n';
import {
  useCurrentContentConfig,
  useHasOneMenuItem,
  useIsFirstMenuItem,
  useLanguage,
  useProgramActions,
  useTranslatedPage,
  useWholeContent,
} from './selectors';
import { HOME_ROUTE } from '../screens/home/HomeScreen';
import { setNavLink, unsetNavLink } from '../actions/userActions';
import { MOBILE_SIZE, useWindowSizeMatches } from '@visikon/utils/src/responsive';
import { backbone, LocationHooks } from '@visikon/backbone';
import { SHARE_PROGRAM_ROUTE } from '../screens/share/shareProgramContainer';

export const useIsMobileOrHasNoMenu = () => {
  const isMobileView = useWindowSizeMatches(MOBILE_SIZE);
  const hasOneMenu = useHasOneMenuItem();
  return isMobileView || hasOneMenu;
};

export const useContentNavLink = () => {
  const content = useWholeContent();
  return {
    title: content?.name || '',
  };
};

export const useNavLinkHelper = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const translatedPage = useTranslatedPage();
  const isMobileOrHasNoMenu = useIsMobileOrHasNoMenu();
  const isFirstMenuItem = useIsFirstMenuItem();
  const contentNavLink = useContentNavLink();
  const currentContentConfig = useCurrentContentConfig();
  const navLink = useSelector((state: IState) => state.resources.navLink);
  const updateNavLink = (link: NavLink) => dispatch(setNavLink(link));
  const level = LocationHooks.useNavLevel();
  const menuPath = LocationHooks.useMenuPath();
  const programActions = useProgramActions();
  const hasVideoAction = programActions.some((a) => a.type.includes('VIDEO'));
  const programId = backbone.store.getState().program?.id;
  const homeNavLink = {
    title: i18n('home_your_programs'),
    action: () => {
      history.push(HOME_ROUTE);
    },
  };

  function createFinalPath(programId, menuPath, history) {
    const newPath = `${programId}/${menuPath}`;

    // split the current path to get the base URL
    let currentPath = history.location.pathname;
    let basePath = currentPath.split('/').slice(0, -2).join('/');

    // append the new path to the base URL
    let finalPath = `${basePath}/${newPath}`;

    return finalPath;
  }

  const goBackLogic = () => {
    const finalPath = createFinalPath(programId, menuPath, history);

    if (level === 0) {
      dispatch(unsetNavLink());
      history.push(HOME_ROUTE);
      return;
    }

    if (hasVideoAction && [1, 2].includes(level)) {
      history.push(finalPath);
      return;
    }

    if (isMobileOrHasNoMenu) {
      if (!navLink?.action) {
        if (level === 1) {
          history.push(finalPath);
        } else if (level >= 2) {
          history.goBack();
        }
        return;
      }

      navLink.action();
    } else {
      history.goBack();
    }
  };

  return {
    translatedPage,
    isMobileOrHasNoMenu,
    contentNavLink,
    homeNavLink,
    navLink,
    isFirstMenuItem,
    updateNavLink,
    goBackLogic,
    currentContentConfig,
  };
};

export const useNavTitle = () => {
  const content = useSelector<IState, MitforlobContent | undefined>((state) => state.resources.content);
  const { isFirstMenuItem, isMobileOrHasNoMenu, homeNavLink, navLink, updateNavLink } = useNavLinkHelper();
  const location = useLocation();
  const level = LocationHooks.useNavLevel();
  const navBackTitle = LocationHooks.useNavBackTitle();
  const language = useLanguage();

  useEffect(() => {
    let link: NavLink;

    if (isMobileOrHasNoMenu) {
      link =
        isFirstMenuItem || level === -1
          ? { title: i18n('home_your_programs') }
          : navLink?.path
          ? navLink
          : { title: content?.name || i18n('home_nav_title') };
    } else {
      link = level === 0 ? homeNavLink : { title: navBackTitle };
    }
    // console.log('LINK', link);
    // Route specific overrides
    if (location.pathname.includes(SHARE_PROGRAM_ROUTE)) {
      const levelCondition = isMobileOrHasNoMenu ? level === 0 : level === -1;

      if (levelCondition) {
        link = { title: i18n('home_your_programs') };
      } else {
        link = { title: content?.name };
      }
    }

    updateNavLink(link);
  }, [isMobileOrHasNoMenu, isFirstMenuItem, location.pathname, level, navLink?.title, language]);

  return { title: navLink?.title, action: navLink?.action };
};
