import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import styled, { keyframes } from 'styled-components';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;
import { ABOVE_MOBILE_SIZE } from '@visikon/utils/src/responsive';
import { Unstyled } from '../../components/Unstyled';

export const HomeScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 16px;
  align-items: center;
  background: ${color.homeBackground};
  padding-top: 16px;
  overflow: auto;

  @media all and ${ABOVE_MOBILE_SIZE} {
    padding-top: 32px;
  }
`;
export const Container = styled.div`
  width: 770px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 16px;
  align-items: center;

  @media (max-width: ${Sizes.breakPointSmall}) {
    width: 100%;
  }
`;

export const NoActiveProgramsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  gap: 12px;
`;

export const CodeContainer = styled.div`
  text-align: center;
  width: 284px;

  & > button {
    margin-top: 16px;
  }
`;
export const CodeFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 307px;
  min-width: 300px;
`;
export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-self: flex-start;
`;

export const Archived = styled(Unstyled.Button)`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
  color: ${color.primary};
  display: flex;
  align-items: center;
`;

export const Title = styled.h2<{ bold: boolean }>`
  font-size: ${(props) => (props.bold ? '24px' : '20px')};
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 800 : 400)};
  line-height: 32px; /* 133.333% */
  padding: 16px 0;
  margin: unset;
`;
export const NoActiveProgramsBody = styled.div`
  color: ${color.text};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 12px;
`;
export const NoActiveProgramsButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  @media (min-width: ${Sizes.breakPointMid}) {
    width: unset;
  }
`;
export const NoActiveProgramsTitle = styled.div`
  color: ${color.text};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px; /* 140% */
  margin-bottom: 12px;
`;

export const PersonalCodeText = styled.div`
  color: #1a1a1a;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  margin: 20px 0 16px 0;
`;

export const ErrorText = styled.div`
  margin: 10px 0;
`;

export const PersonalCodeLink = styled.div`
  display: flex;
  height: 22px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 80px;
`;

const createFadeInAnimation = (translateY: number) => keyframes`
  0% {
    opacity: 0;
    transform: translateY(${translateY}px);
    pointer-events: none;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
`;

const fadeInUpAnimation = createFadeInAnimation(420);
const upFadeInAnimation = createFadeInAnimation(160);

export const FadeInUpDiv = styled.div<{ timeout?: number; showAnimation: boolean; halfway?: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;

  & > div {
    animation: ${(props) => (props.halfway ? upFadeInAnimation : fadeInUpAnimation)} 900ms ease-out
      ${(props) => (props.timeout ? props.timeout / 1000 : 0)}s forwards;
    ${(props) =>
      props.showAnimation
        ? `
      opacity: 0;
      pointer-events: none;`
        : `
      opacity: 1;
      pointer-events: all;
  `};
  }
`;

export const AddButton = styled.div<{ isProgramListAtBottom?: boolean }>`
  display: flex;
  justify-content: center;
  z-index: 100;

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  }

  // enable fixed position only if program list reaches the bottom of the screen
  ${(props) =>
    props.isProgramListAtBottom
      ? `
        position: fixed;
        bottom: calc(24px + env(safe-area-inset-bottom));`
      : ''}

  @media (max-width: ${Sizes.breakPointMid}) {
    width: calc(100% - 32px);
    position: fixed;
    bottom: calc(24px + env(safe-area-inset-bottom));
  }
`;
export const SpaceDivider = styled.div<{ extraSpace?: boolean }>`
  width: 100%;

  ${(props) =>
    props.extraSpace
      ? `
      padding-top: 100px;`
      : 'padding-top: 16px;'}
`;

export const ProgramListContainer = styled.div`
  width: 100%;
`;

export const FlexCenter = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const plusSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 3.75C10.8452 3.75 11.125 4.02982 11.125 4.375V9.375H16.125C16.4702 9.375 16.75 9.65482 16.75 10C16.75 10.3452 16.4702 10.625 16.125 10.625H11.125V15.625C11.125 15.9702 10.8452 16.25 10.5 16.25C10.1548 16.25 9.875 15.9702 9.875 15.625V10.625H4.875C4.52982 10.625 4.25 10.3452 4.25 10C4.25 9.65482 4.52982 9.375 4.875 9.375H9.875V4.375C9.875 4.02982 10.1548 3.75 10.5 3.75Z"
      fill="white"
    />
  </svg>
);

export const HeaderDecorator = styled.div`
  width: 100%;
  align-self: flex-start;
`;

export const ProgramCardDecorator = styled.div`
  width: 100%;
`;
