import styled from 'styled-components';

export const SvgContainer = styled.div<{
  height?: number;
  width?: number;
}>`
  // reset button style
  background: inherit;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  text-transform: inherit;
  letter-spacing: inherit;

  height: 'auto';
  width: 'auto';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & svg {
    height: ${(props) => (props.height ? `${props.height}px` : 'initial')};
    width: ${(props) => (props.width ? `${props.width}px` : 'initial')};
  }
`;
