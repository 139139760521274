import { Button } from '@visikon/spine';
import { Spinner } from '../../common/Spinner';
import { I18N } from '../../internationalization/AppText';
import { ReactNode } from 'react';

export const SubmitOrSpinner = (p: { disabled: boolean; loading: boolean; text?: ReactNode }) =>
  p.loading ? (
    <Spinner />
  ) : (
    <Button type="submit" tabIndex={0} disabled={p.disabled}>
      {p.text ?? <I18N text="share" />}
    </Button>
  );
