import { Translation } from '@visikon/core-models/languageTypes';
import { getTranslation } from '@visikon/core-models/typeUtils';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import * as React from 'react';
import { PropsWithChildren, useEffect } from 'react';
import styled from 'styled-components';
import { useContent, useLanguage } from '../../common/selectors';
import { WithFooter } from '../../components/Footer';
import { FlexView, FlexWrapper, SmallViewSidePadding } from '../../components/styled/ContentAreaContainers';
import { I18N } from '../../internationalization/AppText';
import { ContentData, PhampletUrlType, Phamplets, isUrlType } from '../../reducers/resourcesReducer';
import { PhampletCardComponent } from './PhampletCardComponent';
import { PhampletView } from './PhampletView';
import { PhampletWebView } from './PhampletWebView';
import { Text } from '@visikon/core-models/content';
import { ContentConfig } from '../../common/ContentConfig';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavLinkHelper } from '../../common/NavigationHelper';
import { ContentContainerSection } from '../../components/ContentContainerSection';
import { setNavLink } from '../../actions/userActions';

const SmallHeader = styled.p`
  line-height: 1.5;
  margin-top: 0;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    margin-top: 0.8em;
  }
`;

export type TextListItem = ContentData['phamplets']['translations'][0]['data']['textList'][0];

export const PhampletScreenComponent: React.FC<PropsWithChildren & { fieldKey: keyof ContentConfig; phamplet?: Translation<Phamplets> }> = ({
  fieldKey,
  phamplet,
  children,
}) => {
  const phampletFromContent = useContent<Phamplets>(fieldKey);
  const content = phamplet || phampletFromContent;
  const language = useLanguage();
  const singleItem = content?.data?.textList?.length === 1;
  const dispatch = useDispatch();
  const location = useLocation();

  const { contentNavLink } = useNavLinkHelper();
  useEffect(() => {
    console.log('history.location', location);
    if (location.pathname.includes('documents/')) {
      dispatch(setNavLink(contentNavLink));
    }
  }, [location]);
  const renderPhampletList = () => {
    if (content?.data) {
      return (
        <FlexView style={{ flexWrap: 'wrap' }}>
          <main style={{ width: '100%', height: '100%' }} role="main">
            {content.data.textList.map((t: TextListItem, i: number) => (
              <PhampletCardComponent key={t.textTitle} text={t.text} textIndex={i} textTitle={t.textTitle} image={t.image} fieldKey={fieldKey} />
            ))}
          </main>
        </FlexView>
      );
    }
    return <div />;
  };

  if (singleItem) {
    const singleDoc = content.data.textList[0];
    const text = getTranslation(singleDoc.text, language);

    if (isUrlType(singleDoc.text)) {
      return <PhampletWebView webData={(text as Translation<PhampletUrlType>)!.webData} title={singleDoc.textTitle} />;
    } else {
      return <PhampletView text={((text as Translation<Text>)!.content as any).document} title={singleDoc.textTitle} />;
    }
  }

  return (
    <>
      <FlexWrapper>
        <ContentContainerSection>
          <SmallViewSidePadding>
            <WithFooter>
              <header>
                {children}
                <SmallHeader>
                  <I18N text="read_more_about_your_treatment" />
                </SmallHeader>
              </header>
              {renderPhampletList()}
            </WithFooter>
          </SmallViewSidePadding>
        </ContentContainerSection>
      </FlexWrapper>
    </>
  );
};
