import { Content } from '@visikon/core-models';
import { getTranslation } from '@visikon/core-models/typeUtils';
import { EnvironmentName } from '@visikon/backbone/src/env';
import { backbone } from '@visikon/backbone/src';
import { LanguageCode } from '@visikon/core-models/content';

export class MediaFileUtils {
  public static getUriForImage(id: string, language: Content.LanguageCode) {
    return `${backbone.store.getState().hosts.core}media/image/file/${id}/${language}`;
  }

  public static getUriForThumbnail(video: Content.Video, language: Content.LanguageCode) {
    const translationsWithThumbs = video.translations.filter((t) => t.thumbnailSrc);
    if (translationsWithThumbs.length === 0) {
      return undefined;
    }

    const trans = getTranslation(video, language);
    if (!trans) {
      return undefined;
    }

    const transToUse = translationsWithThumbs.find((t) => t.language === trans.language) ? trans : translationsWithThumbs[0];
    if (backbone.store.getState().env === EnvironmentName.Local) {
      const modificationDate = trans.lastModified;
      return `${backbone.store.getState().hosts.core}media/videoThumbnail/${video._id}/${transToUse.language}/${
        backbone.store.getState().MOCKmediaVariation
      }?lastModified=${modificationDate}`;
    }

    return `${backbone.store.getState().hosts.blobs}${transToUse.thumbnailSrc}`;
  }

  public static getUriForSubtitles(video: Content.Video, language: LanguageCode, subtitlesKey: string) {
    const translationsWithSubtitles = video.translations.filter((t) => t.subtitles);
    if (translationsWithSubtitles.length === 0) {
      return undefined;
    }

    const trans = getTranslation(video, language);
    if (!trans || !trans.subtitles || !trans.subtitles.find((subtitles) => subtitles.key === subtitlesKey)) {
      return undefined;
    }

    const transToUse = translationsWithSubtitles.find((t) => t.language === trans.language) ? trans : translationsWithSubtitles[0];
    if (backbone.store.getState().env === EnvironmentName.Local) {
      const modificationDate = trans.lastModified;
      return `${backbone.store.getState().hosts.core}media/subtitles/${video._id}/${transToUse.language}/${
        backbone.store.getState().MOCKmediaVariation
      }/${subtitlesKey}?lastModified=${modificationDate}`;
    }

    return `${backbone.store.getState().hosts.blobs}${trans.subtitles.find((subtitles) => subtitles.key === subtitlesKey)?.src}`;
  }
}
