import styled from 'styled-components';

export default styled.h1`
  // Reset CSS
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
`;
