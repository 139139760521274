import { IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import * as React from 'react';
import styled from 'styled-components';

const BaseDiv = styled.div`
  align-self: center;
  padding-right: 1.8rem;
  padding-left: 1.5rem;
`;

export const Caret = styled(BaseDiv)<{ isOpen: boolean }>`
  display: flex;
  position: absolute;
  right: 0;
  transform: rotate(${(props) => (props.isOpen ? '90' : '0')}deg);
  transition: transform 0.05s ease;
`;

export const CaretSpacer = styled(BaseDiv)``;

export type Props = {
  isOpen: boolean;
  style?: React.CSSProperties;
};

export function Chevron(props: Props) {
  return (
    <>
      <CaretSpacer style={props.style} aria-hidden />
      <Caret isOpen={props.isOpen} style={props.style} aria-hidden>
        <IonIcon icon={chevronForwardOutline} size="large" color={'primary'} />
      </Caret>
    </>
  );
}
