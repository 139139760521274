// Please, do not update or move this file
// Content is overwritten in the CI appflow process
// See the ../scripts/set_env.sh file

// Will be qa | prod - set as environment var in appflow
export let CI_APPFLOW_BUILD = undefined;
// This is the name of the target branch, ex 'master'
export let CI_GIT_REF = undefined;
// This is the target platform chosen when building in appflow ie. ClientType
export let CI_PLATFORM = undefined;
// Secret stored in environment in appflow
export let API_KEY = undefined;
