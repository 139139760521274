import { useEventHandler } from '@visikon/utils';
import DialogPolyfill from 'dialog-polyfill';
import 'dialog-polyfill/dist/dialog-polyfill.css';
import React from 'react';
import { createPortal } from 'react-dom';
import { classes } from 'typestyle';
import { dialogStyle } from './Dialog.styles';

const DialogContext = React.createContext<DialogOptions | null>(null);
DialogContext.displayName = 'DialogContext';

interface DialogOptions {
  /**
   * Whether the dialog can be dismissed by clicking the backdrop, using the escape key
   * This also adds a dismiss button to the dialog header if [DialogHeader]({@link ./DialogHeader.tsx}) is used in the dialog
   * @defaultValue true
   */
  dismissable: boolean;
}

interface DialogProps extends DialogOptions {
  /**
   * Reference to the underlying dialog element
   */
  _ref?: React.Ref<HTMLDialogElement>;

  /**
   * Whether to use a portal to render the dialog
   * @defaultValue true
   */
  usePortal: boolean;
  /**
   * Target element to render the dialog in when using {@link usePortal}
   * @defaultValue Targets {@link document.body} by default
   */
  portalTarget: Parameters<typeof createPortal>[1];
}

export function Dialog({
  _ref,
  className,
  dismissable = true,
  usePortal = true,
  portalTarget = document.body,
  ...htmlDialogOptions
}: React.PropsWithChildren<React.DialogHTMLAttributes<HTMLDialogElement>> & Partial<DialogProps>) {
  const dialogRef = React.useRef<HTMLDialogElement>(null);

  const refCallback: React.RefCallback<HTMLDialogElement> = (elm) => {
    if (elm) DialogPolyfill.registerDialog(elm);
    if (_ref) {
      const mutableRef = _ref as React.MutableRefObject<HTMLDialogElement | null>;
      mutableRef.current = elm;
    }

    // For internal usage
    const mutableDialogRef = dialogRef as React.MutableRefObject<HTMLDialogElement | null>;
    mutableDialogRef.current = elm;
  };

  useEventHandler(
    dialogRef,
    'mousedown',
    (ev) => {
      // TODO: Also support dismissal when pressing the pollyfilled backdrop
      // When target equals currentTarget, the click was on the backdrop
      if (ev.target === ev.currentTarget) {
        dialogRef.current?.close();
      }
    },
    dismissable,
  );

  // Prevent closing the dialog by using the escape key if not dismissable
  useEventHandler(dialogRef, 'cancel', (event) => event.preventDefault(), !dismissable);

  const dialog = (
    <DialogContext.Provider value={{ dismissable }}>
      <dialog ref={refCallback} className={classes(dialogStyle, className)} {...htmlDialogOptions} />
    </DialogContext.Provider>
  );
  return usePortal ? createPortal(dialog, portalTarget) : dialog;
}

export function useDialogContext() {
  return React.useContext(DialogContext);
}
