import { Button } from '@visikon/spine/src';
import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';

const StarButtonStyles = styled(Button)`
  min-width: unset;
  padding: 0px;
  display: flex;
  justify-content: center;
  border: unset;
  background: transparent !important;
  box-shadow: none;
  color: ${DeprecatedTokens.secondary}!important;
  border-radius: 5px;
  align-items: center;

  margin-bottom: 0.67em !important;
  margin-top: 24px !important;
  white-space: nowrap;

  &:hover {
    background: ${DeprecatedTokens.background};
    border-radius: 5px;
  }

  svg {
    & path {
      fill: ${DeprecatedTokens.secondary};
    }
    padding-right: 0.5em;
  }
`;

const star = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none">
    <path
      d="m11.0002 15.8315 5.665 3.4192-1.5034-6.4442 5.005-4.33585-6.5908-.55917-2.5758-6.0775-2.57587 6.0775-6.59083.55917 5.005 4.33585-1.50334 6.4442 5.66504-3.4192Z"
      fill="#D41D49"
    />
  </svg>
);

export const StarButton = ({ onClick, children }: { color: string; onClick: () => void; children: React.ReactNode }) => {
  return (
    <StarButtonStyles onClick={onClick}>
      {star}
      {children}
    </StarButtonStyles>
  );
};
