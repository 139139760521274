import { GetStorage } from '@visikon/utils/src/storage';

/**
 * Ensure that program codes get's translated into the real EndUser code
 * generate first time this device used the program code.
 *
 * @param code code to check
 * @returns the real program code which should be passed to Core auth endpoint
 */
const STORAGE_PROGRAM_CODE_KEY_PREFIX = 'program-codes:';

export const ProgramCodeStorage = {
  translateCode: async (code: string) => {
    const realCode = await GetStorage().getItem(STORAGE_PROGRAM_CODE_KEY_PREFIX + code);
    return {
      code: realCode !== null ? realCode : code,
    };
  },
  persist: async (programCode: string, realCode?: string) => {
    if (realCode) {
      await GetStorage().setItem(STORAGE_PROGRAM_CODE_KEY_PREFIX + programCode, realCode);
    }
  },
};
