import { usePromise } from '../../common/utils';
import { ProgramsStorage, StoredPrograms } from '../../local-storage/programs-storage';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers/reducer';
import * as React from 'react';
import { listAvailablePrograms, ProgramInfo } from '../../common/listPrograms';
import { Country, getCountryByLanguageCode } from '@visikon/core-models/i18n/languages';
import { backbone } from '@visikon/backbone';
import { archiveProgram } from '../../actions/userActions';

export const useProgramList = () => {
  const dispatch = useDispatch();
  const country = backbone.store.getState().country;
  const programs = usePromise<StoredPrograms | undefined>(ProgramsStorage.get);
  const programsData = useSelector((state: IState) => state.userData.programsData);

  const programList = React.useMemo(() => {
    const programList = listAvailablePrograms(programs?.availablePrograms, country.languageCode, programsData);
    programList.sort((a, b) => (!a.order || !b.order ? 0 : a.order - b.order));

    return programList;
  }, [programs, programsData, country.languageCode]);

  const archive = (programId: string) => {
    dispatch(archiveProgram(programId));
  };

  return { programList, archive };
};

export function useCountryCodesFromProgramList(programList: ProgramInfo[]): Country[] {
  return React.useMemo(() => {
    return programList.reduce<Country[]>((countries, program) => {
      // Loop over all languages in the program
      program.languages.forEach((languageCode) => {
        // If the country is already in the list, skip it
        if (countries.some((country) => country.languageCode === languageCode)) return;

        // Otherwise, add it
        const country = getCountryByLanguageCode(languageCode);
        countries.push(country);
      });

      return countries;
    }, []);
  }, [programList]);
}
