import { useBackbone } from '@visikon/backbone/src';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import { EnvironmentName } from '@visikon/backbone/src/env';
import { reducer } from './reducers/reducer';
import sagas from './sagas/sagas';
import { setIsUpdating } from './actions/resourcesActions';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { authenticateFromStorage } from './actions/authActions';
import { isStartAuthOrSetUserRoute } from './sagas/utils';

export function useInitRedux() {
  const env = useBackbone((state) => state.env);

  // Setup sagas
  const sagaMiddleware = createSagaMiddleware();

  // Only attach Redux devtools in dev mode
  let composeEnhancers = compose;
  if (env === EnvironmentName.Local) {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(sagas);

  if (!isStartAuthOrSetUserRoute()) {
    store.dispatch(authenticateFromStorage()); // Load stuff from storage
  }

  initServiceWorkers(store);

  return store;
}

export function initServiceWorkers(store: any) {
  const config = {
    onUpdate: (registration) => {
      // force unregister + reload
      store.dispatch(setIsUpdating());
      registration.unregister().then(() => {
        window.location.reload();
      });
    },
    onSuccess: (registration) => {},
  };

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register(config);
}
