import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';

export const ScreenHeader = styled.h1`
  margin-top: 0px;
  padding-top: 22px;
  color: ${DeprecatedTokens.dark};
  font-size: 1.6em;
  font-weight: 600;
  line-height: 1.5;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    font-size: 1.25em;
  }
`;
export const SubHeader = styled.p`
  line-height: 1.5;
  margin-top: 0.8em;
  width: 75%;

  @media all and (max-width: ${DeprecatedTokens.breakPointMid}) {
    width: 100%;
  }
`;
