import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import { StatusNotice } from '../screens/program/StatusNotice';

export const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;

  background: ${DeprecatedTokens.primaryBackground};

  padding: 0 40px;

  @media all and (max-width: ${Sizes.breakPoint}) {
    padding: 0 0;
  }

  @media print {
    display: block;
    background: transparent;
  }
`;

export function ContentContainerSection(props: React.PropsWithChildren<{ style?: React.CSSProperties }>) {
  return (
    <ContentContainer style={props.style}>
      <StatusNotice />
      {props.children}
    </ContentContainer>
  );
}
