import { style } from 'typestyle';
import { Tokens } from '../../styles/tokens';

export const cardStyle = style({
  display: 'flex',
  flexDirection: 'column',

  // TODO: Test if it works with older iOS versions
  gap: 4,

  padding: 16,
  borderWidth: 1,
  borderRadius: 6,
  borderStyle: 'solid',

  borderColor: Tokens.color.border,
  backgroundColor: Tokens.color.white,
});
