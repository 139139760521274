import { i18n } from './i18n';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import { useLanguage } from '../common/selectors';
import { LanguageCode } from '@visikon/core-models/content';

interface I18NProps {
  text: TextKeys | '';
  languageCode?: LanguageCode;
  replace?: { [token: string]: string };
}

export function i18N(text: TextKeys | '', languageCode?: LanguageCode, replace?: { [token: string]: string }): any {
  const language = languageCode || useLanguage();
  let cleanText = text.toLowerCase().trim();
  cleanText = cleanText.replace(' ', '_');
  const translation = i18n(cleanText as TextKeys, language, replace);
  return translation.replace(/\\n/g, '\n');
}

export function I18N(props: I18NProps): any {
  return i18N(props.text, props.languageCode, props.replace);
}

interface SetDocumentTitleProps extends I18NProps {
  label?: string;
  textNoTranslation?: string;
}

export function SetDocumentTitle(props: SetDocumentTitleProps): null {
  const language = useLanguage();

  if (props.label) {
    window.document.title = props.label;
  } else if (props.textNoTranslation) {
    // Set title without translations
    window.document.title = `${i18n('my_treatment_name', language)} - ${props.textNoTranslation}`;
  } else if (props.text === '') {
    // No change
    window.document.title = `${i18n('my_treatment_name', language)}`;
  } else {
    window.document.title = `${i18n('my_treatment_name', language)} - ${i18n(props.text, language)}`;
  }

  return null;
}
