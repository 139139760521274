import { Content } from '@visikon/core-models';
import { LanguageCode } from '@visikon/core-models/content';
import { GetStorage } from '@visikon/utils/src/storage';
import { Country, getCountryByLanguageCode } from '@visikon/core-models/i18n/languages';
import { json } from 'stream/consumers';

const STORAGE_LANGUAGE_KEY = 'local-storage-language-key';
const STORAGE_COUNTRY_KEY = 'local-storage-country-key';

// deprecated - use CountryStorage instead
export const LanguageStorage = {
  get: async () => ((await GetStorage().getItem(STORAGE_LANGUAGE_KEY)) as Content.LanguageCode) || null,
  set: async (language: LanguageCode) => {
    console.log(`Saved preferred language ${language}`);
    await GetStorage().setItem(STORAGE_LANGUAGE_KEY, language);
  },
};

export const CountryStorage = {
  get: async () => {
    const countryString = (await GetStorage().getItem(STORAGE_COUNTRY_KEY)) || null;
    if (!countryString) {
      // Fallback try to use stored language
      const storedLanguage = LanguageStorage.get();
      if (!storedLanguage) {
        const countryFromLanguageCode = getCountryByLanguageCode(storedLanguage);
        await CountryStorage.set(countryFromLanguageCode);
        // delete to old language key
        await GetStorage().removeItem(STORAGE_LANGUAGE_KEY);

        return countryFromLanguageCode;
      }
    }
    return countryString ? JSON.parse(countryString) : null;
  },
  set: async (country: Country) => {
    console.log(`Saved preferred country ${country}`);
    await GetStorage().setItem(STORAGE_COUNTRY_KEY, JSON.stringify(country));
  },
};
