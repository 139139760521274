import { HeaderIcon } from './styled/HeaderIcon';
import { i18N } from '../internationalization/AppText';
import { useHistory } from 'react-router-dom';
import { SvgIcon } from '../images/svgIcon';
import { ReactComponent as Logout } from '../images/svg/logout.svg';
import styled from 'styled-components';
import { useRef } from 'react';
import { ConfirmLogoutDialog } from '../screens/header/ConfirmLogoutDialog';
import { tracker } from '@visikon/tracker/src';
import { Unstyled } from './Unstyled';

export const LogoutHeaderIcon = styled(HeaderIcon)`
  margin-right: 0;
  flex-direction: row;
  align-items: center;
  @media all and (max-width: 300px) {
    margin-left: 2px;
  }
`;

export function LogoutIcon() {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const history = useHistory();
  return (
    <>
      <Unstyled.Button onClick={() => dialogRef.current?.showModal()} title={i18N('log_out')}>
        <LogoutHeaderIcon aria-hidden="true">
          <SvgIcon Icon={Logout} width={24} aria-hidden />
        </LogoutHeaderIcon>
      </Unstyled.Button>
      <ConfirmLogoutDialog
        dialogRef={dialogRef}
        onConfirm={() => {
          tracker.trackEvent('Auth', 'logout');
          history.push('/logout');
        }}
      />
    </>
  );
}
