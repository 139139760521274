import { cva } from 'class-variance-authority';
import { style } from 'typestyle';
import { Tokens } from '../../styles/tokens';
import { isSafari } from 'react-device-detect';

export const codeInputStyle = style({
  height: '2.45em',
  width: '100%',
  padding: '1em 0 1em 1em',
  letterSpacing: isSafari ? '0.98em' : '1em',
  textAlign: 'justify',
  background: Tokens.color.white,
  borderWidth: 1,
  borderRadius: 4,
  borderStyle: 'solid',
  borderColor: Tokens.color.border,
  boxSizing: 'border-box',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
  fontFamily: `${Tokens.text.family.monospace}!important`,
  fontSize: Tokens.size.text.large,
  fontWeight: Tokens.text.weight.semibold,
  textTransform: 'uppercase',
  color: Tokens.color.primaryHover,
  '-webkit-appearance': 'none',
  $nest: {
    '&:focus': {
      outline: 'unset',
      borderColor: Tokens.color.primaryFocus,
      backgroundColor: Tokens.color.white,
    },
    '&::placeholder': {
      color: Tokens.color.placeHolder,
    },
  },
  $debugName: 'CodeInput',
});

const variants = {
  state: {
    empty: style({}),
    error: style({
      borderColor: Tokens.color.danger,
      color: Tokens.color.danger,
    }),
  },
};

export const codeInputStyles = cva(codeInputStyle, {
  variants,
  defaultVariants: { state: 'empty' },
});

export type CellStates = keyof (typeof variants)['state'];
