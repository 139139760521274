import * as React from 'react';
import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import Modal from 'react-modal';
import './ModalStyles.css';
import FocusTrap from 'focus-trap-react';

const ClickOverlay = styled.div<{ alpha?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  inset: 0px;
  overflow: auto;
`;

const CloseButton = styled.button<{ top: number; left: number }>`
  // reset button style
  padding: 0;
  font: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  border: none;

  position: absolute;
  top: ${(p) => p.top - 15}px;
  left: ${(p) => p.left + 15}px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  box-shadow: ${DeprecatedTokens.shadowColor} 0px 0px 10px;
  z-index: 5;
  background: white;
  text-decoration: none;
  color: black;

  cursor: pointer;
  font-size: 2em;
  display: flex;
  justify-content: center;
  line-height: 40px;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    top: 50px;
    right: 50px;
    left: auto;
  }
`;

const ContentWrapper = styled.div<{
  width: number;
  top: number;
  left: number;
  background?: string;
}>`
  width: ${(props) => props.width}px;
  background-color: ${(props) => props.background || 'white'};
  border-radius: 3px;
  box-shadow: ${DeprecatedTokens.shadowColor} 0px 0px 10px;
  padding: 20px;
  overflow: auto;

  /* Position the tooltip */
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    max-height: none !important;
    margin: 40px;
  }
`;

export const PopupContent = (props: IPopupContentProps) => {
  const handleOnClickContent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const {
    content,
    trigger,
    confirmDialog,
    width,
    height,
    positionTop,
    positionLeft,
    handleOverlayClick,
    background,
    overlayAlpha,
    maxHeight,
    showCloseButton,
  } = props;

  const heightCss = height ? { height } : {};
  const maxHeightCss = maxHeight ? { maxHeight: maxHeight - positionTop } : {};
  const overlayBackground = overlayAlpha && overlayAlpha >= 0.0 && overlayAlpha <= 1.0 ? `rgba(0,0,0,${overlayAlpha})` : '#00000033';

  return (
    <Modal
      className="ReactModal__Content"
      overlayElement={
        // eslint-disable-next-line
        (props, contentElement) => <aside {...props}>{contentElement}</aside>
      }
      isOpen={true}
      ariaHideApp={false}
      style={{
        content: { top: '0', left: '0', right: '0', bottom: '0' },
        overlay: { backgroundColor: 'none', zIndex: 1002 },
      }}
    >
      <FocusTrap focusTrapOptions={{ preventScroll: true }}>
        <ClickOverlay id="overlay" onClick={handleOverlayClick} style={{ backgroundColor: overlayBackground }}>
          {showCloseButton && (
            <div>
              <CloseButton
                aria-label="Close button ✕"
                onClick={() => handleOverlayClick}
                tabIndex={0}
                top={positionTop}
                left={positionLeft + (width || 400)}
              >
                ✕
              </CloseButton>
            </div>
          )}
          <ContentWrapper
            background={background}
            top={positionTop}
            left={positionLeft}
            width={width || 400}
            style={{ ...heightCss, ...maxHeightCss }}
            onClick={handleOnClickContent}
          >
            {React.cloneElement(content, { handleBackClick: handleOverlayClick })}
            {trigger}
            {confirmDialog}
          </ContentWrapper>
        </ClickOverlay>
      </FocusTrap>
    </Modal>
  );
};

interface IPopupContentProps {
  content: JSX.Element;
  width?: number;
  height?: number;
  positionTop: number;
  positionLeft: number;
  background?: string;
  overlayAlpha?: number;
  maxHeight?: number;
  trigger?: JSX.Element;
  confirmDialog?: JSX.Element;
  showCloseButton?: boolean;

  handleOverlayClick(): void;
}
