import { useMutation } from '@tanstack/react-query';
import { HttpError, useApiClient } from './query-utils';
import { LoginResponse, MergeErrorString, StaffLoginRequest, TranslatableError, UserLoginRequest } from './auth-api.models';
import { backbone } from '@visikon/backbone/src';
import { tracker } from '@visikon/tracker/src';
import { useDispatch } from 'react-redux';
import * as AuthActions from '../actions/authActions';
import { changeCountry, fetchPrograms, setError } from '../actions/resourcesActions';
import { SessionStorage } from '@visikon/backbone/src/session-storage';
import { ProgramCodeStorage } from '../local-storage/program-code-storage';
import { useHistory } from 'react-router-dom';
import { HOME_ROUTE } from '../screens/home/HomeScreen';
import { Country } from '@visikon/core-models/i18n/languages';
import { ProgramsStorage } from '../local-storage/programs-storage';
import { setNewPrograms } from '../actions/userActions';
import { fetchPost } from '@visikon/utils/src/utils';

export function useAuthenticateByCode() {
  const apiClient = useApiClient();
  const dispatch = useDispatch();
  const history = useHistory();

  return useMutation<LoginResponse, HttpError, UserLoginRequest>(
    async (payload: UserLoginRequest) => {
      try {
        const translatedCode = await ProgramCodeStorage.translateCode(payload.code);
        // Added a 500ms delay to show the spinner
        await new Promise((resolve) => setTimeout(resolve, 500));
        return (await apiClient.post<any>('authEndUser', translatedCode)).data;
      } catch (error: any) {
        const responseCode = error?.response.data.error as string;
        console.log('error', error, responseCode);
        dispatch(setError(responseCode));
        throw HttpError.axiosError(error);
      }
    },
    {
      onSuccess: async (data, payload) => {
        const { realCode } = data;

        tracker.trackEvent('Auth', 'loginSuccess');
        await ProgramCodeStorage.persist(payload.code, realCode);
        dispatch(AuthActions.authenticateSuccessful(data));
        dispatch(setError(undefined));
        history.push(HOME_ROUTE, { from: history.location.pathname });
      },
    },
  );
}

export function useAuthenticateByEmail() {
  const apiClient = useApiClient();
  const dispatch = useDispatch();
  const history = useHistory();

  return useMutation<LoginResponse, HttpError, StaffLoginRequest>(
    async (payload: StaffLoginRequest) => {
      try {
        return (await apiClient.post<any>('auth', payload)).data;
      } catch (error) {
        throw HttpError.axiosError(error);
      }
    },
    {
      onSuccess: async (data) => {
        tracker.trackEvent('Auth', 'staffLoginSuccess');

        dispatch(AuthActions.authenticateSuccessful(data));
        history.push(HOME_ROUTE, { from: history.location.pathname });
      },
    },
  );
}

interface CodeMergeSuccessResponse {
  newResources: string[];
  resources: string[];
}

// Adapted from redux saga
// https://github.com/Visikon/mono/blob/0f115e3c41c144736bc86c700f704db643a202fc/frontends/mytreatment/src/sagas/shareProgramSaga.ts#L74
export function useMergeUserMutation() {
  const dispatch = useDispatch();

  return useMutation<CodeMergeSuccessResponse, TranslatableError | unknown, { code: string; country?: Country }>({
    mutationFn: async ({ code, country }) => {
      const activeCountry = backbone.store.getState().country;
      const response = await fetchPost('users/mergeCode', {}, { code });
      const result = await response.json();

      // If the language is different from the active language, change it
      if (country && country.languageCode !== activeCountry.languageCode) {
        dispatch(changeCountry(country));
      }

      if (result.error) {
        const error = `merge_error_${result.error}` as MergeErrorString;
        tracker.trackEvent('HomeScreen', 'AddProgram', 'Failure', error);
        throw new TranslatableError(error);
      }

      tracker.trackEvent('HomeScreen', 'AddProgram', 'Success');
      await ProgramsStorage.clear();

      const { newResources, resources }: CodeMergeSuccessResponse = result;

      return { newResources, resources };
    },
    onSuccess: async ({ newResources, resources }) => {
      const { activeProgramId } = (await ProgramsStorage.get()) || {};
      const newId = newResources.find((id) => id !== activeProgramId);

      // Mark merged resources as new
      dispatch(setNewPrograms(newId ? newResources : resources));

      // TODO: Remove this when program list is using react query as well
      dispatch(fetchPrograms({}));
    },
  });
}
