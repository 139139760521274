import { classes, style } from 'typestyle';
import { Tokens } from './tokens';
import * as DefaultStyles from './default.styles';

export namespace Styles {
  export const size = {
    mammoth: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.mammoth,
      }),
    ),

    elephant: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.elephant,
      }),
    ),

    epic: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.epic,
      }),
    ),

    h1: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.h1,
      }),
    ),

    h2: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.h2,
      }),
    ),

    h3: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.h3,
      }),
    ),

    h4: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.h4,
      }),
    ),

    h5: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.h5,
      }),
    ),

    large: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.large,
      }),
    ),

    medium: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.medium,
      }),
    ),

    small: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.small,
      }),
    ),

    tiny: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontSize: Tokens.size.text.tiny,
      }),
    ),
  };

  export const weight = {
    bold: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontWeight: Tokens.text.weight.bold,
      }),
    ),

    semibold: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontWeight: Tokens.text.weight.semibold,
      }),
    ),

    regular: classes(
      DefaultStyles.Styles.default.text,
      style({
        fontWeight: Tokens.text.weight.regular,
      }),
    ),
  };
}
