import styled from 'styled-components';

export const Label = styled.label`
  display: block;
  font-size: 1.2em;
`;

export const InputText = styled.input`
  font-size: 1em;
  height: 34px;
  width: 95%;
  padding-left: 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;

  &.error {
    box-shadow: inset 0 1px 2px rgba(255, 0, 0, 0.89), 0 -1px 1px #fff, 0 1px 0 #fff;
    border-bottom: 1px solid #ff6363;
  }

  &::placeholder {
    font-size: 0.9em;
  }
`;

export const TextArea = styled.textarea`
  width: calc(100% - 42px);
  font-size: 1em;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
`;
