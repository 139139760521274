import { i18N } from '../../internationalization/AppText';
import { Translation } from '@visikon/core-models/languageTypes';
import { Content } from '@visikon/core-models';
import { Dialog, DialogAnimations, DialogBody, DialogHeader } from '@visikon/spine';
import { DocumentRenderer } from '../../components/documentViewer/DocumentRenderer';

type Props = {
  dialogRef: React.RefObject<HTMLDialogElement>;
  document: Translation<Content.Text>;
};

export const ContactDialog = ({ dialogRef, document }: Props) => {
  return (
    <Dialog className={DialogAnimations.SlideDown} _ref={dialogRef}>
      <DialogHeader>{i18N('contact')}</DialogHeader>
      <DialogBody>
        <DocumentRenderer document={(document!.content as any).document} style={{ padding: 'unset' }} />
      </DialogBody>
    </Dialog>
  );
};
