import { cva } from 'class-variance-authority';
import * as ColorStyles from './colors.styles';
import * as SizeStyles from './size.styles';
import * as DefaultStyles from './default.styles';

export namespace Styles {
  export const text = {
    default: DefaultStyles.Styles.default.text,

    format: cva(DefaultStyles.Styles.default.text, {
      variants: {
        size: {
          large: [SizeStyles.Styles.size.large],
          medium: [SizeStyles.Styles.size.medium],
          small: [SizeStyles.Styles.size.small],
          tiny: [SizeStyles.Styles.size.tiny],
        },

        weight: {
          bold: [SizeStyles.Styles.weight.bold],
          semibold: [SizeStyles.Styles.weight.semibold],
          regular: [SizeStyles.Styles.weight.regular],
        },

        muted: {
          true: [ColorStyles.Styles.color.disabled],
        },
      },
    }),
  };
}
