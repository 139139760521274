import { Button } from '@visikon/spine/src';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import { tracker } from '@visikon/tracker/src';
import { WindowSize, useWindowSizeMatches } from '@visikon/utils/src/responsive';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import { setIcons } from '../../actions/resourcesActions';
import { WithFooter } from '../../components/Footer';
import { SideBarContainer } from '../../components/SideBarContainer';
import { DocumentRenderer } from '../../components/documentViewer/DocumentRenderer';
import { PopupContent } from '../../components/popup/PopupContent';
import { FlexWrapper, SideBarContent } from '../../components/styled/ContentAreaContainers';
import { ReactComponent as tocIcon } from '../../images/svg/toc.svg';
import { SvgIcon } from '../../images/svgIcon';
import { I18N, SetDocumentTitle } from '../../internationalization/AppText';
import { i18n } from '../../internationalization/i18n';
import { DocumentJSON } from '../../types/slate-types';
import { LinkButton } from '../startcode/StartCodeScreen.styles';
import { DocumentWrapper, PrintButtonWrapper } from './PhampletSharedComponents';
import { TableOfContentComponent } from './TableOfContentComponent';
import { ContentContainer, ContentContainerSection } from '../../components/ContentContainerSection';
interface IProps {
  text?: DocumentJSON;
  title?: string;
}

const Space = styled.div<{ height: number }>`
  min-height: ${(p) => p.height}px;
`;

export const PhampletView = ({ text, title }: IProps) => {
  const docContainerRef = useRef<HTMLDivElement>(null);
  const documentWrapper = useRef<HTMLDivElement>(null);
  const [tableOfContent, setTableOfContent] = useState<string[]>();
  const [clicked, setClicked] = useState(false);
  const [printing, setPrinting] = useState(false);
  const smallWindow = useWindowSizeMatches(WindowSize.Medium);
  const dispatch = useDispatch();
  const onTocClick = (key) => {
    setClicked(!clicked);
  };

  const icon = (
    <LinkButton onClick={onTocClick} style={{ paddingRight: 12 }} aria-label={i18n('table_of_content')}>
      <SvgIcon aria-hidden Icon={tocIcon} width={24} />
    </LinkButton>
  );
  const hasToc = tableOfContent && Object.keys(tableOfContent).length > 1;
  // @ts-ignore
  useEffect(() => {
    if (hasToc && smallWindow) {
      // @ts-ignore
      dispatch(setIcons([icon]));
    } else {
      dispatch(setIcons(undefined));
    }
    return () => {
      dispatch(setIcons(undefined));
    };
  }, [hasToc, smallWindow]);

  const toc = (
    <TableOfContentComponent tocElementClicked={onTocClick} tableOfContent={tableOfContent} docContainer={docContainerRef.current || undefined} />
  );

  const handlePrint = () => {
    setPrinting(true);
    tracker.trackEvent('Phamplets', 'printClicked', title);
  };

  if (text) {
    const printButton = (
      <PrintButtonWrapper>
        <Button variant="outline" onClick={handlePrint}>
          <I18N text="print_document" />
        </Button>
      </PrintButtonWrapper>
    );
    return (
      <FlexWrapper ref={documentWrapper}>
        <SetDocumentTitle text="" textNoTranslation={`${i18n('pamphlets')} - ${title}`} />
        <ContentContainer ref={docContainerRef}>
          <DocumentWrapper>
            <WithFooter>
              <ReactToPrint trigger={() => printButton} content={() => documentWrapper.current} onAfterPrint={() => setPrinting(false)} />
              <DocumentRenderer document={text} getTableOfContent={setTableOfContent} />
            </WithFooter>
          </DocumentWrapper>
          <Space height={40} />

          {smallWindow && hasToc && clicked && (
            <PopupContent handleOverlayClick={() => onTocClick(null)} positionLeft={10} positionTop={10} width={450} content={toc} />
          )}
        </ContentContainer>

        {!smallWindow && hasToc && !printing ? (
          <SideBarContainer>
            <SideBarContent>{toc}</SideBarContent>
          </SideBarContainer>
        ) : null}
      </FlexWrapper>
    );
  }

  return null;
};
