import * as React from 'react';
import { SideBarContainer } from '../../components/SideBarContainer';
import { I18N } from '../../internationalization/AppText';
import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { tracker } from '@visikon/tracker/src';

interface IProps {
  tableOfContent?: string[];
  docContainer?: HTMLDivElement;
  tocElementClicked?: (key: string) => void;
}

interface State {
  keys: string[];
}

const TocElement = styled.li`
  max-width: 280px;
  font-weight: normal;
  margin-bottom: 20px;
  font-size: 1.2em;
  cursor: pointer;
  color: ${DeprecatedTokens.primary};
`;

export class TableOfContentComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.renderToc = this.renderToc.bind(this);
    this.getClickHandler = this.getClickHandler.bind(this);
    this.state = {
      keys: Object.keys(props.tableOfContent || {}),
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.tableOfContent !== this.props.tableOfContent) {
      this.setState({
        keys: Object.keys(this.props.tableOfContent || {}),
      });
    }
  }

  renderToc() {
    const { keys } = this.state as State;
    if (!this.props.tableOfContent) {
      return null;
    }

    return keys.map((k) => {
      const element = this.props.tableOfContent![k];
      if (element?.innerText.trim() === '') {
        return null;
      }
      return (
        <TocElement key={k}>
          <button
            type="button"
            onClick={this.getClickHandler(element)}
            style={{
              color: 'unset',
              background: 'unset',
              border: 'none',
              padding: 'inherit',
              font: 'inherit',
              textTransform: 'inherit',
              textAlign: 'left',
              cursor: 'pointer',
            }}
          >
            {element?.innerText}
          </button>
        </TocElement>
      );
    });
  }

  getClickHandler(element: HTMLElement) {
    const { docContainer } = this.props;
    return () => {
      // Perform scroll
      tracker.trackEvent('TOC', 'tocItemClick', element.innerText);
      if (this.props.tocElementClicked) {
        this.props.tocElementClicked(element.id);
      }
      if (docContainer) {
        docContainer.scrollTop = element.offsetTop;
      }
    };
  }

  render() {
    if (this.props.tableOfContent === undefined || Object.keys(this.props.tableOfContent!).length === 1) {
      return <SideBarContainer />;
    }

    return (
      <>
        <h2>
          <I18N text="table_of_content" />
        </h2>
        <ol style={{ paddingLeft: 30, textAlign: 'left' }}>{this.renderToc()}</ol>
      </>
    );
  }
}
