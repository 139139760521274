import styled from 'styled-components';
import * as React from 'react';
import { i18N, I18N } from '../internationalization/AppText';
import { Alert } from './Alert';
import { useIsOffLine } from './selectors';
import Modal from 'react-modal';
import { TestConnectionButton } from './TestConnectionButton';
import { MobileUtils } from './utils';

export function NoConnectionScreen() {
  const [isOpen, setIsOpen] = React.useState(false);
  const isOffline = useIsOffLine();
  const translatedTitle = i18N('network_no_internet_connection_title');
  const isNative = MobileUtils.isNative();

  React.useEffect(() => {
    if (isOffline) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isOffline]);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(2px)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          borderRadius: '5px',
          border: 'none',
          outline: 'none',
          padding: '20px',
          maxWidth: '440px',
          overflow: 'visible',
          position: 'relative',
        },
      }}
    >
      <Alert type={'warning'} title={translatedTitle}>
        <I18N text="network_no_internet_connection_body" />
      </Alert>
      {isNative ? <TestConnectionButton timeout={5} /> : null}
    </Modal>
  );
}
