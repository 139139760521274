import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  background-color: ${DeprecatedTokens.whiteBackground};

  & > video,
  & > div > img {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    @media all and (max-width: ${Sizes.breakPointSmall}) {
      border-radius: 0;
    }
  }

  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    //width: unset;
    flex-wrap: nowrap;
    flex-direction: column;
    flex-basis: 51%;
  }
`;

export const Thumbnail = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 305 / 172;

  // fallback
  @supports not (aspect-ratio: auto) {
    padding-top: 56.39%;
  }

  & > img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
  }
`;

export const DurationSticker = styled.div<{ text?: string }>`
  display: ${({ hidden, text }) => (hidden || !text ? 'none' : 'block')};
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: #263238;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  padding: 1.1px 5px;
  font-size: 0.75em;

  &:before {
    content: '${(props) => props.text}';
  }

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    font-size: 0.62em;
    padding: 1px 5.5px;
  }
`;

export const CardWrapper = styled.div<{ isList: boolean }>`
  padding-bottom: 2em;
  flex: 0 1 calc(33% - 0.8em);

  ${({ isList }) =>
    isList
      ? `width: 100%;
         padding-right: 0;
         padding-bottom: 0;
         flex: unset;`
      : `@media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
            width: 100%;
            padding-right: 0;
            padding-bottom: 0;
            flex: unset;
        }`};

  // fallback
  @supports not (aspect-ratio: auto) {
    padding-bottom: 1em;
  }
`;

const progressWidthCalulation = (percentage: number) => {
  // 75% or more counts as seen
  if (percentage >= 75) {
    return 100;
  }
  return percentage;
};

export const ProgressIndicator = styled.div<{ percentage: number; hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  position: absolute;
  width: ${({ percentage }) => progressWidthCalulation(percentage)}%;
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: ${DeprecatedTokens.secondary};
`;

export const CardTitle = styled.div`
  font-size: 1em;
  padding-top: 6px;
  text-decoration: none;
  color: ${DeprecatedTokens.dark};
  font-weight: 400;

  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    padding: 0;
    font-size: 0.75em;
    margin-left: 6px;
  }
`;

export const CardTitleContainer = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'inline-flex')};

  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    position: relative;
    align-items: center;
    flex-shrink: 20;
    flex-grow: 1;
    flex-wrap: wrap;
  }
`;
export const CardBorderAccent = styled.div`
  display: none;
  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    display: block;
    position: absolute;
    bottom: -0.7em;
    width: 100%;
    border-bottom: 1px solid #cfd8dc;
  }
`;

export const TotalContainerCard = styled(Link)<{ $islist: boolean; $isgif?: boolean }>`
  display: flex;
  flex-direction: column;

  text-decoration: none;
  width: -webkit-fill-available;

  .favourite-button {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 28px;
  }

  ${({ $islist }) =>
    $islist
      ? 'width: 100%;'
      : `@media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
            flex-direction: row;
            width: 100%;
        }`};

  ${({ $isgif }) => ($isgif ? 'pointer-events: none;' : '')};
`;
export const ListVideo = styled.video`
  width: 100%;
  pointer-events: none;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  border: unset !important;
  border-radius: 4px !important;
  overflow: hidden;
`;
