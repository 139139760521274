import { IStorage } from './IStorage';

function WebStorageFactory(storage: Storage): IStorage {
  return {
    async getItem(key: string): Promise<string | null> {
      return new Promise((resolve) => {
        const value = storage.getItem(key);
        resolve(value);
      });
    },
    async setItem(key: string, value: string): Promise<void> {
      return storage.setItem(key, value);
    },
    async removeItem(key: string): Promise<void> {
      return storage.removeItem(key);
    },
  };
}

export const LocalStorage = WebStorageFactory(localStorage);
export const SessionsStorage = WebStorageFactory(localStorage);
