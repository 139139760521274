import * as React from 'react';
import styled from 'styled-components';
import { I18N } from '../../internationalization/AppText';
import { ProgramInfo } from '../../common/listPrograms';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import {
  Button,
  Dialog,
  DialogActionContainer,
  DialogAnimations,
  DialogBody,
  DialogDismissButton,
  DialogHeadding,
  DialogHeader,
} from '@visikon/spine';

const color = Tokens.color;

const SubTitle = styled.div`
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
const ArchiveBody1 = styled.div`
  color: ${color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
  margin-bottom: 4px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${color.border};
  margin-top: 24px;
`;

const ArchiveBody2 = styled.div`
  color: ${color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

type Props = {
  dialogRef: React.RefObject<HTMLDialogElement>;
  program: ProgramInfo;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ArchiveProgramDialog = ({ dialogRef, program, onConfirm, onCancel }: Props) => {
  return (
    <Dialog _ref={dialogRef} className={DialogAnimations.SlideDown}>
      <DialogHeader>
        <DialogHeadding>
          <I18N text={'home_archive_title'} />
          <SubTitle>{program.name}</SubTitle>
        </DialogHeadding>
        <DialogDismissButton />
      </DialogHeader>
      <DialogBody>
        <ArchiveBody1>
          <I18N text={'home_archive_body1'} replace={{ $PROGRAM_NAME: program.name }} />
        </ArchiveBody1>
        <ArchiveBody2>
          <I18N text={'home_archive_body2'} replace={{ $PROGRAM_NAME: program.name }} />
        </ArchiveBody2>
        <Divider />
      </DialogBody>
      <DialogActionContainer>
        <Button variant="danger" size="small" onClick={onConfirm}>
          <I18N text="home_archive_program_accept" />
        </Button>
        <Button variant="ghost" size="small" onClick={onCancel}>
          <I18N text="home_archive_program_cancel" />
        </Button>
      </DialogActionContainer>
    </Dialog>
  );
};
