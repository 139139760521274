import * as React from 'react';
import styled from 'styled-components';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon } from '../../images/svgIcon';
import { ProgramAction, ProgramActionLevel } from './ActionDialog';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as people } from '../../images/svg/people.svg';
import { ReactComponent as trash } from '../../images/svg/trash.svg';
import { ReactComponent as copy } from '../../images/svg/copy.svg';
import { ReactComponent as checkmark } from '../../images/svg/checkmark.svg';

const CommandContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${Tokens.color.text};
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 8px 16px 8px;
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
    border-radius: 6px;
  }
`;

const ChevronContainer = styled.div`
  margin-left: auto;
`;
const Title = styled.div<{ level: ProgramActionLevel }>`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  color: ${Tokens.color.primary};
  ${(props) => props.level === 'warning' && `color: ${Tokens.color.warning};}`}
  ${(props) => props.level === 'danger' && `color: ${Tokens.color.danger};`}
`;

export type Props = {
  action: ProgramAction;
};

export function CommandCard(props: Props) {
  const { action } = props;

  const actionIcons = { trash, share: people, copy: copy, ok: checkmark };

  return (
    <CommandContainer
      onClick={(e) => {
        const result = action.action();
        if (!result) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <SvgIcon aria-hidden Icon={actionIcons[action.icon]} width={24} />
      <Title level={action.level}>{action.title}</Title>
      <ChevronContainer aria-hidden>
        <FontAwesomeIcon style={{ marginLeft: '16px', color: '#999999' }} icon={faChevronRight} width={12} />
      </ChevronContainer>
    </CommandContainer>
  );
}
