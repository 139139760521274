import { getTranslation } from '@visikon/core-models/typeUtils';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { PhampletView } from './PhampletView';
import { isUrlType, MitforlobContent } from '../../reducers/resourcesReducer';
import { Content } from '@visikon/core-models';
import { useFirstValidContentConfig, useLanguage, useWholeContent } from '../../common/selectors';
import { useGetRootReDirector } from '../../components/MainContent';
type OwnProps = RouteComponentProps<{ textId?: string }>;

export const PhampletViewRoute = (props: OwnProps) => {
  const content = useWholeContent() as MitforlobContent;
  const firstValidContentConfig = useFirstValidContentConfig();

  if (!firstValidContentConfig) {
    return null;
  }

  const redirect = () => {
    return useGetRootReDirector();
  };

  if (!content || Object.keys(content).length === 0 || !content.phamplets) {
    return redirect();
  }
  const textList = content.phamplets?.data.textList;
  console.log('textList', textList, props.match.params.textId);
  const relevantDocument = textList.find((text) => !isUrlType(text.text) && text.text._id === props.match.params.textId);
  if (!relevantDocument) {
    console.log('Relevant document not found');
    return redirect();
  }

  const language = useLanguage();
  const textResult = getTranslation(relevantDocument.text as Content.Text, language);
  if (!textResult) {
    console.log("Relevant document didn't contain correct translation");
    return redirect();
  }

  return <PhampletView text={(textResult?.content as any).document} title={relevantDocument.textTitle} />;
};
