import { Reducer } from "redux";
import { isType } from "typescript-fsa";
import { surveySubmit, alreadyAnswered, surveySubmitSuccess } from "../actions/surveyActions";


type AnswerIndex = number;
export interface IAnswerSingle {
    answerType: "single";
    value: AnswerIndex;
    otherText?: string;
}
export interface IAnswerMulti {
    answerType: "multi";
    value: AnswerIndex[];
    otherText?: string;
}
export interface IAnswerText {
    answerType: "text";
    value: string;
}
export type AnswerType = IAnswerSingle | IAnswerMulti | IAnswerText;
// export function isAnswerSingle(a: AnswerType): a is AnswerSingle {
//   return a.answerType === "single";
// }
// export function isAnswerMulti(a: AnswerType): a is AnswerMulti {
//   return a.answerType === "multi";
// }
// export function isAnswerText(a: AnswerType): a is AnswerText {
//   return a.answerType === "text";
// }


export interface ISurveySubmitResult {
    id: string;
    answers: string;
}
export interface ISurveyResult {
    isRunning: boolean;
    msg?: any;
    surveyResultSubmit: ISurveySubmitResult[];
}

const initialState: ISurveyResult = {
    isRunning: false,
    msg: undefined,
    surveyResultSubmit: []
}


export const surveyReducer: Reducer<ISurveyResult> = (state = { ...initialState }, action) => {
    if (isType(action, surveySubmit)) {
        const surveyResultSubmit = [action.payload];
        return { ...state, isRunning: true, surveyResultSubmit };
    }

    if (isType(action, surveySubmitSuccess)) {
        return { ...state, isRunning: false, status: "success", msg: action.payload };
    }
    if (isType(action, alreadyAnswered)) {
        return { ...state, isRunning: false, status: "failed", msg: action.payload };
    }


    return state;
}