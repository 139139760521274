import * as React from 'react';
import { useContainerContentLists } from '../../common/selectors';
import { RouteComponentProps } from 'react-router';
import { ContentContainerComponent } from './ContentContainerComponent';
import { Content } from '../../sagas/resourcesSaga';

export const CONTENT_CONTAINER_ROUTE = '/content/:id';

type RouteProps = RouteComponentProps<{ id?: string }>;

type OwnProps = {
  contentId?: string;
};
type IProps = RouteProps & OwnProps;

export function findContentContainer(content: Content[], id: string | undefined): Content | null {
  const finder = (contentContainer: Content[]) => {
    return contentContainer.reduce(
      // @ts-ignore
      (foundValue: Content, contentContainer: Content): Content | null => {
        if (contentContainer?._id === id) {
          foundValue = contentContainer;
        } else {
          if (contentContainer.data?.content) {
            foundValue = foundValue || finder(contentContainer?.data.content);
          }
        }
        return foundValue;
      },
      null,
    );
  };
  return finder(content);
}

export function findContentContainerById(id: string | undefined): Content | null {
  const contentContainers = useContainerContentLists();
  let foundContentContainer;
  for (let i = 0; contentContainers && !foundContentContainer && i < contentContainers.length; i++) {
    foundContentContainer = findContentContainer(contentContainers[i]!.data.content, id);
  }
  return foundContentContainer;
}

export const ContentContainerRoute: React.FC<IProps> = (props) => {
  const id = props.contentId || props.match.params.id;
  const foundContentContainer = findContentContainerById(id);
  return <ContentContainerComponent contentContainer={foundContentContainer!} />;
};
