import * as React from 'react';
import * as Styles from './LoginContainer.styles';
import { I18N } from '../../internationalization/AppText';
import { i18n } from '../../internationalization/i18n';
import NurseIcon from '../../images/iPad_Nurse.png';
import { useEffect, useState } from 'react';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import { tracker } from '@visikon/tracker/src';
import { ShowStaffStorage, UsernameStorage } from '../../local-storage/username-storage';
import { useAuthenticateByEmail } from '../../api/auth-api';
import { useFormik } from 'formik';
import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import { SvgIcon } from '../../images/svgIcon';
import { ReactComponent as BackIcon } from '../../images/svg/arrow_back.svg';
import { useHistory } from 'react-router-dom';
import { WindowSize, useIsMobile, useWindowSizeMatches } from '@visikon/utils/src/responsive';
import { LoginTitle, LoginTitleContainer } from './LoginContainer.styles';
import { Button } from '@visikon/spine';
import { useLanguage } from '../../common/selectors';
import { LanguageSelectorDialog } from '../../internationalization/LanguageSelectorDialog';

const Wrapper = ({ children, handleBackClick }: { children: React.ReactNode; handleBackClick: () => void }) => {
  const isMobileView = useWindowSizeMatches(WindowSize.ExtraSmall);
  const containerStyles = {
    maxWidth: '700px',
    minHeight: '600px',
    width: '100%',
    background: 'white',
    overflow: 'hidden',
    height: '100%',
  };

  return (
    <IonPage
      style={{
        alignItems: 'center',
        justifyContent: isMobileView ? 'unset' : 'flex-start',
        background: isMobileView ? 'white' : 'rgba(0, 0, 0, 0.2)',
      }}
    >
      <div style={{ width: isMobileView ? '100%' : 'unset', margin: isMobileView ? 'unset' : 'auto' }}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={handleBackClick} style={{ color: 'black', paddingBottom: '8px' }} aria-label="Back" role="region">
                <SvgIcon Icon={BackIcon} width={22} aria-hidden style={{ transform: 'translateY(5px)' }} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div style={containerStyles}>
          <div style={{ overflow: 'hidden', height: '100%' }}>{children}</div>
        </div>
      </div>
    </IonPage>
  );
};

export function StaffLogin() {
  const isMobileView = useWindowSizeMatches(WindowSize.ExtraSmall);
  const language = useLanguage();
  const { form, showSpinner } = useForm();
  const isMobile = useIsMobile();

  function handleFormSubmit(e) {
    e.preventDefault();
    form.handleSubmit();
  }

  const history = useHistory();

  const handleBackClick = () => {
    history.push('/', { backFrom: '/staff' });
  };

  useEffect(() => {
    tracker.trackScreen('SignInWithEmail');
  }, []);

  return (
    <Wrapper handleBackClick={handleBackClick}>
      <div style={{ ...Styles.FlexColumnCenter, position: 'relative', justifyContent: 'space-between', height: '100%' }}>
        <Styles.FormContainer>
          <LoginTitleContainer>
            <LoginTitle>
              <I18N text="staff_login_screen_name" />
            </LoginTitle>
            <LanguageSelectorDialog />
          </LoginTitleContainer>

          <form onSubmit={handleFormSubmit} style={{ ...Styles.FlexColumnCenter, marginTop: 30, maxWidth: '100%' }}>
            <Styles.FormLabel style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <I18N text="staff_login_mail" />
              <br />
              <Styles.FormTextInput
                tabIndex={0}
                type="text"
                name="username"
                aria-label={i18n('accessibility_login_email_input', language)}
                value={form.values.username}
                onIonInput={(event) => {
                  form.setErrors({});
                  form.handleChange(event);
                }}
                className="standalone"
                autofocus={isMobile ? false : true}
              />
            </Styles.FormLabel>

            <Styles.FormLabel style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <I18N text="staff_login_password" />
              <br />
              <Styles.FormTextInput
                type="password"
                name="password"
                aria-label={i18n('accessibility_login_password_input', language)}
                onIonInput={(event) => {
                  form.setErrors({});
                  form.handleChange(event);
                }}
                className="standalone"
              />
            </Styles.FormLabel>

            <CheckBoxes username={form.values.username} />

            <Styles.ButtonContainer>
              <Button type="submit" disabled={showSpinner}>
                <I18N text="continue" />
              </Button>
              {form.errors && (
                <p>
                  {form.errors.username && <I18N text={form.errors.username as TextKeys} />}
                  {form.errors.password && <I18N text={form.errors.password as TextKeys} />}
                </p>
              )}
            </Styles.ButtonContainer>
          </form>
        </Styles.FormContainer>

        {/*//TODO: this is a temporary solution to make the login screen on cut off the language selector */}
        <div style={{ width: '100%' }}>
          <Styles.LoginImage src={NurseIcon} alt="" />
          <div style={{ height: '120px', width: '100%', background: isMobileView ? 'white' : 'rgba(0, 0, 0, 0.2)' }}></div>
        </div>
      </div>
    </Wrapper>
  );
}

function useForm() {
  const { mutate: authenticate, error, isLoading: showSpinner } = useAuthenticateByEmail();

  async function handleRememberMeChanged(username: string) {
    const rememberMeChecked = await UsernameStorage.enabled();
    if (rememberMeChecked) {
      await UsernameStorage.toggle(username);
      await UsernameStorage.toggle(username); // has to be double to store the final value
    }
  }

  useEffect(() => {
    const filterBool = (value: string | null) => (value === 'true' ? '' : value);

    async function setStoredValue() {
      const storedValue = await UsernameStorage.get();

      const username = filterBool(storedValue) || '';
      form.setFieldValue('username', username);
    }

    setStoredValue();
  }, []);

  const form = useFormik({
    validateOnChange: false,

    initialValues: {
      username: '',
      password: '',
    },

    validate: (values) => {
      if (!values.username) {
        return {
          username: 'login_error_username_missing',
        };
      }
      if (!values.password) {
        return {
          password: 'login_error_password_missing',
        };
      }
    },

    onSubmit: async (values) => {
      await handleRememberMeChanged(values.username);
      tracker.trackEvent('Auth', 'LoginPress', 'staff');

      try {
        authenticate(values);
      } catch (error) {
        form.setErrors({
          username: `login_error_${error}`,
        });
      }
    },
  });

  useEffect(() => {
    if (error) {
      form.setErrors({
        username: `login_error_${error.message}`,
      });
    }
  }, [error]);

  return { form, showSpinner };
}

function CheckBoxes({ username }: { username: string }) {
  const [rememberMe, setRememberMe] = useState(false);
  const [showStaffLogin, setShowStaffLogin] = useState(false);

  useEffect(() => {
    (async () => {
      setRememberMe(await UsernameStorage.enabled());
      setShowStaffLogin(await ShowStaffStorage.enabled());
    })();
  }, []);

  const handleRememberMeChanged = async () => {
    await UsernameStorage.toggle(username);
    setRememberMe(!rememberMe);
  };
  const handleShowStaffLoginChanged = async () => {
    await ShowStaffStorage.toggle();
    setShowStaffLogin(!showStaffLogin);
  };

  return (
    <>
      <Styles.CheckboxContainer>
        <input id="rememberMe" type="checkbox" checked={rememberMe} onChange={handleRememberMeChanged} />
        <Styles.FormLabel style={{ marginBottom: 0 }} htmlFor="rememberMe">
          <I18N text="remember_user_log_in" />
        </Styles.FormLabel>
      </Styles.CheckboxContainer>

      <Styles.CheckboxContainer>
        <input id="alwaysShowCheckbox" type="checkbox" checked={showStaffLogin} onChange={handleShowStaffLoginChanged} />
        <Styles.FormLabel style={{ marginBottom: 0 }} htmlFor="alwaysShowCheckbox">
          <I18N text="staff_login_always_show" />
        </Styles.FormLabel>
      </Styles.CheckboxContainer>
    </>
  );
}
