import styled from 'styled-components';

export const RoundImage = styled.div<{ imgWidth?: string }>`
  width: ${(props) => props.imgWidth || '16px'} !important;
  height: ${(props) => props.imgWidth || '16px'} !important;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & > img {
    height: ${(props) => props.imgWidth || '16px'} !important;
    width: calc(${(props) => props.imgWidth || '16px'} * 1.5) !important;
  }
`;
