import { fetchPost } from './utils';
import { backbone } from '@visikon/backbone/src';
function sendLog(logLevel: string, message: any, args: any[]) {
  const state = backbone.store.getState();
  const logBody = {
    level: logLevel,
    message,
    ddtags: `env:${state.env}`,
    service: `client:mytreatment-${state.client?.type}`,
    arguments: args.map((a) => (a && (a instanceof Error || a.componentStack !== undefined) ? JSON.stringify(a, Object.getOwnPropertyNames(a)) : a)),
  };
  fetchPost('log', null, logBody).then(() => {
    console.log(message);
  });
}

export const logger = {
  debug: (message: any, ...args: any[]) => sendLog('debug', message, args),
  info: (message: any, ...args: any[]) => sendLog('info', message, args),
  warn: (message: any, ...args: any[]) => sendLog('warn', message, args),
  error: (message: any, ...args: any[]) => sendLog('error', message, args),
};
