import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { PhampletViewRoute } from '../screens/phamplets/PhampletViewRoute';
import { PHAMPLET_WEB_ROUTE, PhampletWebRoute } from '../screens/phamplets/PhampletWebRoute';
import { NavigationBar } from '../screens/navigation/NavigationBar';
import { SHARE_PROGRAM_ROUTE, ShareProgramContainer } from '../screens/share/shareProgramContainer';
import { HeaderBar } from './HeaderBar';
import * as Styles from './MainContainer.styles';
import GenericScreenContainer from '../screens/GenericScreenContainer';
import { getValidContentKeys, useContent, useContentKeys, useFirstValidContentConfig } from '../common/selectors';
import { useLocation } from 'react-router-dom';
import { ContentConfig, contentConfig } from '../common/ContentConfig';
import { CONTENT_CONTAINER_ROUTE, ContentContainerRoute } from '../screens/content/ContentContainerRoute';
import { PlayerContainer, VIDEO_PLAYER_ROUTE } from '../screens/videoPlayer/PlayerContainer';
import { useHasContent, useIsEmbedded } from '../reducers/resourcesReducer';
import { useSelector } from 'react-redux';
import { WindowSize, useWindowSizeMatches } from '@visikon/utils/src/responsive';
import { LoadingOverlayContainer } from '../screens/login/LoadingOverlayContainer';
import { DEBUG_ROUTE, DebugScreen } from '../screens/debug';
import { IonContent } from '@ionic/react';
import { NoConnectionScreen } from '../common/NoConnectionScreen';
import { HOME_ROUTE, HomeScreen } from '../screens/home/HomeScreen';
import { PrivacyPopupContainer } from '../screens/privacyPopup/PrivacyPopup';
import { Survey } from '../screens/program/Survey';
import { SmartBanner } from '../screens/program/SmartBanner';
import { backbone, LocationHooks, useBackbone } from '@visikon/backbone';
import { useNavLinkHelper } from '../common/NavigationHelper';
import { isHomeScreen } from '../common/utils';
import SelfServicePopup from '../screens/selfServicePopup/SelfServicePopup';
import { AnyUserKinds } from '@visikon/core-models/user';

const { Medium } = WindowSize;

export const ROOT_ROUTE = '/';

export const AppSpecificPaths = ['videoplayer', 'content', 'debug', 'share', 'staff', 'home'];
export const useValidPaths = (): string[] => [...useContentKeys().map((k) => contentConfig[k].path), ...AppSpecificPaths];

export const useIsLegalPath = (path: string) => {
  // console.log('legalPath', 'useIsLegalPath', path);
  return useValidPaths().includes(path);
};

export function MainContent() {
  const location = useLocation();
  const hasContent = useHasContent();
  const isEmbedded = useIsEmbedded();
  const { isMobileOrHasNoMenu } = useNavLinkHelper();

  const level = LocationHooks.useNavLevel();
  // console.log('locationHook', `%c locationHook at Content - level: ${level}`, `color: pink;`);

  const user = useBackbone((state) => state.user);
  const selfServiceUserTypes: AnyUserKinds[] = ['SuperAdmin', 'Admin', 'Editor', 'Translator', 'EmbedIntegration', 'ViewerLicense'];

  const showSelfServicePopup = user && selfServiceUserTypes.includes(user.type);

  if (!hasContent) {
    return <LoadingOverlayContainer />;
  }

  return (
    <Styles.FlexWrapper>
      <Styles.MainContainer navLevel={level}>
        {!isEmbedded && (showSelfServicePopup ? <SelfServicePopup /> : <PrivacyPopupContainer />)}
        <HeaderBar showBack={location.pathname !== HOME_ROUTE} />
        <Survey />
        <NoConnectionScreen />
        <Content isMobileView={isMobileOrHasNoMenu} />
      </Styles.MainContainer>
    </Styles.FlexWrapper>
  );
}

export const useGetRootReDirector = () => {
  const firstContentConfig = useFirstValidContentConfig();
  const programId = backbone.store.getState().program?.id;
  return <Redirect to={`/${programId}/${firstContentConfig.path}`} />;
};

export const getPath = () => {
  const location = useLocation();
  const hasProgramId = (location.pathname.match(/\//g) || []).length > 1;
  // console.log('location', 'for legal path location>', location);
  return location.pathname.split(ROOT_ROUTE)[hasProgramId ? 2 : 1];
};

function Content({ isMobileView }: { isMobileView: boolean }) {
  const showSideMenu = !useWindowSizeMatches(Medium) && location.pathname !== HOME_ROUTE;
  const contentEntries = useSelector(getValidContentKeys).length;
  const firstContentConfig = useFirstValidContentConfig();
  const menuPath = LocationHooks.useMenuPath();
  const programId = backbone.store.getState().program?.id;

  LocationHooks.updateNavLevel(isMobileView);
  LocationHooks.updateNavTitle(isMobileView);

  const path = getPath();
  const level = LocationHooks.useNavLevel();
  const isLegalPath = useIsLegalPath(path);
  // console.log('path', 'for legal path', path);

  // Handling of "wrong" router paths -could be because we just changed program...
  if (firstContentConfig && !isLegalPath) {
    console.log('firstContentConfig', firstContentConfig);
    console.log('NOT LEGAL path "' + path + '" ');
    return <Redirect to={`/${programId}/${firstContentConfig.path}`} />;
  }

  const isSharePath = window.location.pathname.includes(SHARE_PROGRAM_ROUTE);

  const showSideNavigation = showSideMenu && contentEntries > 1 && !isSharePath;
  const showNavigationScreen = path === menuPath && isMobileView;

  return (
    <Styles.ContentAndMenuContainer>
      {showSideNavigation ? <NavigationBar /> : null}
      <Styles.ContentContainer navLevel={level}>
        {isMobileView || isHomeScreen() ? null : <SmartBanner />}
        <Routes showNavigationScreen={showNavigationScreen} />
      </Styles.ContentContainer>
    </Styles.ContentAndMenuContainer>
  );
}

export const programPath = (path) => {
  const slash = path.startsWith('/') ? '' : '/';
  const newPath = `/:programId${slash}${path}`;
  return newPath;
};
function Routes(props: { showNavigationScreen: boolean }) {
  const contentKeys = useContentKeys();
  const isEmbedded = useIsEmbedded();
  const programId = backbone.store.getState().program?.id;
  if (contentKeys.length === 0 || !programId) {
    return <></>;
  }

  const contentRoutes = contentKeys.map((key) => (
    <ContentRoutes key={key} path={programPath(contentConfig[key].path)} fieldKey={key as keyof ContentConfig} />
  ));

  return (
    <IonContent forceOverscroll={false} scrollY={false}>
      {isEmbedded ? null : <Route path={ROOT_ROUTE} component={HomeScreen} exact={true} />}
      {isEmbedded && location.pathname === HOME_ROUTE ? <Redirect to="/" /> : <Route path={HOME_ROUTE} component={HomeScreen} exact={true} />}
      <Route path={programPath(DEBUG_ROUTE)} component={DebugScreen} exact={true} />
      <Route path={programPath(VIDEO_PLAYER_ROUTE)} component={PlayerContainer} />
      {contentRoutes}
      <Route path={programPath(PHAMPLET_WEB_ROUTE)} component={PhampletWebRoute} exact={true} />
      <Route path={programPath(CONTENT_CONTAINER_ROUTE)} component={ContentContainerRoute} exact={true} />
      <Route path={programPath(SHARE_PROGRAM_ROUTE)} component={ShareProgramContainer} exact={true} />
    </IonContent>
  );
}

function ContentRoutes({ path, fieldKey }: { path: string; fieldKey: keyof ContentConfig }) {
  const content = useContent(fieldKey);
  return (
    <>
      <Route path={path} render={() => <GenericScreenContainer fieldKey={fieldKey} path={path} />} exact={true} />
      {content?.typeDescriptor === 'document' && <Route path={programPath(`documents/:textId`)} component={PhampletViewRoute} exact={true} />}
    </>
  );
}
