import * as React from 'react';
import { useState } from 'react';
import { tracker } from '@visikon/tracker/src';
import { I18N } from '../../internationalization/AppText';
import { ShareProgramSideBar } from '../../screens/sideBar/ShareProgramSideBar';
import { FlexView, FlexWrapper, HeaderArea, SmallViewSidePadding } from '../styled/ContentAreaContainers';
import { VideoSection } from './VideoSection';
import { MitforlobContent, VideoPlaylist } from '../../reducers/resourcesReducer';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import styled from 'styled-components';
import { StarButton } from '../styled/StarButton';
import { useSelector } from 'react-redux';
import { IState } from '../../reducers/reducer';
import { ProgramInfo, useContent, useProgramInformationFromContent } from '../../common/selectors';
import { Translation } from '@visikon/core-models/languageTypes';
import { isContentContainer, usePromise } from '../../common/utils';
import { useWindowSizeMatches, WindowSize } from '@visikon/utils/src/responsive';
import { WithFooter } from '../Footer';
import { ContentContainerSection } from '../ContentContainerSection';

interface IProps {
  disableFavorites?: boolean;
  isContained?: boolean;
  fieldKey: string;
  videoList?: Translation<VideoPlaylist>;
}

const SectionHeader = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FavoritesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  button {
    margin-top: 0 !important;
    padding-top: 22px;
  }

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    button {
      margin-top: unset !important;
    }
  }
`;

export const VideoScreenComponent = (props: React.PropsWithChildren<IProps>) => {
  const { disableFavorites, isContained, fieldKey, videoList, children: genericScreenHeader } = props;
  const [showOnlyFavorites, setShowOnlyFavorites] = useState<boolean>(false);
  const contentVideoList = useContent<VideoPlaylist>(fieldKey);
  const myVideoList = videoList || contentVideoList;
  const content = useSelector<IState, MitforlobContent | undefined>((state) => state.resources.content);
  const programInfo = usePromise<ProgramInfo>(useProgramInformationFromContent, content);
  const favorites = useSelector<IState, string[]>((state) => state.userData.favorites);
  const favoritesDisabled = disableFavorites || programInfo?.genericHeader;
  const tabletWindowSize = useWindowSizeMatches(WindowSize.Tablet);

  const toggleFilter = () => {
    const state = showOnlyFavorites ? 'favoriteDisableFilter' : 'favoriteEnableFilter';
    tracker.trackEvent('Favorite', state);
    setShowOnlyFavorites(!showOnlyFavorites);
  };

  if (!myVideoList) {
    return (
      <h1>
        <I18N text="loading_dotdotdot" />
      </h1>
    );
  }

  const shouldDisplay = !isContentContainer();

  return (
    <>
      <FlexWrapper>
        <ContentContainerSection>
          <WithFooter whiteBackground={tabletWindowSize && !isContentContainer} footerStyle={{ paddingLeft: tabletWindowSize ? '16px' : '0' }}>
            <SmallViewSidePadding shouldBeWhite={true}>
              <HeaderArea shouldDisplay={shouldDisplay}>
                {favoritesDisabled && genericScreenHeader}
                {!favoritesDisabled && (
                  <div style={{ position: 'relative' }}>
                    <SectionHeader>
                      {genericScreenHeader}
                      <FavoritesContainer>
                        {!disableFavorites && <ShowSaved onClick={toggleFilter} filterFavorites={showOnlyFavorites} />}
                      </FavoritesContainer>
                    </SectionHeader>
                  </div>
                )}
              </HeaderArea>
              <FlexView style={{ marginTop: 8, flexDirection: 'column', minHeight: 'fit-content' }}>
                {myVideoList?.data.sections.map((s, i) => (
                  <VideoSection
                    key={s.sectionName + i}
                    filterByFavorites={showOnlyFavorites}
                    favorites={!favoritesDisabled ? favorites : undefined}
                    section={s}
                  />
                ))}
              </FlexView>
            </SmallViewSidePadding>
            {!isContained && tabletWindowSize ? <ShareProgramSideBar /> : null}
          </WithFooter>
        </ContentContainerSection>

        {!isContained && !tabletWindowSize ? <ShareProgramSideBar /> : null}
      </FlexWrapper>
    </>
  );
};

const ShowSaved = ({ filterFavorites, onClick }: { filterFavorites: boolean; onClick: () => void }) => {
  return (
    <StarButton color={DeprecatedTokens.secondary} onClick={onClick}>
      {filterFavorites ? <I18N text="all_videos" /> : <I18N text="favorites" />}
    </StarButton>
  );
};
