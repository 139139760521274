import { classes, extend, style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { Tokens } from '../../styles/tokens';

export const dialogHeadding = style({
  color: Tokens.color.primary,
  $debugName: 'DialogHeading',
});

export const dialogDismissButton = style({
  display: 'inline-flex',
  height: '100%',
  padding: 8,

  border: 'none',
  margin: 'none',
  appearance: 'none',
  background: 'transparent',
  $debugName: 'DialogDismissButton',
});

export const dialogContainer = style({
  marginBottom: 8,
  overflow: 'auto',
});

const dialogSectionStyle: NestedCSSProperties = {
  paddingTop: 24,
  paddingRight: 24,
  paddingBottom: 16,
  paddingLeft: 24,
};

export const dialogSection = style(dialogSectionStyle);

export const dialogHeader = style(
  extend(dialogSectionStyle, {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: Tokens.color.white,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: Tokens.color.border,
    $debugName: 'DialogHeader',
  }),
);

export const dialogActionsContainer = classes(
  dialogSection,
  style({
    display: 'flex',
    flexDirection: 'column',
    $nest: {
      '& > button': {
        marginBottom: '12px',
      },

      '& > button:last-child': {
        marginBottom: '0px',
      },
    },
  }),
);

const dialogBackdrop: NestedCSSProperties = {
  background: 'rgba(0, 0, 0, 0.6)',
  // Prevent TypeStyle from combining the selectors into a selector list
  // Safari <= 15.4 will not apply any styles when the ::backdrop pseudo-element
  // selector is used anywhere within in the selector list
  $unique: true,
};

const DIALOG_CORNER_RADIUS = 12;
export const dialogStyle = style({
  padding: 'unset',

  borderWidth: 1,
  borderRadius: DIALOG_CORNER_RADIUS,
  borderStyle: 'solid',
  borderColor: Tokens.color.border,
  backgroundColor: Tokens.color.dialogBackground,

  // May needs to be adjusted, or be an configurable prop
  maxWidth: '36rem',

  boxSizing: 'border-box',

  $nest: {
    [`& > .${dialogHeader}`]: {
      borderTopLeftRadius: DIALOG_CORNER_RADIUS,
      borderTopRightRadius: DIALOG_CORNER_RADIUS,
    },
    // &::backdrop = native implementation
    // & + .backdrop = polyfill implementation
    // Safari <= 15.4 will not apply any styles when the ::backdrop pseudo-element
    // selector is used anywhere within in the selector list, so we separate them
    '&::backdrop': dialogBackdrop,
    '& + .backdrop': dialogBackdrop,
  },
  $debugName: 'Dialog',
});
