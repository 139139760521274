import styled from 'styled-components';
import {DeprecatedTokens} from "@visikon/spine/src/styles/tokens/deprecated-tokens";
import Splash from '../../images/characterLineup.png';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const App = styled.div`
  display: grid;
  grid-template-rows: 142px 1fr 55px;
  grid-template-columns: 150px 1fr 150px;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    grid-template-rows: 180px 1fr 70px;
  }

  @media all and (max-width: 28.75em) {
    grid-template-rows: 142px 1fr 56px;
  }

  @media all and (max-width: 320px) {
    grid-template-rows: 142px 1fr 68px;
  }
`;
export const ITEMOne = styled.div`
  background-color: rgba(31, 80, 153, 0.25);
  width: 100%;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 4;
`;
export const ITEMTwo = styled.div`
  grid-area: 1 / 1 / 4 / 4;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${Splash});
  background-position: center;
  background-origin: content-box;
`;

export const Content = styled.div`
  padding: 20px;

  h1 {
    height: 25px;
    width: 246px;
    color: #414f58;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
  }
  p {
    width: 289px;
    color: #414f58;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
  li::before {
    content: '•';
    color: #9797b0;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 20px;
    padding-left: 0;
  }
  ul {
    list-style: none;
  }

  li {
    height: 40px;
    width: 269px;
    color: #414f58;
    font-size: 14px;
    letter-spacing: 0;
    padding-left: 4px;
  }
  @media all and (max-width: 320px) {
    margin-left: 10px;
  }
`;

export const UnStyledLink = styled.a`
  text-decoration: none;
`;

export const AppStoreButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export const AppStoreButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  border: transparent;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  margin-left: 5px;
  margin-right: 5px;

  span {
    width: 81px;
    color: #1f5099;
    font-size: 14px;
    font-weight: 600;

    /* Trick to make every word break */
    word-spacing: 100vw;
  }

  img {
    height: 26px;
    width: 26px;
  }
`;

export const ContentToGetStart = styled.div`
  box-sizing: border-box;
  height: 570px;
  width: 347px;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 20px;

  h1 {
    width: 246px;
    color: #414f58;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
  }
  li {
    width: 242px;
    color: #414f58;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    padding-left: 10px;
  }
  p {
    width: 242px;
    color: #414f58;
    font-size: 14px;
    padding-left: 10px;
  }

  @media all and (max-width: 320px) {
    padding: 20px;
    border: 0;
  }
`;

export const MainBodyContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px;
  margin-top: 20px;
`;

export const StartCodeSection = styled.div`
  height: 47px;
  width: 150px;
  background-color: rgba(31, 80, 153, 0.25);
  margin-top: 10px;
  margin-left: 10px;

  h1 {
    padding: 0;
    text-align: center;
    font-weight: bold;
    width: 150px;
    height: 18px;
    font-size: 18px;
  }
`;

export const CopiedSection = styled.div`
  align-self: center;
  margin-left: 20px;
`;

export const VideoModal = styled.div`
  box-sizing: border-box;
  height: 79px;
  width: 290px;
  border: 1px solid #f9f0f0;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const VideoViewer = styled.video`
  width: 100%;
  min-height: 20vw;
  min-width: 45vw;
`;

export const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  padding: 0;
  color: #1f5099;
`;
