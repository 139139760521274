import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

export const FadeIn = styled.div<{ opacity: number; seconds?: number }>`
  opacity: ${(props) => props.opacity};
  transition: opacity ${(props) => props.seconds || 0.5}s;
`;

export const useFader = (timeout?: number) => {
  const [opacity, setOpacity] = useState(0);

  const timeoutValue = timeout || 10;
  useEffect(() => {
    const timer = setTimeout(() => setOpacity(1), timeoutValue);
    return () => clearTimeout(timer);
  }, []);
  return opacity;
};

interface IProps {
  children: React.ReactNode;
  timeout?: number;
  seconds?: number;
  disable?: boolean;
}

export const Fader = ({ children, timeout, seconds, disable }: IProps) => {
  const fade = useFader(timeout);
  if (disable) return <>{children}</>;

  return (
    <FadeIn opacity={fade} seconds={seconds}>
      {children}
    </FadeIn>
  );
};
