import { defaultLanguageCode, supportedLanguages } from '@visikon/core-models/i18n/languages';
import { lookup as lookupBCP47 } from 'bcp-47-match';
import { Content } from '@visikon/core-models';

export function getPreferredBrowserLanguage(): Content.LanguageCode {
  const browserLanguages = navigator.languages as string[];
  const supportedLanguagesKeys = supportedLanguages();
  const browserPreferedSupportedLanaguage = lookupBCP47(supportedLanguagesKeys, browserLanguages);

  return (browserPreferedSupportedLanaguage ?? defaultLanguageCode) as Content.LanguageCode;
}
