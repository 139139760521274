import * as React from 'react';
import { StarIcon } from '../../images/star';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { toggleFavorite } from '../../actions/userActions';

interface IOwnProps {
  videoId: string;
  favorites?: string[];
}

type IProps = IOwnProps;

const StarWrapper = styled.button`
  // reset button style
  background: unset;
  color: unset;
  border: none;
  font: unset;
  text-transform: unset;
  letter-spacing: unset;
  cursor: inherit;
  padding: inherit;

  padding: 0px;
  max-width: 32px;
  max-height: 32px;
`;

export const FavoriteButton = (props: IProps) => {
  const dispatch = useDispatch();
  const { favorites, videoId } = props;
  const isFavorite = (favorites || []).find((f) => f === videoId) !== undefined;
  const handleOnClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    const videoId = props.videoId;
    dispatch(toggleFavorite(videoId));
  };

  if (favorites === undefined) {
    return null;
  }

  return (
    <StarWrapper onClick={handleOnClick} className={'favourite-button'} tabIndex={0} aria-label="Favorite">
      <StarIcon active={isFavorite} />
    </StarWrapper>
  );
};
