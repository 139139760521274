import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const CenterDiv = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
`;

// Takes a program id AND a token from auth, and changes the program
// redirects to the first content in that program

export function IFrameTest() {
  const { nr } = useParams<{ nr: string }>();
  const token =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTRjZWQ1NDgyOTBjMjFlYWVkZjRhNTgiLCJ0eXBlIjoiRW5kVXNlciIsInJlZmVyZW5jZWRVc2VyIjpmYWxzZSwiaWF0IjoxNjk5NTQwMzA4LCJleHAiOjE3MzEwNzYzMDh9.YUX1V8fLGMXJ4aWlE14nzEOS4tsfBB3MRWkl9B2FPMo';
  const srcUrl = (program) => `http://localhost:3020/set-user/${token}/${program}/da`;

  const programs = [
    { name: 'DrypDemu', src: srcUrl('630da089056f936f63e3a09b') },
    { name: 'Frederiks test', src: srcUrl('633ec123e76c84421b2bdbfe') },
    { name: 'Kunstigt knæ', src: srcUrl('5f7c62ac41b5480035bfe7f6') },
  ];

  return (
    <CenterDiv>
      <iframe src={programs[nr].src} style={{ height: '90%', width: '90%' }} />
    </CenterDiv>
  );
}
