import { Content } from '@visikon/core-models';
import { TextKeys, translations } from '@visikon/core-models/i18n/translations';
import { backbone } from '@visikon/backbone/src';

const FALLBACK_LANGUAGE: Content.LanguageCode = 'en';

// Get the default translations for the pre defined tokens
const defaultTranslateKeys = (languageCode) => ({
  $APP_NAME: translations.$APP_NAME[languageCode],
});

const deEscape = (str: string) => str.replace(/&quote;/g, '"');

export const i18n = (key: TextKeys | '' | undefined, explicitLanguage?: Content.LanguageCode, replace?: { [token: string]: string }): string => {
  if (!key || key.trim() === '') {
    return '';
  }

  const language = explicitLanguage || backbone.store.getState().country.languageCode;

  // replace all tokens found in the replace-object (merged with the defaultTranslationsKeys-object) into the target string
  const replaceTokens = (content: string) => {
    const replaceAndDefault = { ...replace, ...defaultTranslateKeys(language) };
    let result = content;
    Object.keys(replaceAndDefault).forEach((token) => {
      const regex = new RegExp('\\' + token, 'g');
      result = result.replace(regex, replaceAndDefault[token]);
    });

    return deEscape(result);
  };

  if (hasTranslation(key)) {
    if (hasTranslation(key, language)) {
      return replaceTokens(translations[key][language]);
    }
    if (hasTranslation(key, FALLBACK_LANGUAGE)) {
      return replaceTokens(translations[key][FALLBACK_LANGUAGE] as string);
    }
  }
  return `mis: ${key}`;
};

/**
 * Check if a translation key exists
 *
 * @param key key to check the existence of
 * @param language optional - check if a translation for the key exists in the given language
 * @returns true if the translation key exists and if specified, in the given language, false otherwise
 */
export function hasTranslation(key: string, language?: Content.LanguageCode) {
  if (!(key in translations)) return false; // key does not exist
  if (language === undefined) return true; // key exists, no language specified

  return language in translations[key]; // check if the language exists for translation
}
