import { classes, style } from 'typestyle';
import { Tokens } from '../../styles/tokens';
import { cva } from 'class-variance-authority';
import { Styles } from '../../styles';

const baseStyle = classes(
  Styles.weight.bold,
  style({
    textTransform: 'uppercase',
    borderRadius: 120000,
    border: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    padding: '12px 30px',
  }),
);

export const buttonStyles = cva(baseStyle, {
  variants: {
    size: {
      large: Styles.size.large,
      medium: Styles.size.medium,
      small: Styles.size.small,
      tiny: Styles.size.tiny,
    },
    variant: {
      default: style({
        color: Tokens.color.white,
        background: Tokens.color.primary,
        $nest: {
          '&:hover': {
            background: Tokens.color.primaryHover,
            cursor: 'pointer',
          },
          '&:disabled': {
            background: Tokens.color.disabled,
            color: Tokens.color.border,
            cursor: 'default',
          },
        },
      }),
      danger: style({
        color: Tokens.color.white,
        background: Tokens.color.danger,
        $nest: {
          '&:hover': {
            background: Tokens.color.dangerHover,
            cursor: 'pointer',
          },
          '&:disabled': {
            background: Tokens.color.disabled,
            color: Tokens.color.border,
            cursor: 'default',
          },
        },
      }),
      outline: style({
        color: Tokens.color.primary,
        background: Tokens.color.white,
        borderColor: Tokens.color.primary,
        $nest: {
          '&:hover': {
            background: Tokens.color.outlineHover,
            cursor: 'pointer',
          },
          '&:disabled': {
            background: Tokens.color.disabled,
            color: Tokens.color.border,
            border: `1px solid ${Tokens.color.disabled}`,
            cursor: 'default',
          },
        },
      }),
      ghost: style({
        color: Tokens.color.primary,
        backgroundColor: 'transparent',
        $nest: {
          '&:hover, &:focus': {
            backgroundColor: Tokens.color.white,
            borderColor: Tokens.color.border,
            color: Tokens.color.primaryHover,
            cursor: 'pointer',
          },
        },
      }),
    },
  },
});
