import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { ClientInfo, ProgramInfo, UserInfo } from './models';
import { EnvironmentName, getEnvName } from './env';
import { backends, blobs, selfService } from './host';
import { getPreferredBrowserLanguage } from './languages';
import { LocalStorage } from '@visikon/utils/src/storage/WebStorage';
import { IStorage } from '@visikon/utils/src/storage/IStorage';
import { Country, getCountryByLanguageCode } from '@visikon/core-models/i18n/languages';

const env = getEnvName();

export type BackboneState = {
  env: EnvironmentName;
  hosts: {
    core: string;
    blobs: string;
    selfService: string;
    selfServiceApi: string;
  };
  apiKey: string;
  MOCKmediaVariation: string;

  client?: ClientInfo;
  authToken?: string;

  deviceId?: string;
  user?: UserInfo;
  country: Country;
  program?: ProgramInfo;

  storageApi: IStorage;
  isOffLine: boolean;
  featureToggles?: string[];

  location: {
    paths: string[];
    level: number;
  };
  embedInfo?: {
    live_path?: string;
    preview_path?: string;
    programId: string;
  };
};

export default function () {
  const preferredBrowserLanguage = getPreferredBrowserLanguage();
  const defaultCountry = getCountryByLanguageCode(preferredBrowserLanguage);
  console.log(`Got initial language '${preferredBrowserLanguage}' from browser: `, navigator.languages, ' -> ', defaultCountry);

  return create(
    devtools(
      subscribeWithSelector<BackboneState>(() => {
        return {
          env,
          hosts: {
            core: backends[env],
            blobs: blobs[env],
            selfService: selfService[env],
            selfServiceApi: `${selfService[env]}api/`,
          },
          MOCKmediaVariation: '5b91713d9bfc750039a336a5',
          apiKey: 'mitForlob.AdA195dqZM3ZLCX08nvyo8MjCGnAnz4ikggQp9HMircJT4ubYW3yVHlPktEeyB2Z',
          country: defaultCountry,
          storageApi: LocalStorage,
          isOffLine: false,
          location: {
            paths: [],
            level: -1,
          },
        };
      }),
      { name: 'Backbone' },
    ),
  );
}
