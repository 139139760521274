import { DatePicker } from '@capacitor-community/date-picker';
import { backbone, useBackbone } from '@visikon/backbone/src';
import { DateTime } from 'luxon';

const DATEPICKER_SUPPORTED_CLIENTS = ['ios', 'android'];

export function StripTimeFromDateTime(dateTime: DateTime): DateTime {
  return dateTime.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
}

export const dateFormat = 'yyyy/MM/dd';
async function NativeDatePicker(date: number): Promise<DateTime | null> {
  const { value } = await DatePicker.present({
    date: DateTime.fromMillis(date).toFormat(dateFormat),
    mode: 'date',
    locale: backbone.store.getState().country.languageCode ?? 'da',
    format: dateFormat,
    ios: { format: dateFormat },
    android: { format: dateFormat },
  });

  const dateTime = DateTime.fromFormat(value, dateFormat);

  return dateTime.isValid ? dateTime : null;
}

function DebugWebDatePicker(clientType?: string): DateTime | null {
  const supportedJoined = DATEPICKER_SUPPORTED_CLIENTS.join(', ');
  const result = window.confirm(
    `Client type "${clientType ?? 'unknown'}" doesn't support DatePicker!\nSupported: ${supportedJoined}\n\nUse current date as picked date?`,
  );

  return result ? StripTimeFromDateTime(DateTime.now()) : null;
}

export async function PresentDatePicker(date: number): Promise<DateTime | null> {
  const { client } = useBackbone.getState();

  if (client?.type && DATEPICKER_SUPPORTED_CLIENTS.includes(client.type)) {
    return NativeDatePicker(date);
  } else {
    return DebugWebDatePicker(client?.type);
  }
}
