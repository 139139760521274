import { useEffect } from 'react';

export type OrientationChangeListener = (orientationType: OrientationType) => void;

/**
 * Translate degrees from {@link window.orientation} into the newer {@link Screen Screen API}'s {@link OrientationType}
 * @param degrees
 * @returns {OrientationType}
 */
export function DegreesToOrientationType(degrees: number): OrientationType {
  switch (degrees) {
    case 0:
    default:
      return 'portrait-primary';
    case 90:
      return 'landscape-primary';
    case 180:
      return 'portrait-secondary';
    case -90:
      return 'landscape-secondary';
  }
}

/**
 * Wrapper for listing for the Screen Orientation API change event.
 * With fallback to the deprecated {@link window.onorientationchange} on older browsers
 *
 * @param callback function to be called when orientation changes
 * @returns
 */
export function onScreenOrientationChange(callback: OrientationChangeListener): (() => void) | undefined {
  // Screen Orientation API
  if (screen?.orientation?.onchange !== undefined) {
    const listener = () => callback(screen.orientation.type);
    screen.orientation.addEventListener('change', listener);

    return () => screen.orientation.removeEventListener('change', listener);
  }

  // Fallback to Window's orientation change event
  // Required for supoorting devices running iOS <=16.3
  if (window.onorientationchange !== undefined) {
    const listener = () => {
      const orientation = DegreesToOrientationType(window.orientation);
      callback(orientation);
    };

    window.addEventListener('orientationchange', listener);

    return () => window.removeEventListener('orientationchange', listener);
  }
}

/**
 * Wrapper around {@link onScreenOrientationChange} for automatically adding/removing listener on component mount/unmmount
 * @param listener
 */
export function useScreenOrientationEvent(listener: OrientationChangeListener) {
  useEffect(() => {
    return onScreenOrientationChange(listener);
  }, []);
}
