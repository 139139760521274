import * as React from 'react';
import { useEffect, useRef } from 'react';
import { CommandCard } from './CommandCard';
import { IonActionSheet } from '@ionic/react';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { MOBILE_SIZE, useWindowSizeMatches } from '@visikon/utils/src/responsive';
import trash from '../../images/svg/trash.svg';
import people from '../../images/svg/people.svg';
import copy from '../../images/svg/copy.svg';
import checkmark from '../../images/svg/checkmark.svg';
import * as Styles from './ActionDialog.styles';

export type ProgramActionLevel = 'info' | 'warning' | 'danger';
export type ProgramAction = {
  icon: 'trash' | 'share' | 'copy' | 'ok';
  title: string;
  action: () => boolean;
  level: ProgramActionLevel;
};

type Props = {
  actions?: ProgramAction[];
  parentRef: React.RefObject<HTMLDivElement>;
  onHide: () => void;
  show: boolean;
  dialogIndex?: number;
  manualDismiss?: boolean;
};

export const ActionDialog = (props: Props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const actionSheetRef = useRef<HTMLIonActionSheetElement>(null);
  const isMobileView = useWindowSizeMatches(MOBILE_SIZE);
  const { parentRef, actions, onHide, show, dialogIndex, manualDismiss } = props;
  const clickOutSideHandler = (event: any) => {
    var rect = parentRef.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }
    var isInDialog =
      rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width;
    if (!isInDialog) {
      onHide();
    }
  };

  useEffect(() => {
    window.addEventListener('click', clickOutSideHandler);
    return () => {
      window.removeEventListener('click', clickOutSideHandler);
    };
  }, [parentRef]);

  useEffect(() => {
    if (parentRef.current && dialogRef.current) {
      const offset = 32;
      const clientWidth = dialogRef.current.offsetWidth;
      dialogRef.current.style.top = `${parentRef.current.offsetTop + offset}px`;
      dialogRef.current.style.left = `${parentRef.current.offsetLeft - clientWidth + offset}px`;
    }
  }, [parentRef, dialogRef]);

  useEffect(() => {
    const actionSheet = actionSheetRef?.current;
    const backDrop = actionSheet?.children[0] as HTMLElement | undefined;

    if (manualDismiss) {
      // Cannot dismiss the action sheet by calling the dismiss method as it exists again once the backdrop is clicked (see actionSheetWillPresent)
      backDrop?.click();
    }
  }, [manualDismiss]);

  const actionSheetDidDismiss = (event: CustomEvent<OverlayEventDetail<any>>) => {
    onHide();
  };
  const actionIcons = { trash, share: people, copy: copy, ok: checkmark };
  const dialogActions = actions?.map((action, index) => <CommandCard key={index} action={action} />);

  const actionSheetButtons = actions?.map((action, index) => ({
    text: action.title,
    role: action.level === 'danger' ? 'destructive' : 'default',
    icon: actionIcons[action.icon],
    cssClass: action.level === 'danger' ? Styles.DangerButtonClassStyle : Styles.DefaultButtonClassStyle,
    handler: action.action,
  }));

  const actionSheetWillPresent = (event: Event) => {
    let actionSheet = event.target as HTMLIonActionSheetElement;
    const buttons = Array.from(actionSheet.getElementsByTagName('button'));
    const dangerButton = buttons.find((btn) => btn.classList.contains(Styles.DangerButtonClassStyle)) as HTMLButtonElement;

    if (!dangerButton) {
      return;
    }

    // Store the original dismiss function
    const originalDismiss = actionSheet.dismiss;

    dangerButton.onclick = () => {
      // Prevent the action sheet from being dismissed
      actionSheet.dismiss = () => Promise.resolve(false);
    };

    const dismissActionSheet = () => {
      actionSheet.dismiss = originalDismiss;
      actionSheet.dismiss();
    };

    // @ts-ignore Restore the original dismiss function when the backdrop is tapped
    actionSheet.onBackdropTap = () => {
      dismissActionSheet();
    };
  };

  const mobileSheet = (
    <IonActionSheet
      trigger={`open-action-sheet-${dialogIndex}`}
      mode="md"
      ref={actionSheetRef}
      onDidDismiss={actionSheetDidDismiss}
      onWillPresent={actionSheetWillPresent}
      className={Styles.ActionSheetClassStyle}
      buttons={actionSheetButtons}
    ></IonActionSheet>
  );

  const desktopDialog = show ? (
    <Styles.DialogWrapper ref={dialogRef} open={true} tabIndex={0}>
      {dialogActions}
    </Styles.DialogWrapper>
  ) : null;
  return <>{isMobileView ? mobileSheet : desktopDialog}</>;
};
