import { Country } from '@visikon/core-models/i18n/languages';
import { useCountry } from '../../common/selectors';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { fetchGet } from '@visikon/utils/src/utils';
import { LanguageCode } from '@visikon/core-models/content';
import { changeCountry } from '../../actions/resourcesActions';
import { listProgramLanguages } from '../../sagas/shareProgramSaga';
import { LanguageSelectorModal } from '../../internationalization/LanguageSelectorModal';
import * as React from 'react';

export function useLanguageCodeHandler() {
  const dispatch = useDispatch();
  const country = useCountry();
  const [codeVerified, setCodeVerified] = useState<boolean | undefined>(undefined);
  const [languageCodesToChoose, setLanguageCodesToChoose] = useState<LanguageCode[]>([]);

  const verifyCode = (code: string) => {
    setCodeVerified(false);
    fetchGet(listProgramLanguages(code))
      .then((languages) => {
        const languageCodes = languages.map((t) => t.language);

        if (languageCodes && languageCodes.length > 0 && !languageCodes.includes(country.languageCode)) {
          setLanguageCodesToChoose(languageCodes);
          return;
        }
        setCodeVerified(true);
      })
      .catch(() => {
        console.log("Couldn't fetch program languages");
        // Just call backend to authenticate -and get proper error message
        setCodeVerified(true);
      });
  };

  const handleSelectedCountry = (country: Country | null) => {
    setLanguageCodesToChoose([]);
    if (!country) {
      setCodeVerified(undefined);
      return;
    }
    dispatch(changeCountry(country!));
    setCodeVerified(true);
  };

  const languageSelectorModal = (languageCodesToChoose.length > 0 ? <LanguageSelectorModal languageCodes={languageCodesToChoose} onSelectCountry={handleSelectedCountry} /> : null);

  return { codeVerified, verifyCode, languageCodesToChoose, languageSelectorModal };
}
