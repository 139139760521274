import { I18N } from '../../internationalization/AppText';
import { VideoInfoList, VideoPlaylist } from '../../reducers/resourcesReducer';
import { VideoCard } from './VideoCard';
import * as Styles from './VideoSection.styles';
import * as CardStyles from './VideoCard.styles';
import { useLanguage } from '../../common/selectors';

type SectionType = VideoPlaylist['translations'][0]['data']['sections'][0];
interface VideoSectionProps {
  section: SectionType;
  filterByFavorites?: boolean;
  favorites?: string[];
}

export function VideoSection({ section, favorites, filterByFavorites }: VideoSectionProps) {
  const language = useLanguage();
  const filterVideoList = (videoList: SectionType['videoList']) => {
    if (!filterByFavorites) return videoList;
    return videoList.filter((v) => (favorites || []).indexOf(v.video._id) !== -1);
  };

  const renderVideoCard = (v: VideoInfoList[number]) => {
    if (!v.video) return null;

    return <VideoCard videoInfo={v} language={language} favorites={favorites} isList={isList} isAutoPlay={v.autoPlay} />;
  };

  const renderStep = (v: VideoInfoList[number], idx: number) => (
    <Styles.StepContentWrapper isFirstStep={idx === 0}>
      <Styles.TextWrapper>
        <Styles.TextHeader>{v.name}</Styles.TextHeader>
        <Styles.TextDescription>{v.description}</Styles.TextDescription>
      </Styles.TextWrapper>
      <Styles.MediaWrapper>{renderVideoCard(v)}</Styles.MediaWrapper>
    </Styles.StepContentWrapper>
  );
  const videoList = filterVideoList(section.videoList || []);
  const isList = section?.sectionType === 'list';
  const isSectionEmpty = videoList.length === 0;

  return (
    <Styles.SectionWrapper>
      {section.sectionName && !isList ? <Styles.SectionTitle>{section.sectionName}</Styles.SectionTitle> : null}
      <Styles.FlexWrapper isList={isList}>
        {videoList.map((v, idx) =>
          isList ? (
            <Styles.StepWrapper key={idx}>{renderStep(v, idx)}</Styles.StepWrapper>
          ) : (
            <CardStyles.CardWrapper key={v.name + idx} isList={isList}>
              {renderVideoCard(v)}
            </CardStyles.CardWrapper>
          ),
        )}
        {isSectionEmpty && !isList ? (
          <p>
            <I18N text="no_videos_in_category" />
          </p>
        ) : null}
      </Styles.FlexWrapper>
    </Styles.SectionWrapper>
  );
}
