import * as React from "react";
import styled from "styled-components";
import "../checkBox/CheckBox.css";
// tslint:disable-next-line: no-empty-interface
const Checkbox = styled.div<{ top: number }>`
    flex-direction: column;
    margin-top: ${(props) => (props.top ? `${props.top}px` : `${50}px`)};
    margin-bottom: 0;
    margin-left: 30;
`;
interface IOwnProps {
    disabled?: boolean;
    label?: string;
    checked?: boolean;
    top?: any;
    onPress(): void;
}

interface IState {
    checked: boolean;
}

type Props = IOwnProps;

export default class CheckBox extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            checked: false,
        };
        this.confirmToggle = this.confirmToggle.bind(this);
    }

    public confirmToggle() {
        this.setState({ checked: !this.state.checked });
    }
    public render() {
        const { onPress, disabled, label, checked, top } = this.props;
        return (
            // tslint:disable-next-line: no-empty
            <Checkbox
                top={top}
                // tslint:disable-next-line: no-empty
                onClick={disabled ? () => {} : onPress}
            >
                <label htmlFor="c1" className="checkbox">
                    <input type="checkbox" checked={checked} />
                    {label}
                </label>
            </Checkbox>
        );
    }
}
