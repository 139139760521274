import { RoundImage } from '../components/styled/RoundImage';
import ReactCountryFlag from 'react-country-flag';
import * as React from 'react';
import styled from 'styled-components';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import { i18N } from './AppText';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import { Country } from '@visikon/core-models/i18n/languages';
import { IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';

export const CountryContainer = styled.div<{ hover: boolean }>`
  // remove button styling
  background: none;
  border: none;
  width: 100%;

  font-size: 16px;
  font-weight: bold;
  color: ${Tokens.color.text};
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;
  padding: 12px;
  padding-right: 4px;
  cursor: pointer;
  &:hover {
    ${(props) => props.hover && `background-color: #F7F7F7;`}
    border-radius: 6px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

const CountryTitle = styled.div`
  align-self: flex-start;
  padding-left: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
const CountrySubTitle = styled.div`
  align-self: flex-start;
  padding-left: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
`;

export type Props = {
  country: Country;
  imgWidth?: string;
  showLegend?: boolean;
  showArrow?: boolean;
};

export function CountryBadge(props: Props) {
  const { country, imgWidth } = props;

  const languageKey = country.language;
  const countryLanguageCode = country.languageCode;
  const languageTranslated = i18N(languageKey as TextKeys);
  const originalLanguage = `${i18N(languageKey as TextKeys, countryLanguageCode)} (${country.code.toUpperCase()})`;

  return (
    <CountryContainer hover={props.showLegend !== undefined}>
      <RoundImage imgWidth={imgWidth || '64px'}>
        <ReactCountryFlag alt={props.showLegend ? '' : languageTranslated} countryCode={country.code} svg />
      </RoundImage>
      {props.showLegend && (
        <Title>
          <CountryTitle>{originalLanguage}</CountryTitle>
          <CountrySubTitle>{languageTranslated}</CountrySubTitle>
        </Title>
      )}
      {props.showArrow && <IonIcon icon={chevronForwardOutline} style={{ marginLeft: 'auto', fontSize: '26px' }} />}
    </CountryContainer>
  );
}
