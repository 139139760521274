import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SvgIcon } from '../../images/svgIcon';
import { ReactComponent as help } from '../../images/svg/help.svg';
import { ReactComponent as heart } from '../../images/svg/heart.svg';
import { ReactComponent as pulse } from '../../images/svg/pulse.svg';
import { ReactComponent as documents } from '../../images/svg/document.svg';
import { ReactComponent as body } from '../../images/svg/body.svg';
import { ReactComponent as medkit } from '../../images/svg/medkit.svg';
import { ReactComponent as play } from '../../images/svg/play.svg';
import { RoundImage } from '../../components/styled/RoundImage';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contentConfig } from '../../common/ContentConfig';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import { useContent } from '../../common/selectors';
import { i18n } from '../../internationalization/i18n';
import { WithContentTypeNames } from '../../reducers/resourcesReducer';
import { CustomType } from '@visikon/core-models/content';
import { useBackbone } from '@visikon/backbone';

interface IProps {
  contentKey: string;
}

const IconTypes = {
  help: help,
  heart: heart,
  pulse: pulse,
  documents: documents,
  body: body,
  medkit: medkit,
  play: play,
};

const Container = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ContentCard = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: #fff;
  }
`;
const LeftCell = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  padding-left: 4px;
`;
const RightCell = styled.div`
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;
const Title = styled.div`
  color: #1a1a1a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding-left: 12px;
`;

const ChevronIconContainer = styled.div`
  display: flex;
  width: 38px;

  svg {
    padding: 6px;
  }
`;

const FeatureCardSvg = styled(SvgIcon)`
  color: ${Tokens.color.primary};

  & path {
    fill: currentColor;
  }
`;

function UseMenuLabel(contentKey: string) {
  const content = useContent<CustomType<WithContentTypeNames, never>>(contentKey);

  if (content?.data?.menuLabel) {
    return content?.data?.menuLabel;
  }

  const contentKeyProps = contentConfig[contentKey];
  return i18n(contentKeyProps.translationKeys.menu);
}

export const FeatureCard = ({ contentKey }: IProps) => {
  const programId = useBackbone((state) => state.program?.id);
  const menuLabel = UseMenuLabel(contentKey);
  const contentKeyProps = contentConfig[contentKey];
  const targetRoute = contentKeyProps.path;
  const icon = IconTypes[contentKeyProps.icon];

  return (
    <Container>
      <ContentCard to={`/${programId}/${targetRoute}`}>
        <LeftCell>
          <RoundImage imgWidth={'44px'} style={{ background: '#E3ECF4' }} aria-hidden>
            <FeatureCardSvg Icon={icon} width={24} />
          </RoundImage>
        </LeftCell>
        <RightCell>
          <Title>{menuLabel}</Title>
        </RightCell>
        <ChevronIconContainer>
          <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: 'auto' }} width={12} color={Tokens.color.primary} />
        </ChevronIconContainer>
      </ContentCard>
    </Container>
  );
};
