import { initReactApp } from './App';
import { backbone } from '@visikon/backbone/src';
import '@visikon/utils/src/NetworkMonitor';

const { version } = require('../package.json');

backbone.update({
  client: {
    type: 'web',
    version,
  },
});

function main() {
  initReactApp();
}

// TODO: Enforce https in frontdoor
main();
