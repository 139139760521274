import { ProgramInfo, useContent, useIsFirstMenuItem, useLanguage, useProgramInformationFromContent } from '../common/selectors';
import { SetDocumentTitle } from '../internationalization/AppText';
import { ScreenHeader } from '../components/styled/Texts';
import * as React from 'react';
import { i18n } from '../internationalization/i18n';
import { contentConfig, ContentConfig, validContentKeys } from '../common/ContentConfig';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import { ProgramHeader } from '@visikon/nativeui/src/program/ProgramHeader';
import { getPath } from '../components/MainContent';
import { MitforlobContent } from '../reducers/resourcesReducer';
import { IState } from '../reducers/reducer';
import { useSelector } from 'react-redux';
import { usePromise } from '../common/utils';
import { ProgramMenu } from './program/ProgramMenu';
import { MOBILE_SIZE, useWindowSizeMatches } from '@visikon/utils/src/responsive';
import { LocationHooks } from '@visikon/backbone';

export function GenericScreenHeader() {
  const content = useSelector<IState, MitforlobContent | undefined>((state) => state.resources.content);
  const programInfo = usePromise<ProgramInfo>(useProgramInformationFromContent, content);
  const isFirstMenuItem = useIsFirstMenuItem();

  const tab = getPath();
  let fieldKey;
  validContentKeys.forEach((k) => {
    if (contentConfig[k].path === tab) {
      fieldKey = k as keyof ContentConfig;
    }
  });
  const { title, translationKey } = usePageTitle(fieldKey);
  const name = programInfo?.name || '';
  const showGenericHeader = programInfo?.genericHeader || isFirstMenuItem;
  const contentTitle = LocationHooks.useContentTitle();
  const showProgramMenu = useShowProgramMenu();

  React.useEffect(() => {
    if (!programInfo) return;
  }, [programInfo]);

  const Header = () => {
    if (isFirstMenuItem) {
      return <ProgramMenu />;
    }

    if (showGenericHeader) {
      return <ProgramHeader name={name} subName={programInfo?.subName} department={programInfo?.department} />;
    }

    return <ScreenHeader>{title || contentTitle}</ScreenHeader>;
  };

  if (showProgramMenu) {
    return (
      <>
        <SetDocumentTitle text={translationKey as TextKeys} label={title} />
        <Header />
      </>
    );
  }

  return (
    <>
      <SetDocumentTitle text={translationKey as TextKeys} label={title} />
      <Header />
    </>
  );
}

export function usePageTitle(fieldKey: keyof ContentConfig) {
  const content = useContent(fieldKey);
  const language = useLanguage();
  const translationKey = contentConfig[fieldKey]?.translationKeys.page;
  const title = content?.data?.pageTitle || i18n(translationKey as TextKeys, language);

  return { title, translationKey };
}

export const useShowProgramMenu = () => {
  const isFirstMenuItem = useIsFirstMenuItem();
  const isMobileView = useWindowSizeMatches(MOBILE_SIZE);
  return isMobileView && isFirstMenuItem;
};
