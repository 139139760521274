import { classes, style } from 'typestyle';
import { Tokens } from './tokens';
import * as DefaultStyles from './default.styles';

export namespace Styles {
  export const color = {
    primary: classes(
      DefaultStyles.Styles.default.text,
      style({
        color: Tokens.color.primary,
      }),
    ),

    danger: classes(
      DefaultStyles.Styles.default.text,
      style({
        color: Tokens.color.danger,
      }),
    ),

    success: classes(
      DefaultStyles.Styles.default.text,
      style({
        color: Tokens.color.success,
      }),
    ),

    border: classes(
      DefaultStyles.Styles.default.text,
      style({
        color: Tokens.color.border,
      }),
    ),

    disabled: classes(
      DefaultStyles.Styles.default.text,
      style({
        color: Tokens.color.disabled,
      }),
    ),

    text: classes(
      DefaultStyles.Styles.default.text,
      style({
        color: Tokens.color.text,
      }),
    ),

    white: classes(
      DefaultStyles.Styles.default.text,
      style({
        color: Tokens.color.white,
      }),
    ),
  };
}
