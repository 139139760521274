import { tracker } from '@visikon/tracker/src';
import { I18N } from '../../internationalization/AppText';
import { FlexWrapper, SmallViewSidePadding } from '../../components/styled/ContentAreaContainers';
import { FAQSection, FAQType } from '../../reducers/resourcesReducer';
import { FaqCardComponent } from './FaqCardComponent';
import * as React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useContent } from '../../common/selectors';
import { WithFooter } from '../../components/Footer';
import { MOBILE_SIZE, useWindowSizeMatches } from '@visikon/utils/src/responsive';
import { DetailsCardContainer } from '../../components/styled/DetailsCard';
import { ScreenHeader } from '../../components/styled/Texts';
import { ContentConfig } from '../../common/ContentConfig';
import { useNavLinkHelper } from '../../common/NavigationHelper';
import * as FaqStyles from './FaqCardComponent.styles';

export const renderDetails = (info: FAQSection['questions'][0]) => {
  return (
    <FaqStyles.FaqDetails key={info.question}>
      <div style={{ alignItems: 'center', lineHeight: '1.5' }}>
        <span style={{ maxWidth: '100%' }}>{info.question}</span>
      </div>
      <div>{renderTextWithNewline(info.answer)}</div>
    </FaqStyles.FaqDetails>
  );
};
import { ContentContainerSection } from '../../components/ContentContainerSection';
import { LocationHooks } from '@visikon/backbone';

const renderTextWithNewline = (text: string) => {
  return text?.split('\n').map((t) => {
    if (t && t.length > 0) {
      return (
        <p key={t} style={{ lineHeight: '1.5' }}>
          {t}
        </p>
      );
    }
    return null;
  });
};

export const FaqComponent: React.FC<PropsWithChildren & { fieldKey: keyof ContentConfig }> = ({ fieldKey, children: header }) => {
  const content = useContent<FAQType>(fieldKey);
  const [detailView, setDetailView] = useState<FAQSection | undefined>(undefined);

  const getClickHandler = (faq: FAQSection) => {
    return () => {
      if (detailView !== faq) {
        tracker.trackScreen('FAQDetails');
        tracker.trackEvent('FAQ', 'faqShow', faq.name);
        setDetailView(faq);
      } else {
        tracker.trackScreen('FAQList');
        setDetailView(undefined);
      }
    };
  };
  const faqSections = content?.data.sections.map((section, i) => (
    <FaqSection key={i} isOpen={section === detailView} section={section} onClick={getClickHandler(section)} />
  ));
  const sectionDetailView = detailView ? <DetailsCardContainer>{detailView.questions.map(renderDetails)}</DetailsCardContainer> : undefined;

  const isMobileView = useWindowSizeMatches(MOBILE_SIZE);

  const headerText = isMobileView && detailView ? <ScreenHeader>{detailView.name}</ScreenHeader> : header;

  const { isMobileOrHasNoMenu, contentNavLink, translatedPage, updateNavLink } = useNavLinkHelper();

  const paths = LocationHooks.usePaths();
  const pathComingFrom = paths[paths.length - 1];
  const level = LocationHooks.useNavLevel();

  useEffect(() => {
    if (detailView && isMobileOrHasNoMenu) {
      updateNavLink({
        title: translatedPage,
        path: pathComingFrom,
        action: () => {
          setDetailView(undefined);
          updateNavLink(contentNavLink);
        },
      });
    }
  }, [isMobileView, detailView, level]);

  const contentView = isMobileView && sectionDetailView ? sectionDetailView : faqSections;
  return (
    <FlexWrapper>
      <ContentContainerSection style={{ display: 'block' }}>
        <SmallViewSidePadding>
          <WithFooter footerStyle={{ paddingTop: '16px' }}>
            {headerText}

            {content === undefined && (
              <div>
                <I18N text="loading_dotdotdot" />
                ...
              </div>
            )}

            {content && contentView}
          </WithFooter>
        </SmallViewSidePadding>
      </ContentContainerSection>
    </FlexWrapper>
  );
};

function FaqSection({ section, isOpen, onClick }: { section: FAQSection; isOpen: boolean; onClick: () => void }) {
  return <FaqCardComponent key={section.name} faq={section} isOpen={isOpen} onClick={onClick} />;
}
