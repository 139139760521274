import React from 'react';
import Iconfinder_Apple_Store_2697649 from '../../images/iconfinder_Apple_Store_2697649.png';
import Iconfinder_google_play_132832 from '../../images/iconfinder_google_play_132832.png';
import VideoThumb from '../../images/videothumb.png';
import { PopupScreenCenter } from '../../components/popup/PopupScreenCenter';
import { I18N } from '../../internationalization/AppText';
import * as s from './StartCodeScreen.styles';
import Copy_icon from '../../images/copy_icon.png';
import { APPLE_APP_STORE_LINK, GOOGLE_PLAY_STORE_LINK } from '@visikon/core-models/misc';
import { connect, MapStateToProps } from 'react-redux';
import { IState } from '../../reducers/reducer';

interface IProps {
  programName: string;
  subName: string;
  userCode: any;
}

interface IPropsFromState {
  error?: string;
}

type Props = IPropsFromState & IProps;

interface ILocalState {
  showPopup: boolean;
  showVideo: boolean;
}

// TODO: Localize this. Update the texts, update the links (like mitforlob.dk)

class StartCodeScreen extends React.Component<Props, ILocalState> {
  inputRef: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      showVideo: false,
      showPopup: false,
    };
    this.inputRef = React.createRef();
  }

  handleCopyClick() {
    const text: any = document.getElementById('text');

    const range = document.createRange();
    range.selectNode(text);
    window.getSelection()!.addRange(range);
    document.execCommand('copy');
  }

  copyText(text: any) {
    return async () => {
      try {
        await (navigator as any).clipboard.writeText(text);
        this.setState({ showPopup: true });
        setTimeout(() => {
          this.setState({ showPopup: false });
        }, 1200);
      } catch (e: any) {
        const range = document.createRange();
        range.selectNode(text);
        const result = document.execCommand('copy');
        if (result) {
          console.log('Copied');
        } else {
          console.log("Couldn't copy text.");
        }
      }
    };
  }

  toggleVideo = () => {
    const { showVideo } = this.state;
    this.setState({ showVideo: !showVideo });
  };

  renderContent() {
    const hasError = this.props.error;
    console.log('this.props', this.props, hasError);
    return (
      <s.Content>
        <h1>
          <I18N text="my_treatment_name" />
        </h1>
        <p style={{ fontSize: 20 }}>{this.props.programName}</p>
        {this.props.subName === undefined ? <div /> : <p style={{ fontSize: 20, color: '#414F58' }}>{`-${this.props.subName}`}</p>}
        <p>Få overblik over hele dit behandlingsforløb hurtigt og let.</p>

        <ul style={{ padding: 16 }}>
          <li>Se videoer om dit behandlingsforløb.</li>
          <li>Modtag påmindelser om vigtige ting du skal huske.</li>
          <li>Find svar på de mest stillede spørgsmål.</li>
        </ul>

        <s.VideoModal onClick={this.toggleVideo}>
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <img alt="" style={{ width: '120px' }} src={VideoThumb} />
            <p style={{ fontWeight: 'bold', marginLeft: 14 }}>Kort introduktion</p>
          </div>
        </s.VideoModal>
        <PopupScreenCenter
          visible={this.state.showVideo}
          onOverlayClick={this.toggleVideo}
          height={450}
          overlayAlpha={0.7}
          width={700}
          showCloseButton={true}
          content={
            <s.VideoViewer
              style={{ flex: 1, height: '100%' }}
              id="videoElement"
              ref={this.inputRef as any}
              autoPlay={true}
              controls={true}
              controlsList="nodownload"
              src={'https://visikoncorestorage.blob.core.windows.net/static-content/videos/Digital_onboarding.mp4'}
            />
          }
        />
      </s.Content>
    );
  }

  renderStoreBTN() {
    return (
      <s.AppStoreButtons>
        <s.UnStyledLink href={APPLE_APP_STORE_LINK}>
          <s.AppStoreButton>
            <img alt="Apple App Store logo" src={Iconfinder_Apple_Store_2697649} />
            <span>App Store</span>
          </s.AppStoreButton>
        </s.UnStyledLink>

        <s.UnStyledLink href={GOOGLE_PLAY_STORE_LINK}>
          <s.AppStoreButton>
            <img alt="Goole Play logo" src={Iconfinder_google_play_132832} />
            <span>Google Play</span>
          </s.AppStoreButton>
        </s.UnStyledLink>
      </s.AppStoreButtons>
    );
  }

  renderCodeAndCopieSection() {
    return (
      <div style={{ display: 'flex' }}>
        <s.StartCodeSection>
          <h1 id="text">{this.props.userCode}</h1>
        </s.StartCodeSection>
        <s.CopiedSection>
          <s.LinkButton onClick={this.copyText(this.props.userCode)}>
            <img alt="" style={{ width: 13, height: 14, color: '#1F5099', marginRight: 8 }} src={Copy_icon} />
            Kopier
          </s.LinkButton>
          {this.state.showPopup && <div style={{ color: 'green' }}>kopieret!</div>}
        </s.CopiedSection>
      </div>
    );
  }

  renderGetStartContent() {
    return (
      <s.ContentToGetStart>
        <h1>Sådan kommer du i gang</h1>
        <ol style={{ paddingLeft: 16 }}>
          <li> Din personlige kode er:</li>

          {this.renderCodeAndCopieSection()}

          {this.props.error && <p style={{ color: 'red' }}>Fejl i kode</p>}
          <p>Denne kode skal du gemme - enten ved at tage et billede af den, skrive den ned eller kopiere den.</p>

          <li>Brug koden til at logge ind på appen Mit Forløb.</li>
          <p>
            Du kan hente Mit Forløb i <b>App Store</b> hvis du har en iPad eller iPhone, eller på <b>Google Play</b> hvis du har en telefon eller
            tablet med Android.
          </p>

          {this.renderStoreBTN()}
          <p>Du kan også logge ind via hjemmesiden:</p>
          <div style={{ textAlign: 'center' }}>
            <a href="https://mitforlob.dk" className="weblink" target="_blank" rel="noreferrer">
              www.mitforløb.dk
            </a>
          </div>
        </ol>
      </s.ContentToGetStart>
    );
  }

  render() {
    return (
      <div style={{ overflow: 'auto' }}>
        {/* <TopBannerWrapper>
                    <BackgroundBanner />

                    <CounterBlock />
                    <ImageWrapper>
                        <Image src={Splash} />
                    </ImageWrapper>
                </TopBannerWrapper> */}
        <s.App>
          <s.ITEMOne />
          <s.ITEMTwo />
        </s.App>
        <s.PageWrapper>
          {/* <NavBar />

                    <TopBanner>
                        <img src={Splash} />
                    </TopBanner> */}

          <s.MainBodyContent>
            {this.renderContent()}
            {this.renderGetStartContent()}
          </s.MainBodyContent>
        </s.PageWrapper>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<IPropsFromState, {}, IState> = (state) => {
  return {
    error: state.resources.error,
  };
};

export default connect(mapStateToProps)(StartCodeScreen);
