import { useRef } from 'react';
import * as Styles from './LoginContainer.styles';
import { i18N, I18N } from '../../internationalization/AppText';
import { Dialog, DialogAnimations, DialogBody, DialogHeader } from '@visikon/spine';

export function CodeHelp() {
  const dialogRef = useRef<HTMLDialogElement>(null);

  return (
    <>
      <Styles.GuidingTitle onClick={() => dialogRef.current?.showModal()}>
        <I18N text="login_code_guiding_title" />
      </Styles.GuidingTitle>
      <Dialog className={DialogAnimations.SlideDown} _ref={dialogRef} usePortal>
        <DialogHeader children={i18N('login_code_guiding_title')} />
        <DialogBody>
          <div style={{ textAlign: 'initial' }}>
            <Styles.GuidingText>
              <I18N text="login_code_guiding_text" />
            </Styles.GuidingText>

            <Styles.GuidingTextHelp>
              <I18N text="login_code_guiding_text_help" />
            </Styles.GuidingTextHelp>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}
