import { CodeInput } from './CodeInput';
import { forwardRef, useEffect } from 'react';
import * as React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { CODE_INPUT_LENGTH } from '@visikon/mytreatment/src/screens/login/EndUserLogin';
import { Spinner } from '@visikon/mytreatment/src/common/Spinner';

interface CodeFieldProps {
  htmlId?: string;
  code: string;
  error: boolean;
  onFocus?: () => void;
  loading: boolean;
  onChange: (code: string) => void;
  login?: () => void;
}

export const CodeInputField = forwardRef<HTMLInputElement, CodeFieldProps>(({ htmlId, code, error, onFocus, onChange, loading, login }, ref) => {
  const errorOccurredRef = React.useRef(false);
  const codeRef = React.useRef(code);

  useEffect(() => {
    const activeElement = document.activeElement as HTMLElement;
    const inputField = activeElement as HTMLInputElement;

    if (error && !errorOccurredRef.current) {
      errorOccurredRef.current = true;
      return;
    }

    if (!inputField || loading || error || !login) return;

    const isEditingFields = activeElement === inputField;
    const reachedFullWhileEditing = isEditingFields && code.length === CODE_INPUT_LENGTH;

    if (!reachedFullWhileEditing) {
      return;
    }

    // Check if code has changed since the last render
    if (code !== codeRef.current) {
      login();

      // make virtual keyboard disappear on mobile
      inputField?.blur();

      // Update the stored value of code
      codeRef.current = code;
    }
  }, [code, error, loading, login]);

  function selectLastCharacter(inputField: HTMLInputElement) {
    const newPosition = CODE_INPUT_LENGTH - 1;
    inputField.setSelectionRange(newPosition, CODE_INPUT_LENGTH);
  }
  function selectAllCharacters(inputField: HTMLInputElement) {
    inputField.setSelectionRange(0, CODE_INPUT_LENGTH);
  }

  const handleChange = (event: React.ChangeEvent) => {
    const code = (event.target as HTMLInputElement).value;
    const filteredCode = code.replace(/[^A-z0-9]/gi, '');
    onChange(filteredCode);
  };

  const handleSelection = (event: React.SyntheticEvent<HTMLInputElement>, tap?: 'TAP') => {
    const target = event.target as HTMLInputElement;
    const isTap = tap === 'TAP';
    const { selectionStart: start, selectionEnd: end } = target;

    if (start === null || end === null) {
      return;
    }
    const selectAll = start === 0 && end === code.length;
    const selectMultipleCharacters = end - start > 1;

    if (selectMultipleCharacters || selectAll) {
      return;
    }

    if (isAndroid) {
      // Selecting a single character on Android if the user taps on the character
      if (isTap) {
        target.setSelectionRange(start, start + 1);
      }

      // Prevent double selection on Android
      return;
    }

    if (isIOS) {
      // Selecting individual characters is too challenging on iOS
      return;
    }

    target.setSelectionRange(start, start + 1);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const activeElement = document.activeElement as HTMLElement;
    const inputField = activeElement as HTMLInputElement;

    if (inputField.selectionStart === null) return;

    const isLetterOrNumber = /^[0-9a-zA-Z]$/.test(event.key);
    const lastCharacterSelected = inputField.selectionStart === CODE_INPUT_LENGTH - 1;

    // Prevent user from adding a cursor after the CODE_INPUT_LENGTH position
    if (code.length === CODE_INPUT_LENGTH && lastCharacterSelected && isLetterOrNumber) {
      selectLastCharacter(inputField);
    }

    // Arrow key navigation with always selecting character at cursor
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      if (inputField.selectionStart !== null) {
        inputField.setSelectionRange(inputField.selectionStart, inputField.selectionStart + 1);
        if (event.key === 'ArrowLeft') {
          inputField.setSelectionRange(inputField.selectionStart - 1, inputField.selectionStart);
        }
        if (event.key === 'ArrowRight') {
          inputField.setSelectionRange(inputField.selectionStart, inputField.selectionStart + 1);
        }
      }

      if (event.key === 'ArrowRight' && lastCharacterSelected) {
        selectLastCharacter(inputField);
        event.preventDefault();
        return;
      }
    }

    // Login on enter
    if (event.key === 'Enter') {
      login?.();
    }
  };

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    if (isIOS) {
      // Input fields when on Focus should select all characters
      selectAllCharacters(event.target as HTMLInputElement);
    }
  };

  return loading && !error ? (
    <Spinner />
  ) : (
    <CodeInput
      id={htmlId}
      ref={ref}
      error={error}
      value={code}
      onChange={handleChange}
      onFocus={onFocus}
      onClick={(e) => {
        handleSelection(e, 'TAP'); // for mobile
        handleInputClick(e);
      }}
      placeholder="________"
      maxLength={8}
      onSelect={(e) => handleSelection(e)}
      onKeyDown={handleKeyDown}
    />
  );
});
