import styled from 'styled-components';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import { Tokens } from '@visikon/spine/src/styles/tokens';

export const LinkContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 8.5em;

  @media all and (max-width: ${Sizes.breakPoint}) {
    max-width: 12em;
  }
`;

export const NavContainer = styled.nav`
  ion-footer ion-toolbar {
    @media all and (min-width: ${Sizes.breakPoint}) {
      --border-width: 0px !important;
    }
  }
`;

export const NavigationList = styled.ul`
  width: 110px;
  list-style-type: none;
  margin: 0;
  padding: 0 0 30px 0;
  background-color: white;
  z-index: 2;
  height: 100%;

  @media all and (max-width: ${Sizes.breakPoint}) {
    display: flex;
    justify-content: space-around;
    align-items: center;

    min-width: auto;
    width: 100%;
    max-height: 56px;
    padding: 0;
  }

  @media screen and (max-width: ${Sizes.breakPointMid}) {
    bottom: 0;
  }

  @media print {
    display: none;
  }
`;

export const NavigationListItem = styled.li<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;

  a {
    color: ${(props) => (props.active ? Tokens.color.primary : Tokens.colors.darkBlue[400])};
    font-weight: ${(props) => (props.active ? 600 : 400)};
    text-decoration: none;
  }

  ${(props) =>
    props.active &&
    `border-radius: 6px;
     background: rgba(0, 0, 0, 0.03);`}

  div.iconWrapper {
    display: flex;
    margin-bottom: 8px;
    justify-content: center;
    align-items: center;
  }

  div.labelWrapper {
    font-size: 0.8em;
    text-align: center;
    padding: 0 8px;
  }

  @media all and (max-width: ${Sizes.breakPoint}) {
    background-color: unset;
    // Fix padding
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;

    // Change sizes
    a {
      font-size: 0.75em;
    }
    div.iconWrapper {
      height: 24px;
      width: 24px;
      margin-bottom: 4px;
      justify-content: center;
      align-items: center;
    }
  }
`;
