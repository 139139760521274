import * as React from 'react';
import MDSpinner from 'react-md-spinner';
import styled from 'styled-components';
import { Tokens } from '@visikon/spine/src/styles/tokens';
const SpinnerWrapper = styled(MDSpinner)`
  margin-top: 5rem;
`;

export function Spinner() {
  return <SpinnerWrapper size={45} singleColor={Tokens.color.primary} borderSize={4.5} />;
}
