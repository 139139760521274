export namespace Tokens {
  export const colors = {
    primary: {
      100: '#C8F3FB',
      200: '#94E2F7',
      300: '#5CC2E8',
      400: '#349DD1',
      500: '#006DB3',
      600: '#005499',
      700: '#003F80',
      800: '#002C67',
      900: '#001F55',
    },

    darkBlue: {
      100: '#E5F3F5',
      200: '#CCE6EB',
      300: '#9DBBC3',
      400: '#667E87',
      500: '#263238',
      600: '#1B2830',
      700: '#131E28',
      800: '#0C1520',
      900: '#070F1A',
    },

    grey: {
      0: '#FFFFFF',
      50: '#F2F2F2',
      100: '#E6E6E6',
      200: '#CCCCCC',
      300: '#B3B3B3',
      400: '#999999',
      500: '#808080',
      600: '#666666',
      700: '#4D4D4D',
      800: '#333333',
      900: '#1A1A1A',
    },

    danger: {
      100: '#FDE1D3',
      200: '#FBBDA8',
      300: '#F38F7B',
      400: '#E76459',
      500: '#D82729',
      600: '#B91C2B',
      700: '#9B132C',
      800: '#7D0C2B',
      900: '#67072A',
    },

    success: {
      100: '#E0F8D3',
      200: '#BDF2A9',
      300: '#89D977',
      400: '#59B44E',
      500: '#228220',
      600: '#176F1D',
      700: '#105D1B',
      800: '#0A4B19',
      900: '#063E18',
    },
  };

  export const color = {
    primary: colors.primary[500],
    danger: colors.danger[500],
    success: colors.success[500],
    border: colors.grey[100],
    disabled: colors.grey[400],
    warning: '#ff9800', // '#ffffcc',
    text: colors.grey[900],
    lightGrey: colors.grey[50],
    white: colors.grey[0],
    primaryHover: colors.primary[600],
    dangerHover: colors.danger[600],
    outlineHover: colors.grey[50],
    primaryFocus: colors.primary[300],
    placeHolder: colors.grey[400],
    dialogBackground: '#F7F7F7',
    homeBackground: '#f1f4f8',
  };
}
