import actionCreatorFactory from 'typescript-fsa';
import { IAuthSuccessResult } from '../reducers/authReducer';

const actionCreator = actionCreatorFactory('Authentication');

export const clearLoginProgress = actionCreator('CLEAR_LOGIN_PROCESS');
export const logoutAndSetAuthCode = actionCreator<string>('LOGOUT_AND_SET_AUTH_CODE');
export const setAuthCode = actionCreator<string>('SET_AUTH_CODE');

export const setStartCode = actionCreator<string>('SET_START_CODE');
export const authenticateFromStorage = actionCreator('AUTHENTICATE_STORAGE');
export const authenticateSuccessful = actionCreator<IAuthSuccessResult>('AUTHENTICATE_SUCCESS');

export const logOut = actionCreator('LOG_OUT');
export const logOutSuccessful = actionCreator('LOG_OUT_SUCCESS');
