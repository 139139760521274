import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';

export const PrintButtonWrapper = styled.header`
  display: flex;
  justify-content: flex-end;
  margin: 20px;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    display: none;
  }

  @media print {
    display: none;
  }
`;

export const DocumentWrapper = styled.div`
  position: relative;
  flex: 1;
  z-index: 0;

  // // // // // // // // // // // // // // // // // // //
  // NOTE: Below is specific for larger screens         //
  // We normally make exceptions for smaller screens    //
  // // // // // // // // // // // // // // // // // // //
  @media all and (min-width: ${DeprecatedTokens.breakPoint}) {
    background-color: white;
    border: 1px solid #aaa;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    margin: 40px auto;
    max-width: 900px;

    &::before,
    &::after {
      width: 100%;
      background-color: white;
      border-left: 1px solid #aaa;
      border-top: 1px solid #aaa;
      border-right: 1px solid #aaa;
      content: '';
      height: 250px;
      position: absolute;
      left: -6px;
      top: -5px;
      z-index: -1;
      -moz-transform: rotate(-2deg);
      -webkit-transform: rotate(-2deg);
      transform: rotate(-2deg);
    }
    &::after {
      left: -3px;
      -moz-transform: rotate(-1deg);
      -webkit-transform: rotate(-1deg);
      transform: rotate(-1deg);
    }
  }

  @media print {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: transparent;
    max-width: 100%;
    border: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;

    &::before,
    &::after {
      border: 0;
      transform: none;
      height: 0px;
    }

    font-size: 0.8em !important;
  }
`;
