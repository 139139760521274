// tslint:disable:no-empty-interface interface-name no-shadowed-variable jsx-no-lambda
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { MitforlobContent } from '../../reducers/resourcesReducer';
import { IState } from '../../reducers/reducer';
import { FlexView } from '../../components/styled/ContentAreaContainers';
import { surveySubmit } from '../../actions/surveyActions';
import { I18N } from '../../internationalization/AppText';
import { Hr } from '../../components/styled/HorizontalLine';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import styled from 'styled-components';
import RadioButton from '../../components/radioButton/RadioButton';
import CheckBox from '../../components/checkBox/CheckBox';
import { TextArea } from '../../components/styled/Inputs';
import { Link } from 'react-router-dom';
import './ProgressBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { i18n } from '../../internationalization/i18n';
import { backbone } from '@visikon/backbone/src';
import { Content } from '@visikon/core-models';
import { Button } from '@visikon/spine/src';
import { LanguageCode } from '@visikon/core-models/content';

interface IPropsFromState {
  content?: MitforlobContent;
  language: Content.LanguageCode;
}

interface IOwnProps {
  onFinishCallback(): void;
  onCloseCallback(): void;
  questionIndex: number;
  setQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  surveyResult: { id: string; answers: any[] };
  setSurveyResult: React.Dispatch<React.SetStateAction<{ id: string; answers: any[] }>>;
  progress: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

interface DispatchProps {
  saveSubmitResult(surveyResult: any): void;
}

type Props = IPropsFromState & IOwnProps & DispatchProps;

type AnswerIndex = number;
export interface AnswerSingle {
  answerType: 'single';
  value: AnswerIndex;
  otherText?: string;
}

export interface AnswerMulti {
  answerType: 'multi';
  value: AnswerIndex[];
  otherText?: string;
}

export interface AnswerText {
  answerType: 'text';
  otherText?: string;
  value: string;
}
export type AnswerType = AnswerSingle | AnswerMulti | AnswerText;
export function isAnswerSingle(a: AnswerType): a is AnswerSingle {
  return a.answerType === 'single';
}
export function isAnswerMulti(a: AnswerType): a is AnswerMulti {
  return a.answerType === 'multi';
}
export function isAnswerText(a: AnswerType): a is AnswerText {
  return a.answerType === 'text';
}

export interface ISurveyResult {
  id: string;
  answers: AnswerType[];
}
interface ILocalState {
  question: string;
  isEnd: boolean;
  options: any;
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 50px;
`;

const RadiobuttonWrapper = styled.div`
  flex: 1;
`;

class CSurveyDetailScreen extends React.Component<Props, ILocalState> {
  checkmarkAnimation: React.RefObject<any> = React.createRef();
  constructor(props: Props) {
    super(props);
    this.state = {
      options: [],
      question: '',
      isEnd: false,
    };

    this.nextQuestionHandle = this.nextQuestionHandle.bind(this);
    this.previousQuestionHandle = this.previousQuestionHandle.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.toggleCheckIndex = this.toggleCheckIndex.bind(this);
  }

  componentDidMount() {
    this.loadQuize();
    // this.checkMarkAnimation();
  }

  componentDidUpdate(prevProps: any, prevState: ILocalState) {
    const { questionIndex } = this.props;

    if (this.props.questionIndex !== prevProps.questionIndex) {
      this.setState({
        question: this.props.content!.survey!.data.questions[questionIndex].questionText,
        options: this.props.content!.survey!.data.questions[questionIndex].answers || [],
      });
      this.props.setDisabled(questionIndex === 0);
    }
  }

  nextQuestionHandle() {
    const { questionIndex, surveyResult } = this.props;
    const percentageWidth = 100 / this.props.content!.survey!.data.questions.length;
    const perce = this.props.progress + percentageWidth <= 100 ? this.props.progress + percentageWidth : 100;
    if (this.props.content!.survey!.data.questions.length === questionIndex + 1) {
      this.props.saveSubmitResult(surveyResult);
      this.props.onFinishCallback();
      this.setState({
        isEnd: true,
      });
    } else {
      const nextIndex = this.props.questionIndex + 1;
      const nextQuestion = this.props.content!.survey!.data.questions[nextIndex];

      // Clone result

      const surveyResult = JSON.parse(JSON.stringify(this.props.surveyResult));

      // If we load an optional Text answer, set empty answer
      if (nextQuestion.questionType === 'text' && this.props.surveyResult.answers[nextIndex] === undefined) {
        surveyResult.answers[nextIndex] = {
          answerType: 'text',
          value: '',
        };
      }

      this.props.setSurveyResult(surveyResult);
      this.props.setQuestionIndex(nextIndex);
      this.props.setProgress(perce);
    }
  }

  previousQuestionHandle() {
    const percentageWidth = 100 / this.props.content!.survey!.data.questions.length;
    const perce = this.props.progress - percentageWidth <= 100 ? this.props.progress - percentageWidth : 100;

    this.props.setDisabled(true);
    this.props.setQuestionIndex(this.props.questionIndex - 1);
    this.props.setProgress(perce);

    if (perce <= 0) {
      this.props.setProgress(0);
    }
  }
  loadQuize = () => {
    const { questionIndex } = this.props;

    this.setState({
      question: this.props.content!.survey!.data.questions[questionIndex].questionText,
      options: this.props.content!.survey!.data.questions[questionIndex].answers,
    });

    const surveyId = this.props.content!.survey!._id;
    this.props.setSurveyResult((oldState) => ({ ...oldState, id: surveyId }));

    this.props.setProgress(this.props.progress);
  };

  toggleCheckIndex(oldValues: number[], idx: any) {
    if (oldValues.indexOf(idx) === -1) {
      return [...oldValues, idx].sort();
    } else {
      return oldValues.filter((i) => i !== idx);
    }
  }

  setAnswerChecked = (index: number, e?: any) => {
    const { surveyResult, questionIndex } = this.props;

    const questionType = this.props.content!.survey!.data.questions[questionIndex].questionType;

    if (questionType === 'single') {
      this.props.setSurveyResult((prevState) => {
        const updatedAnswers = [...prevState.answers];
        updatedAnswers[questionIndex] = {
          answerType: questionType,
          value: index,
          otherText: e,
        };
        return {
          ...prevState,
          answers: updatedAnswers,
        };
      });
    }

    if (questionType === 'multi') {
      this.props.setSurveyResult((prevSurveyResult) => {
        let oldValues: number[] = [];
        if (prevSurveyResult.answers[questionIndex] !== undefined) {
          oldValues = prevSurveyResult.answers[questionIndex].value as number[];
        }
        const otherText = prevSurveyResult.answers[questionIndex] ? prevSurveyResult.answers[questionIndex].otherText : undefined;
        const updatedAnswers = [...prevSurveyResult.answers];
        updatedAnswers[questionIndex] = {
          answerType: questionType,
          otherText,
          value: this.toggleCheckIndex(oldValues, index),
        };
        return {
          ...prevSurveyResult,
          answers: updatedAnswers,
        };
      });
    }
  };

  setOtherText(e: string) {
    const { questionIndex, surveyResult } = this.props;
    surveyResult.answers[questionIndex].otherText = e;
  }

  setText(str: string) {
    // TODO: Make these immutabel
    this.props.setSurveyResult((prevSurveyResult) => {
      const { questionIndex } = this.props;
      const oldAnswer = prevSurveyResult.answers[questionIndex] || {};
      if (this.props.content!.survey!.data.questions[questionIndex].questionType === 'text') {
        const updatedAnswers = [...prevSurveyResult.answers];
        updatedAnswers[questionIndex] = {
          ...oldAnswer,
          answerType: 'text',
          value: str,
        };
        return {
          ...prevSurveyResult,
          answers: updatedAnswers,
        };
      }
      return prevSurveyResult;
    });
  }

  isChecked(answerIdx: number) {
    const { questionIndex, surveyResult } = this.props;
    if (surveyResult.answers[questionIndex] === undefined) {
      return false;
    }

    if (surveyResult.answers[questionIndex].answerType === 'single') {
      return surveyResult.answers[questionIndex].value === answerIdx;
    }

    if (surveyResult.answers[questionIndex].answerType === 'multi') {
      return ((surveyResult.answers[questionIndex].value as number[]) || []).indexOf(answerIdx) !== -1;
    }

    // TODO: Handle text
    return false;
  }

  isNextButtonDisabled() {
    const { content } = this.props;
    if (content === undefined || content.survey === undefined) {
      return true;
    }

    const { questionIndex, surveyResult } = this.props;

    const currentQuestion = content.survey.data.questions[questionIndex];

    // For multi questions - the value array cannot be undefined and cannot be empty
    if (currentQuestion.questionType === 'multi') {
      return (
        surveyResult.answers[questionIndex] === undefined ||
        surveyResult.answers[questionIndex].value === undefined ||
        (surveyResult.answers[questionIndex].value as number[]).length === 0
      );
    }

    // For all other types - it cannot be undefined
    return surveyResult.answers[questionIndex] === undefined;
  }

  renderEnd() {
    return (
      <FlexView
        style={{
          flex: 1,
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div className="progress">
            <div style={{ width: `${100}%` }} className="progressvalue" />
          </div>
          <FontAwesomeIcon icon={faCheckCircle} size={'lg'} color={DeprecatedTokens.positive} />
        </div>

        <Hr color={DeprecatedTokens.border} />
        <div
          style={{
            flex: 1,
            flexDirection: 'column',
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 75,
          }}
        >
          <h1>
            <I18N text="thanks_for_your_reply" />
          </h1>
          <div ref={this.checkmarkAnimation} />
        </div>
        <div
          style={{
            marginBottom: 35,
            alignSelf: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Link to="" onClick={() => this.props.onCloseCallback()}>
            <Button type="submit">
              <I18N text="close" />
            </Button>
          </Link>
        </div>
      </FlexView>
    );
  }

  renderHeader() {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div className="progress">
            <div style={{ width: `${this.props.progress}%` }} className="progressvalue" />
          </div>
          <FontAwesomeIcon icon={faCheckCircle} size={'lg'} color="#A9A9A9" />
        </div>
        <Hr color={DeprecatedTokens.border} />
      </>
    );
  }

  renderPreviousNextButtons() {
    return (
      <ButtonWrapper>
        <Button type="submit" onClick={this.previousQuestionHandle} disabled={this.props.disabled}>
          <I18N text="previous" />
        </Button>
        <Button type="submit" disabled={this.isNextButtonDisabled()} onClick={this.nextQuestionHandle}>
          <I18N text="next" />
        </Button>
      </ButtonWrapper>
    );
  }
  renderOtherText() {
    const { questionIndex } = this.props;
    const { options } = this.state;

    return (
      <div>
        {this.props.content!.survey!.data.questions[questionIndex].hasOtherField &&
        this.props.content!.survey!.data.questions[questionIndex].questionType === 'multi' ? (
          <CheckBox
            checked={this.isChecked(options?.length)}
            onPress={() => this.setAnswerChecked(options?.length)}
            label={i18n('survey_other_answer', this.props.language)}
          />
        ) : (
          this.props.content!.survey!.data.questions[questionIndex].hasOtherField && (
            <RadioButton
              onPress={() => {
                this.setAnswerChecked(options?.length);
              }}
              checked={this.isChecked(options?.length)}
              label={i18n('survey_other_answer', this.props.language)}
            />
          )
        )}
        {this.isChecked(options?.length) && (
          <TextArea
            style={{
              height: 250,
              marginTop: 25,
              marginLeft: 30,
            }}
            onChange={(event: any) => this.setOtherText(event.target.value)}
            value={this.props.surveyResult.answers[questionIndex].otherText}
            placeholder={i18n('survey_text_placeholder', this.props.language)}
          />
        )}
      </div>
    );
  }

  renderCurrentQuestion() {
    const { questionIndex } = this.props;
    const { question, options } = this.state;
    const { questionType } = this.props.content!.survey!.data.questions[questionIndex];

    if (questionType === 'text') {
      return (
        <>
          <div style={{ textAlign: 'center' }}>
            <h2>{question}</h2>
          </div>
          <TextArea
            style={{
              height: 250,
              marginTop: 25,
              alignSelf: 'center',
            }}
            onChange={(event: any) => this.setText(event.target.value)}
            // onKeyPress={() => this.checkAnswer(idx)}
            value={this.props.surveyResult.answers[questionIndex] ? (this.props.surveyResult.answers[questionIndex].value as string) : ''}
            placeholder={i18n('survey_text_placeholder', this.props.language)}
          />
        </>
      );
    }

    if (questionType === 'single') {
      return (
        <>
          <div style={{ textAlign: 'center' }}>
            <h2>{question}</h2>
          </div>

          {options?.map((option: any, idx: number) => (
            <RadioButton key={`${option.text}${idx}`} checked={this.isChecked(idx)} onPress={() => this.setAnswerChecked(idx)} label={option.text} />
          ))}
          {this.renderOtherText()}
        </>
      );
    }
    if (questionType === 'multi') {
      return (
        <>
          <div style={{ textAlign: 'center' }}>
            <h2>{question}</h2>
          </div>
          {options?.map((option: any, idx: number) => (
            <CheckBox key={`${option.text}${idx}`} checked={this.isChecked(idx)} onPress={() => this.setAnswerChecked(idx)} label={option.text} />
          ))}
          {this.renderOtherText()}
        </>
      );
    }
    return null;
  }

  render() {
    const { isEnd } = this.state;

    if (isEnd) {
      return this.renderEnd();
    }

    return (
      <FlexView
        style={{
          flex: 1,
          height: '100%',
          flexDirection: 'column',
        }}
      >
        {this.renderHeader()}

        <RadiobuttonWrapper>{this.renderCurrentQuestion()}</RadiobuttonWrapper>

        {this.renderPreviousNextButtons()}
      </FlexView>
    );
  }
}

const mapStateToProps: MapStateToProps<IPropsFromState, IOwnProps, IState> = (state) => ({
  content: state.resources.content,
  language: backbone.store.getState().country.languageCode as LanguageCode,
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, IOwnProps> = (dispath) => ({
  saveSubmitResult: (surveyEntry) => {
    dispath(surveySubmit(surveyEntry));
  },
});

export const SurveyDetailScreen = connect(mapStateToProps, mapDispatchToProps)(CSurveyDetailScreen);
