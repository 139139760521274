import { backbone } from '@visikon/backbone/src';
import { Content } from '@visikon/core-models';
import { MapStateToProps, connect } from 'react-redux';
import { ProgramInfo, useLanguage, useProgramInformationFromContent } from '../../common/selectors';
import { isContentContainer, usePromise } from '../../common/utils';
import { WithFooter } from '../../components/Footer';
import { DocumentRenderer } from '../../components/documentViewer/DocumentRenderer';
import { ExpandPanel } from '../../components/panel/ExpandPanel';
import { CCHeader, FlexWrapper, SmallViewSidePadding } from '../../components/styled/ContentAreaContainers';
import { IState } from '../../reducers/reducer';
import { ContentContainer } from '../../reducers/resourcesReducer';
import { getTranslation } from '../../sagas/resourcesSaga';
import { GenericScreenHeader } from '../GenericScreenHeader';
import { ContentCardComponent } from './ContentCardComponent';
import { Card, CardDescription, ContentWrapper } from './ContentContainerComponent.styles';
import * as React from 'react';
import { ContentConfig } from '../../common/ContentConfig';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavLinkHelper } from '../../common/NavigationHelper';
import { VideoScreenComponent } from '../../components/video/VideoScreen';
import { ContentContainerSection } from '../../components/ContentContainerSection';

const getPanelContentCard = (data: ContentContainer, fieldKey: keyof ContentConfig) => {
  const content = data.content.map((cont) => <ScreenComponent content={cont} key={cont._id} fieldKey={fieldKey} />);
  const card = (
    <Card key={data?.title}>
      <div>
        <CardDescription>{data?.description}</CardDescription>
        <div>{content}</div>
      </div>
    </Card>
  );
  return <ExpandPanel cta={card} header={data.title} key={data?.title} />;
};

const getContentCard = (data: ContentContainer, id: string, fieldKey?: keyof ContentConfig) => {
  return <ContentCardComponent key={data?.title} content={data} id={id} fieldKey={fieldKey} />;
};

export const ContentContainerComponent: (contentContainer: any, fieldKey: keyof ContentConfig) => JSX.Element = ({
  contentContainer,
  fieldKey,
}: {
  contentContainer: any;
  fieldKey: keyof ContentConfig;
}) => {
  if (!contentContainer) {
    return <></>;
  }

  const { isFirstMenuItem, isMobileOrHasNoMenu, contentNavLink, translatedPage, updateNavLink } = useNavLinkHelper();
  const location = useLocation();
  useEffect(() => {
    if (isMobileOrHasNoMenu && !isFirstMenuItem) {
      if (translatedPage) {
        // we are on fist level
        updateNavLink(contentNavLink);
      }
      if (isContentContainer()) {
        updateNavLink({ title: contentContainer.data.title });
      }
    } else {
      if (isContentContainer()) {
        console.log('updateNavLink', location);
        // @ts-ignore
        updateNavLink({ title: location.state.from });
      }
    }
  }, [contentContainer]);

  const data = contentContainer.data;
  // Special case: If we have a contentContainer - with just 1 content item - that is NOT a ContentContainer - just show it without the wrapper
  const content = data.content.map((contentContainer) => (
    <ScreenComponent content={contentContainer} key={contentContainer._id} fieldKey={fieldKey} />
  ));
  // If enabled title of content container content is not shown
  // if (data.content?.length === 1 && data.content[0].typeDescriptor !== 'contentContainer') {
  //   return content;
  // }

  return (
    <FlexWrapper>
      <ContentContainerSection style={{ flexDirection: 'row' }}>
        <SmallViewSidePadding>
          <ContentWrapper>
            <WithFooter footerStyle={{ paddingTop: '16px' }}>
              <CCHeader>
                <GenericScreenHeader />
              </CCHeader>
              {content}
            </WithFooter>
          </ContentWrapper>
        </SmallViewSidePadding>
      </ContentContainerSection>
    </FlexWrapper>
  );
};

interface ScreenComponentProps {
  content: any;
  fieldKey: keyof ContentConfig;
}

export function ScreenComponent({ content, fieldKey }: ScreenComponentProps) {
  const language = useLanguage();
  const programInformation = usePromise<ProgramInfo>(useProgramInformationFromContent, content);

  if (content.data.collapsible) {
    return getPanelContentCard(content.data, fieldKey);
  }
  if (content.typeDescriptor === 'contentContainer') {
    return getContentCard(content.data, content._id, fieldKey);
  }

  if (content.typeDescriptor === 'videoList') {
    const disableFavorites = programInformation?.programType === 'information';
    return (
      <VideoScreenComponent videoList={content} fieldKey={'not used'} isContained={true} disableFavorites={disableFavorites}></VideoScreenComponent>
    );
  }
  if (content.typeDescriptor === 'document') {
    const textElement = content.data.textList[0].text;
    const textTranslation = getTranslation(textElement, language);
    return <DocumentRenderer document={textTranslation.content.document} />;
  }
  return <>{content.typeDescriptor} not supported</>;
}
