import * as SizeStyles from './size.styles';
import * as ColorStyles from './colors.styles';
import * as TextStyles from './text.styles';

export namespace Styles {
  export const size = SizeStyles.Styles.size;
  export const weight = SizeStyles.Styles.weight;
  export const text = TextStyles.Styles.text;
  export const color = ColorStyles.Styles.color;
}
