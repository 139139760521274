import * as React from 'react';
import { GetButton } from '../program/SmartBanner.styles';
import { I18N } from '../../internationalization/AppText';
import { useState } from 'react';
import { i18n } from '../../internationalization/i18n';
import { CodeInputField, Dialog, DialogAnimations, DialogBody, DialogHeader } from '@visikon/spine';
import { CodeHelp } from '../login/CodeHelp';
import { Spinner } from '../../common/Spinner';
import { CODE_INPUT_LENGTH, ERROR_TOO_FEW_CHARACTERS, ErrorMessage } from '../login/EndUserLogin';
import * as Styles from './HomeScreen.styles';
import { useMergeUserMutation } from '../../api/auth-api';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import { TranslatableError } from '../../api/auth-api.models';

interface CodeMergeModalProps {
  dialogRef: React.RefObject<HTMLDialogElement>;
}

export function CodeMergeModal({ dialogRef }: CodeMergeModalProps) {
  const mergeMutation = useMergeUserMutation();
  const [mergeCode, setMergeCode] = useState('');
  const translableError = React.useMemo<TextKeys | undefined>(() => {
    if (mergeMutation.error === null) return undefined;
    if (mergeMutation.error instanceof TranslatableError) {
      const error = mergeMutation.error.error;

      // Better error message when code is shorter than the usual codes length
      if (error === 'merge_error_unknown_code' && mergeCode.length < CODE_INPUT_LENGTH) {
        return ERROR_TOO_FEW_CHARACTERS;
      }

      return error;
    }

    return 'merge_error_unknown_error';
  }, [mergeMutation.error]);

  const handleAddProgramClick = () => {
    console.debug('Adding program with code: ', mergeCode);

    mergeMutation.mutate({ code: mergeCode });
  };

  const handleOnChange = (code: string) => {
    if (mergeMutation.isError) {
      mergeMutation.reset();
    }
    setMergeCode(code);
  };

  return (
    <Dialog className={DialogAnimations.SlideDown} _ref={dialogRef}>
      <DialogHeader>{i18n('home_add_new_program_title')}</DialogHeader>
      <DialogBody>
        <Styles.CodeFieldContainer>
          {mergeMutation.isLoading ? (
            <Styles.SpinnerContainer>
              <Spinner />
            </Styles.SpinnerContainer>
          ) : (
            <>
              <Styles.PersonalCodeText>
                <I18N text={'home_add_new_program_body'} />
              </Styles.PersonalCodeText>
              <Styles.CodeContainer>
                <CodeInputField
                  code={mergeCode}
                  error={mergeMutation.isError}
                  onChange={handleOnChange}
                  login={handleAddProgramClick}
                  loading={mergeMutation.isLoading}
                />

                <Styles.ErrorText>{ErrorMessage(translableError)}</Styles.ErrorText>

                <GetButton onClick={handleAddProgramClick}>
                  <I18N text="continue" />
                </GetButton>
              </Styles.CodeContainer>
              <Styles.PersonalCodeLink>
                <CodeHelp />
              </Styles.PersonalCodeLink>
            </>
          )}
        </Styles.CodeFieldContainer>
      </DialogBody>
    </Dialog>
  );
}
