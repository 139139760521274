import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import styled from 'styled-components';
import { Unstyled } from '../../components/Unstyled';

export const SurveyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h3 {
    margin-bottom: 0;
    margin-top: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const SurveyWrapper = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const GetButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: ${Sizes.breakPointMid}) {
    width: 100%;
  }
`;

export const InnerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000054;
`;

export const ConfirmPopup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: #acacac 0 0 10px;
  background-color: white;
  width: 500px;
  height: 220px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: auto;

  & > h1 {
    margin: 0;
    font-size: 1.7em;
  }
`;
export const DismissButton = styled(Unstyled.Button)`
  width: 12px;
  margin-left: auto;
`;

export const Invitation = styled.p`
  text-align: center;
  margin: 1rem;
`;
