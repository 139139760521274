import { ReactComponent as help } from '../images/svg/help.svg';
import { ReactComponent as help_filled } from '../images/svg/help_filled.svg';
import { ReactComponent as heart } from '../images/svg/heart.svg';
import { ReactComponent as heart_filled } from '../images/svg/heart_filled.svg';
import { ReactComponent as pulse } from '../images/svg/pulse.svg';
import { ReactComponent as pulse_filled } from '../images/svg/pulse_filled.svg';
import { ReactComponent as documents } from '../images/svg/document.svg';
import { ReactComponent as documents_filled } from '../images/svg/document_filled.svg';
import { ReactComponent as body } from '../images/svg/body.svg';
import { ReactComponent as body_filled } from '../images/svg/body_filled.svg';
import { ReactComponent as medkit } from '../images/svg/medkit.svg';
import { ReactComponent as medkit_filled } from '../images/svg/medkit_filled.svg';
import { ReactComponent as play } from '../images/svg/play.svg';
import { ReactComponent as play_filled } from '../images/svg/play_filled.svg';
import { SvgIcon } from './svgIcon';

const IconTypes = {
  help: help,
  help_active: help_filled,
  heart: heart,
  heart_active: heart_filled,
  pulse: pulse,
  pulse_active: pulse_filled,
  documents: documents,
  documents_active: documents_filled,
  body: body,
  body_active: body_filled,
  medkit: medkit,
  medkit_active: medkit_filled,
  play: play,
  play_active: play_filled,
};

interface IProps {
  name: string;
  active: boolean;
}
export const NavigationIcon = ({ name, active }: IProps) => {
  let iconName = name;
  if (active) iconName += '_active';
  if (iconName in IconTypes) {
    return <SvgIcon Icon={IconTypes[iconName]} width={24} className={active ? 'navTabs-active' : ''} aria-hidden />;
  }
  return null;
};
