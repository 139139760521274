import * as React from 'react';
import { GenericScreenComponent } from './GenericScreenComponent';
import { ContentConfig } from '../common/ContentConfig';

export default function GenericScreenContainer({ fieldKey, path }: { fieldKey: keyof ContentConfig; path: string }) {
  return (
    <>
      <GenericScreenComponent fieldKey={fieldKey} path={path} />
    </>
  );
}
