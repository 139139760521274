import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import styled from 'styled-components';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';

export const Section = styled.section`
  margin: unset;
  padding: unset;
`;

export const ExternalLink = styled.a`
  color: ${DeprecatedTokens.breakPoint};
`;

export const Container = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px;
  font-size: 1.2em;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    margin: 0;
    font-size: 1.1em;
  }
  @media all and (max-width: ${Sizes.breakPointMid}) {
    padding: 16px;
  }
`;

export const Title = styled.div`
  font-size: 2em;
  margin-bottom: 20px;
  margin-top: 40px;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    font-size: 1.4em;
    margin-top: 0;
  }
`;

export const Header = styled.div`
  font-size: 1.5em;
  margin-bottom: 20px;
  margin-top: 40px;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    font-size: 1.2em;
  }
`;

export const Paragraph = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 20px;
  line-height: 1.4em;
`;

export const List = styled.div`
  margin-left: 40px;
  margin-bottom: 30px;
`;

export const ListItem = styled.div`
  margin-bottom: 5px;
`;

export const Center = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const text: { [key: string]: React.CSSProperties } = {
  boldText: {
    fontWeight: 'bold',
  },
  italicText: {
    fontStyle: 'italic',
  },
};
