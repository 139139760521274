import { getTranslation } from '@visikon/core-models/typeUtils';
import { MediaUtils } from '../../common/utils';
import { MediaFileUtils } from '@visikon/utils';
import videoPlaceHolder from '../../images/videoPlaceholder.png';
import { VideoInfoList } from '../../reducers/resourcesReducer';
import { FavoriteButton } from './FavoriteButton';
import * as Styles from './VideoCard.styles';
import { VIDEO_PLAYER_STR } from '../../screens/videoPlayer/PlayerContainer';
import { useBackbone } from '@visikon/backbone/src';
import { EnvironmentName } from '@visikon/backbone/src/env';
import { LanguageCode } from '@visikon/core-models/content';
import VideoThumbnail from '../VideoThumbnail';
import { useVideoWatchedPercentage } from '../../screens/videoPlayer/videoUtils';

interface IProps {
  videoInfo: VideoInfoList[0];
  favorites?: string[];
  language: LanguageCode;
  isList?: boolean;
  isAutoPlay?: boolean;
}

export const videoPlayerSrc = (programId, videoId: string) => {
  return `/${programId}${VIDEO_PLAYER_STR}${videoId}`;
};

export const VideoCard = ({ videoInfo, language, isList = false, favorites, isAutoPlay }: IProps) => {
  const watchedPercentage = useVideoWatchedPercentage(videoInfo.video._id);
  const programId = useBackbone((state) => state.program?.id);
  const videoPlayerUrl = videoPlayerSrc(programId, videoInfo.video._id);

  const { env, hosts } = useBackbone((state) => ({
    env: state.env,
    hosts: state.hosts,
  }));

  if (!videoInfo || !videoInfo.video) {
    return null;
  }

  const video = getTranslation(videoInfo.video, language);
  if (video === undefined) {
    return null;
  }

  let videoUrl = `${hosts.blobs}${video.src}`;
  if (env === EnvironmentName.Local) {
    videoUrl = `${hosts.core}media/video/file/${videoInfo.video._id}/${video.language}?modified=${video.lastModified}`;
  }

  const durationText = MediaUtils.prettyPrintDuration(video.length);
  const thumbSrc = MediaFileUtils.getUriForThumbnail(videoInfo.video, language) || videoPlaceHolder;

  let videoLink: any = '#'; // no link
  if (!isAutoPlay) {
    videoLink = videoPlayerUrl;
  }

  const notShowFavorites = !favorites || isList || isAutoPlay;

  return (
    <Styles.TotalContainerCard to={videoLink} $islist={isList} $isgif={isAutoPlay} aria-label={videoInfo.name} tabIndex={isAutoPlay ? -1 : 0}>
      <Styles.CardContainer>
        {isAutoPlay ? (
          <Styles.ListVideo src={videoUrl} muted autoPlay loop playsInline poster={thumbSrc} />
        ) : (
          <VideoThumbnail thumbUrl={thumbSrc}>
            <Styles.DurationSticker text={durationText} hidden={isAutoPlay} />
            <Styles.ProgressIndicator percentage={watchedPercentage} hidden={isAutoPlay} />
          </VideoThumbnail>
        )}

        {notShowFavorites ? null : <FavoriteButton videoId={videoInfo.video._id} favorites={favorites} />}
      </Styles.CardContainer>
      <Styles.CardTitleContainer hidden={isList}>
        <Styles.CardTitle>
          {videoInfo.name}
          <Styles.CardBorderAccent />
        </Styles.CardTitle>
      </Styles.CardTitleContainer>
    </Styles.TotalContainerCard>
  );
};
