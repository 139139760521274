import { ContactDialog } from './ContactDialog';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@visikon/spine';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import { I18N } from '../../internationalization/AppText';
import { IState } from '../../reducers/reducer';

export const ContactButton = () => {
  const contactText = useSelector((state: IState) => state.resources.content?.contactText);
  const dialogRef = React.useRef<HTMLDialogElement>(null);

  return contactText ? (
    <>
      <div style={{ width: '500' }}>
        <Button
          onClick={() => dialogRef.current?.showModal()}
          size={'small'}
          variant={'ghost'}
          style={{
            textTransform: 'none',
            background: 'rgba(0, 109, 179, 0.06)',
            fontWeight: Tokens.text.weight.bold,
            padding: '8px 12px',
          }}
        >
          <I18N text={'contact'} />
        </Button>
      </div>
      <ContactDialog dialogRef={dialogRef} document={contactText} />
    </>
  ) : null;
};
