import { TextKeys } from '@visikon/core-models/i18n/translations';
import { LanguageCode } from '@visikon/core-models/languageTypes';
import { CustomError } from 'ts-custom-error';
import { IUserDataState } from '../reducers/userDataReducer';

export type UserLoginRequest = {
  code: string;
};

export type StaffLoginRequest = {
  username: string;
  password: string;
};

export type LoginResponse = {
  token: string;
  userData: IUserDataState;
  referencedUser: boolean;
  firstLogin: boolean;
  activatedAt: number;
  realCode?: string;
};

export type MergeErrorString =
  | 'merge_error_code_parameter_missing'
  | 'merge_error_user_not_logged_in'
  | 'merge_error_current_user_not_found'
  | 'merge_error_unknown_code'
  | 'merge_error_same_code'
  | 'merge_error_code_already_used'
  | 'merge_error_new_language'
  | 'merge_error_unknown_error';

export class TranslatableError extends CustomError {
  constructor(public error: TextKeys, public availableLanguages?: LanguageCode[]) {
    super();
  }
}
