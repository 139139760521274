import * as React from 'react';
import * as Styles from './DocumentRenderer.styles';
import {
  DocumentJSON,
  NodeJSON,
  isDocumentJSON,
  isTextJSON,
  isInlineJSON,
  isBlockJSON,
  BlockJSON,
  TextJSON,
  Leaf,
  InlineJSON,
} from '../../types/slate-types';
import { DocumentImage } from './DocumentImage';
import { CSSProperties } from 'react';

interface RenderConfig {
  inList?: boolean;
}

interface Props {
  document: DocumentJSON;
  getTableOfContent?(tableOfContent: string[]): void;
  style?: CSSProperties;
}

export const DocumentRenderer = (props: Props) => {
  const docContainerRef = React.useRef<HTMLDivElement>(null);
  const headersRef = React.useRef<string[]>([]);
  const titlesRef = React.useRef<string[]>([]);

  // refresh table of content when document changes
  React.useEffect(() => {
    headersRef.current = [];
    titlesRef.current = [];
  }, [props.document]);

  React.useEffect(() => {
    const titles = titlesRef.current;
    if (!props.getTableOfContent) {
      return;
    }

    props.getTableOfContent(titles);
  }, [props.getTableOfContent]);

  const renderHeader = (header: BlockJSON, style: React.CSSProperties, key: string) => {
    // Header functionality only renders the first node in title/header block
    if (!header.nodes) {
      console.warn('Header element missing nodes');
      return null;
    }

    if (header.nodes.length > 1) {
      console.warn('Header element has more than one nodes');
    }

    const node = header.nodes[0];
    if (isTextJSON(node)) {
      if (key.endsWith('title')) {
        return (
          <Styles.Title key={key} ref={(r) => (titlesRef.current[key] = r)} className={header.type}>
            {renderText(node, key)}
          </Styles.Title>
        );
      } else {
        return (
          <Styles.Header key={key} ref={(r) => (headersRef.current[key] = r)} className={header.type}>
            {renderText(node, key)}
          </Styles.Header>
        );
      }
    }

    // This is a fall back
    console.warn('Header element contained unexpected child..');
    return renderElement(node, key);
  };

  const renderBlock = (block: BlockJSON, parentKey: string, renderConfig) => {
    const key = `${parentKey}`;
    switch (block.type) {
      case 'title':
        return renderHeader(block, Styles.text.title, `${key}_title`);
      case 'header':
        return renderHeader(block, Styles.text.header, `${key}_header`);
      case 'center':
        return renderCenter(block, key);
      case 'ul_list':
        return renderList(block, key);
      case 'ol_list':
        return renderList(block, key);
      case 'list_item':
        return renderListItem(block, key);
      case 'paragraph':
        return renderParagraph(block, key, renderConfig);
      case 'image':
        return renderImage(block, key);
      default: {
        console.warn('Fallback:', block.type);
        return renderNodes(block.nodes, key);
      }
    }
  };

  const renderInline = (inline: InlineJSON, parentKey: string) => {
    switch (inline.type) {
      case 'externalLink':
        return renderExternalLink(inline, parentKey);
      default:
        return null;
    }
  };

  const renderExternalLink = (link: InlineJSON, parentKey: string) => {
    if (link.data === undefined || link.data.href === undefined || link.nodes === undefined) {
      return null;
    }

    const text = link.nodes[0];
    const key = `${parentKey}_link`;
    return (
      <Styles.ExternalLink href={link.data.href} key={key} target="_blank" rel="noreferrer">
        {renderElement(text, key)}
      </Styles.ExternalLink>
    );
  };

  const renderCenter = (block: BlockJSON, parentKey: string) => {
    return <Styles.Center key={parentKey}>{renderNodes(block.nodes, parentKey)}</Styles.Center>;
  };

  const renderParagraph = (paragraph: BlockJSON, parentKey: string, config?: RenderConfig) => {
    if (config && config.inList) {
      return renderListItem(paragraph, parentKey);
    }
    return <Styles.Paragraph key={`${parentKey}_paragraph`}>{renderNodes(paragraph.nodes, parentKey)}</Styles.Paragraph>;
  };

  const renderListItem = (block: BlockJSON, parentKey: string) => {
    return <Styles.ListItem key={parentKey}>{renderNodes(block.nodes, parentKey, { inList: true })}</Styles.ListItem>;
  };

  const renderList = (list: BlockJSON, parentKey: string) => {
    const listItems = (list.nodes || []).filter(isBlockJSON).map((n, i) => (
      <div key={`${parentKey}_${i}`} style={{ display: 'flex', flexDirection: 'row' }}>
        <span style={{ paddingRight: 5, minWidth: 20 }}>{list.type === 'ul_list' ? '\u2022' : `${i + 1}.`} </span>
        <div style={{ flex: 1, display: 'inline-block' }}>{renderListItem(n, `${parentKey}_elem_${i}`)}</div>
      </div>
    ));
    return <Styles.List key={parentKey}>{listItems}</Styles.List>;
  };

  const renderImage = (image: BlockJSON, _: string) => {
    const imageId = image.data ? image.data.idRef : undefined;

    if (!imageId) {
      return null;
    }

    return (
      <div key={imageId} style={{ marginBottom: 30 }}>
        <DocumentImage alt="" imageId={imageId} language="da" />
      </div>
    );
  };

  const renderText = (text: TextJSON, parentKey: string) => {
    return text.leaves.map((l, i) => renderLeaf(l, `${parentKey}_leaf_${i}`));
  };

  const renderLeaf = (leaf: Leaf, key: string) => {
    const hasMark = (leaf: Leaf, type: string) => {
      return leaf.marks && leaf.marks.find((m) => m.type === type);
    };

    if (hasMark(leaf, 'bold')) {
      return (
        <span style={Styles.text.boldText} key={key}>
          {leaf.text}
        </span>
      );
    }
    if (hasMark(leaf, 'italic')) {
      return (
        <span style={Styles.text.italicText} key={key}>
          {leaf.text}
        </span>
      );
    }

    // Empty line
    if (leaf.text.trim() === '') {
      return <span key={key}> </span>;
    }

    return <span key={key}>{leaf.text}</span>;
  };

  const renderNodes = (nodes: NodeJSON[] | undefined, parentKey: string, renderConfig?: RenderConfig): React.ReactNode[] => {
    if (nodes === undefined) {
      return [];
    }
    return (nodes || []).map((n, i) => renderElement(n, `${parentKey}_${i}`, renderConfig));
  };

  const renderElement = (element: NodeJSON, parentKey: string, renderConfig?: RenderConfig): React.ReactNode => {
    const key = `${parentKey}_element`;
    if (isDocumentJSON(element)) {
      return <article key={parentKey}>{renderNodes(element.nodes, `${parentKey}_doc`)}</article>;
    }
    if (isTextJSON(element)) {
      return <Styles.Section key={parentKey}>{renderText(element, key)}</Styles.Section>;
    }
    if (isInlineJSON(element)) {
      return <Styles.Section key={parentKey}>{renderInline(element, key)}</Styles.Section>;
    }
    if (isBlockJSON(element)) {
      return <Styles.Section key={parentKey}>{renderBlock(element, key, renderConfig)}</Styles.Section>;
    }
    return <Styles.Section key={parentKey}>I'm UNKNOWN!</Styles.Section>;
  };

  return (
    <Styles.Container ref={docContainerRef} style={props.style}>
      {renderElement(props.document, 'root')}{' '}
    </Styles.Container>
  );
};
