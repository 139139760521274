import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { shareProgramSms, shareProgramFailed, shareProgramSuccess, shareProgramEmail } from '../actions/userActions';
import { IShareProgramEmailActionType, IShareProgramSmsActionType } from '../reducers/shareProgramReducer';
import { fetchPost, selfServiceApiCall } from '@visikon/utils/src/utils';
import { backbone, useBackbone } from '@visikon/backbone/src';

export const listProgramLanguages = (code: string) => `content/mitforlobPack/translations/${code}`;

function* handleShareEmailProgramSaga(action: Action<IShareProgramEmailActionType>) {
  const language = backbone.store.getState().country.languageCode;
  const body = { mailTo: action.payload.email, from: action.payload.name, languageCode: language };

  const programId = yield select((state) => state.resources.content?._id);

  try {
    const result: Response = yield call(fetchPost, `users/createReferencedUser/${programId}`, {}, body);
    if (result.ok) {
      yield put(shareProgramSuccess());
    } else {
      let error = 'Unknown error';
      const r = yield call([result, result.json]);
      if (r.error) {
        error = r.error;
      }
      yield put(shareProgramFailed(error));
    }
  } catch (e: any) {
    console.log("Couldn't share program: ", e.message);
    yield put(shareProgramFailed(e.message));
  }
}

function* handleShareSmsProgramSaga(action: Action<IShareProgramSmsActionType>) {
  const bearerToken = useBackbone.getState().authToken;
  const locale = backbone.store.getState().country.languageCode;
  const programId = yield select((state) => state.resources.content?._id);
  const body = { ...action.payload, locale, programId, bearerToken };

  try {
    const result: Response = yield call(selfServiceApiCall, `sms/shareProgram`, {
      body: JSON.stringify(body),
      method: 'POST',
    });
    if (result.ok) {
      yield put(shareProgramSuccess());
    } else {
      let error = 'Unknown error';
      const r = yield call([result, result.json]);
      if (r.error) {
        error = r.error;
      }
      yield put(shareProgramFailed(error));
    }
  } catch (e: any) {
    console.log("Couldn't share program: ", e.message);
    yield put(shareProgramFailed(e.message));
  }
}

export function* shareProgramSaga() {
  yield takeEvery(shareProgramEmail, handleShareEmailProgramSaga);
  yield takeEvery(shareProgramSms, handleShareSmsProgramSaga);
}
