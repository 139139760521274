import { CountryBadge } from './CountryBadge';
import * as React from 'react';
import styled from 'styled-components';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import { useEffect, useRef, useState } from 'react';
import { Country, Countries } from '@visikon/core-models/i18n/languages';
import { useCountry } from '../common/selectors';
import { changeCountry } from '../actions/resourcesActions';
import { useDispatch } from 'react-redux';
import color = Tokens.color;
import { i18n } from './i18n';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Unstyled } from '../components/Unstyled';

export const DialogWrapper = styled.dialog`
  background-color: ${Tokens.color.white};
  position: absolute;
  z-index: 1000;
  border: 1px solid ${color.border};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: none;
  width: 200px;
  max-height: 76vh;
  overflow: auto;
`;
export const LanguageSelectorButton = styled(Unstyled.Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-right: 4px;
`;

const CountrySelectionDialogWrapper = styled(DialogWrapper)`
  /* Ensure that all Country badges have the same size */
  display: flex;
  flex-direction: column;
`;

type Props = {
  countryCodes?: string[];
  onSelectCountry?: (country: Country) => void;
};

export const LanguageSelectorDialog = (props: Props) => {
  const { countryCodes } = props;
  const dialogRef = useRef<HTMLDialogElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const country = useCountry();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const clickOutSideHandler = (event: any) => {
    const rect = dialogRef.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }
    const isInDialog =
      rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width;
    if (!isInDialog) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', closeDialog);
    window.addEventListener('click', clickOutSideHandler);
    return () => {
      window.removeEventListener('resize', closeDialog);
      window.removeEventListener('click', clickOutSideHandler);
    };
  }, [dialogRef]);

  useEffect(() => {
    if (open && dialogRef.current && buttonRef.current) {
      const offset = 30;
      dialogRef.current.style.top = `${buttonRef.current.offsetTop + offset}px`;
      dialogRef.current.style.left = `${buttonRef.current.offsetLeft - 225}px`; // 225 is the width of the dialog
    }
  }, [open, dialogRef, buttonRef]);

  const countries = Countries.filter((country) => !countryCodes || countryCodes.find((countryCode) => countryCode === country.code)).sort((c1, c2) =>
    i18n(c1.language as TextKeys, c1.languageCode).localeCompare(i18n(c2.language as TextKeys, c2.languageCode)),
  );

  const closeDialog = () => {
    setOpen(false);
  };

  const dialogOpenHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  const onSelectCountry = (country: Country) => {
    if (dialogRef.current) {
      closeDialog();
    }
    if (countries.length === 1) {
      return;
    }
    if (props.onSelectCountry) {
      props.onSelectCountry(country);
    } else {
      dispatch(changeCountry(country));
    }
  };

  const countryBadges = countries.map((country, index) => (
    <Unstyled.Button key={`badge${country.name}${index}`} onClick={() => onSelectCountry(country)}>
      <CountryBadge country={country} showLegend={true} imgWidth={'32px'} />
    </Unstyled.Button>
  ));

  const ariaLabel = i18n('accessibility_language_selector', undefined, {
    $LANGUAGE: i18n(country.language as TextKeys),
  });

  return (
    <div style={{ position: 'relative' }}>
      <LanguageSelectorButton ref={buttonRef} onClick={dialogOpenHandler} aria-label={ariaLabel}>
        <CountryBadge country={country} imgWidth={'24px'} />
        <FontAwesomeIcon icon={faChevronDown} aria-hidden />
      </LanguageSelectorButton>
      {open && (
        <CountrySelectionDialogWrapper ref={dialogRef} open={open}>
          {countryBadges}
        </CountrySelectionDialogWrapper>
      )}
    </div>
  );
};
