import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { authenticateSuccessful, clearLoginProgress, setAuthCode, setStartCode } from '../actions/authActions';
import { IUserDataState } from './userDataReducer';
import { backbone } from '@visikon/backbone/src';
import { UserLoginRequest } from '../api/auth-api.models';

export interface IAuthRequest {
  readonly code: string;
  loggedIn?: boolean;
}

export type ReAuthenticateRequest = IAuthRequest & {
  authenticate: (payload: UserLoginRequest) => void;
};

export interface IAuthSuccessResult {
  token: string;
  userData: IUserDataState;
  referencedUser: boolean;
  activatedAt: number;
}

export interface IAuthError {
  readonly msg: string;
}

export interface IState {
  readonly error?: string;
  readonly staffLoginError?: string;
  readonly loginProcess?: ILoginInProcess;
}

export interface ILoginInProcess {
  authCode?: string;
  startCode?: string;
}

const initialState: IState = {
  loginProcess: undefined,
};

export function authReducer(state: IState = { ...initialState }, action: Action): IState {
  if (isType(action, authenticateSuccessful)) {
    backbone.setAuthToken(action.payload);

    return state;
  }

  if (isType(action, setAuthCode)) {
    return { ...state, loginProcess: { authCode: action.payload } };
  }

  if (isType(action, clearLoginProgress)) {
    return { ...state, loginProcess: undefined };
  }

  if (isType(action, setStartCode)) {
    return { ...state, loginProcess: { startCode: action.payload } };
  }

  return state;
}
