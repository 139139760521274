import * as React from 'react';
import * as Styles from '../components/video/VideoCard.styles';
import { LazyImage } from '@visikon/utils';
import { Spinner } from '../common/Spinner';
import styled from 'styled-components';

type Props = { thumbUrl?: string; children?: any; spinner?: boolean };

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  z-index: 1;
`;

function VideoThumbnail(props: Props) {
  return (
    <Styles.Thumbnail>
      {props.spinner && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      <LazyImage imageUrl={props.thumbUrl} />
      {props.children}
    </Styles.Thumbnail>
  );
}

export default VideoThumbnail;
