import { backbone } from '@visikon/backbone';
import { MitforlobContent, VideoPlaylist } from '../reducers/resourcesReducer';
import { fetchGet } from '@visikon/utils/src/utils';
import { LanguageCode, MyTreatmentProgramDescription } from '@visikon/core-models/content';
import { getTranslationFromArray, HasTranslations } from '@visikon/core-models/typeUtils';
import { WithId } from '@visikon/core-models/base';
import { getPreferredBrowserLanguage } from '@visikon/backbone/src/languages';
import { Content, getTranslation } from './resourcesSaga';
import { ProgramsStorage, storedProgramsFromObject } from '../local-storage/programs-storage';

export async function getAvailablePrograms() {
  const { availablePrograms, activeProgramId } = (await ProgramsStorage.get()) || {};

  let programs = availablePrograms?.programs;
  let timestamp = availablePrograms?.timestamp;
  if (availablePrograms === undefined || Date.now() - availablePrograms.timestamp > AVAILABLE_PROGRAM_TTL) {
    const programsResponse = (await fetchGet('content/mitforlobPack/list')) as MyTreatmentProgramDescription[];

    if (responseIsAuthError(programsResponse)) {
      throw new Error(programsResponse.error);
    }

    programs = programsResponse;
    timestamp = Date.now();
  }

  if (!programs || programs.length === 0) {
    throw new Error('No programs');
  }

  return storedProgramsFromObject({
    activeProgramId,
    availablePrograms: {
      programs,
      timestamp: timestamp || Date.now(),
    },
  });
}

export async function extractProgramTranslation(program) {
  const translation = await getProgramTranslation(program);

  if (!translation) {
    throw new Error('No translation');
  }

  const { language } = translation;
  const { name, content, contactText, department, organization, image } = translation.data;
  const programType = translation.data.programType ?? 'operation';

  const programContent: MitforlobContent = {
    _id: program._id,
    translations: program.translations.map((t) => t.language),

    language,
    name,
    programType,
    subName: translation.data.subName,
    image,
    anatomy: translation.data.anatomy,
    genericHeader: translation.data.genericHeader,
    footer: translation.data.footer,
    department: {
      name: department,
      organization: organization,
      logo: translation.data.logo,
      banner: translation.data.banner,
    },

    survey: getTranslation(content.survey, language),
    programVideos: getVideoPlaylistTranslation(content.programVideos, language),
    instructionVideos: getVideoPlaylistTranslation(content.instructionVideos, language),
    trainingVideos: getVideoPlaylistTranslation(content.trainingVideos, language),
    phamplets: getTranslation(content.phamplets, language),
    symptoms: getTranslation(content.symptoms, language),
    faq: getTranslation(content.faq, language),
    help: getTranslation(content.help, language),
    contactText: getTranslation(contactText, language),
    notifications: getTranslation(content.notifications, language),
  };
  // Remove undefined stuff. They interfere with tabs etc.
  const keys = Object.keys(programContent) as Array<keyof MitforlobContent>;
  keys.forEach((key) => programContent[key] === undefined && delete programContent[key]);

  console.log('Extracted translation', programContent);

  return programContent;
}

interface IAuthenticationError {
  error: 'Authentication Error';
}

function responseIsAuthError(response: any): response is IAuthenticationError {
  return response.error !== undefined && response.error === 'Authentication Error';
}

const AVAILABLE_PROGRAM_TTL = 1000; // 1 second

export interface WithTypeDescriptor {
  typeDescriptor: string;
}

export type Translation<T extends HasTranslations> = (T['translations'][0] & WithId & WithTypeDescriptor) | undefined;

function getVideoPlaylistTranslation(playList: VideoPlaylist | undefined, lang: LanguageCode) {
  if (!playList) {
    return undefined;
  }
  return addMatomoTrackingKey(getTranslation(playList, lang), playList);
}

function addMatomoTrackingKey(translation: Translation<VideoPlaylist>, playList: VideoPlaylist) {
  return {
    ...translation,
    data: translation?.data && {
      ...translation.data,
      sections: translation.data.sections?.map((section, i) => {
        return {
          ...section,
          videoList: section.videoList.map((videoInfo, j) => {
            const hasTranslationZeroEntry =
              playList.translations[0].data.sections.length >= i + 1 && playList.translations[0].data.sections[i].videoList.length >= j + 1;

            const video = videoInfo.video;
            video.trackingKey = hasTranslationZeroEntry ? playList.translations[0].data.sections[i].videoList[j].name : videoInfo.name;

            return {
              ...videoInfo,
              video,
            };
          }),
        };
      }),
    },
  } as Translation<VideoPlaylist>;
}

async function getProgramTranslation<T extends Content>(data: T | undefined): Promise<Translation<T>> {
  if (!data || !data.translations || data.translations.length < 1) {
    return undefined;
  }

  // const country = await getSelectedProgramLanguage();
  const country = backbone.store.getState().country;
  const preferredBrowserLanguage = getPreferredBrowserLanguage();

  // Language from local storage
  if (country) {
    const selectedTranslation = getTranslationFromArray(data.translations, country.languageCode);
    if (selectedTranslation?.language === country.languageCode) {
      console.log(`Picked translation '${selectedTranslation.language}' for country '${country}'`);
      return selectedTranslation;
    }
  }

  // Language from browser
  const browserBasedTranslation = getTranslationFromArray(data.translations, preferredBrowserLanguage);
  console.log(`Picked translation '${browserBasedTranslation.language}' for browser language '${preferredBrowserLanguage}'`);
  return browserBasedTranslation;
}
