import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IState } from '../../reducers/reducer';
import { ErrorMessage } from './LoginContainer.styles';
import { useRouteQueryParams } from '../../common/Router';
import { ErrorBoundary, ErrorBoundaryFallbackProps } from '../../common/ErrorBoundary';
import { useBackbone } from '@visikon/backbone';
import { getCountryByLanguageCode } from '@visikon/core-models/i18n/languages';
import { EmbedError } from '../embed/EmbedError';
import { Spinner } from '../../common/Spinner';
import { SessionsStorage } from '@visikon/utils/src/storage/WebStorage';
import { setSeenPrivacyPolicy } from '../../actions/userActions';

export const EMBED_ROUTE = '/embed';
export const EMBED_PATH = `${EMBED_ROUTE}/:programId/:language?`;
const DUMMY_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJkdW1teSIsInJldmlld2VyIjp0cnVlLCJ0eXBlIjoiRW5kVXNlciIsImlhdCI6MTUxNjIzOTAyMn0.OXAT1dTtvRTF0zv_a-54QJSF5oAbxuZ196S4LDJDsFc';

const CenterDiv = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
`;

function useEmbedParams() {
  const { programId, language } = useParams<{ programId: string; language: string }>();
  return { programId, languageCode: language || 'da' };
}

function EmbedErrorMessage({ error }: ErrorBoundaryFallbackProps) {
  return <ErrorMessage>{error?.message ?? 'An unknown error has accured'}</ErrorMessage>;
}

export function EmbedRoute() {
  return (
    <CenterDiv>
      <ErrorBoundary fallback={<EmbedErrorMessage />}>
        <EmbedNavigator />
      </ErrorBoundary>
    </CenterDiv>
  );
}

export function EmbedNavigator() {
  const error = useSelector((state: IState) => state.resources.error);
  const query = useRouteQueryParams();
  const { programId, languageCode } = useEmbedParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const live_path = query.get('live_path') ?? undefined;
    const preview_path = query.get('preview_path') ?? undefined;
    const is_reviewer = Boolean(query.get('reviewer'));

    if (!live_path && !preview_path) {
      throw new EmbedError('Nither preview or live path provided');
    }

    useBackbone.setState(() => ({
      embedInfo: {
        live_path,
        preview_path,
        programId,
      },
      storageApi: SessionsStorage,
      authToken: DUMMY_TOKEN,
      user: {
        _id: 'dummy',
        reviewer: is_reviewer,
        type: 'EndUser',
        referencedUser: false,
        activatedAt: 0, // Set this low to ensure survey will be shown
      },
      country: getCountryByLanguageCode(languageCode),
    }));

    // Mark privacy policy as seen
    dispatch(setSeenPrivacyPolicy(new Date()));

    history.replace('/' + programId);
  }, [query.toString()]);

  if (error) throw error;

  return <Spinner />;
}
