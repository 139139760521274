import { Content } from '@visikon/core-models';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { MediaFileUtils } from '@visikon/utils';
import PhampletPlaceholder from '../../images/document_placeholder.png';
import { PhampletUrlType, isUrlType } from '../../reducers/resourcesReducer';
import { PHAMPLET_WEB_ROUTE } from './PhampletWebRoute';
import { ReactComponent as documents } from '../../images/svg/document.svg';
import { SvgIcon } from '../../images/svgIcon';
import { Chevron } from '../../components/styled/Chevron';
import { DetailsCard } from '../../components/styled/DetailsCard';
import { ContentConfig } from '../../common/ContentConfig';
import { backbone } from '@visikon/backbone';

const CardName = styled.div`
  display: flex;
  font-weight: 600;
  flex: 1;
  user-select: auto;
  padding-left: 12px;
  z-index: 1;
`;

const PhampletDetailsCard = styled(DetailsCard)`
  margin-top: 20px;
  padding: 24px;
  height: unset;

  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    height: unset;
  }
`;

interface IProps {
  textTitle: string;
  textIndex: number;
  text?: Content.Text | PhampletUrlType;
  image?: Content.Image;
  fieldKey?: keyof ContentConfig;
}

export function PhampletCardComponent(props: IProps) {
  const { textTitle, text, textIndex, image, fieldKey } = props;
  const history = useHistory();
  const programId = backbone.store.getState().program?.id;

  let imageUri = PhampletPlaceholder;
  if (image) {
    imageUri = MediaFileUtils.getUriForImage(image._id, 'da');
  }

  if (text === undefined) {
    return null;
  }
  const handleOnClick = () => {
    let route = `/${programId}/documents/${text._id}`;
    if (isUrlType(text)) {
      const WEB_ROUTE = PHAMPLET_WEB_ROUTE.split(':textIndex')[0];
      route = `/${programId}${WEB_ROUTE}${textIndex}`;
    }
    console.log('route', route);
    history.push(route);
  };

  return (
    <PhampletDetailsCard onClick={handleOnClick}>
      {isUrlType(text) ? <SquareAspectImage src={imageUri} /> : <SvgIcon aria-hidden Icon={documents} width={32} className={'navTabs-active'} />}
      <CardName>{textTitle}</CardName>
      <Chevron isOpen={false} style={{ paddingLeft: '12px', paddingRight: '24px' }} />
    </PhampletDetailsCard>
  );
}

export const SquareAspectImage = ({ src, height }: { src: string; height?: number }) => {
  const [imgPadding, setImgPadding] = React.useState(0);
  const desiredHeight = height || 32;

  React.useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      // Calculate the width based on the aspect ratio and desired height
      const calculatedWidth = (img.naturalWidth / img.naturalHeight) * desiredHeight;
      if (calculatedWidth < 32) {
        setImgPadding((32 - calculatedWidth) / 2);
      }
    };
  }, [src]);

  return <img src={src} aria-hidden height={desiredHeight} style={{ paddingLeft: imgPadding, paddingRight: imgPadding }} />;
};
