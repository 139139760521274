import { create } from 'zustand';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { getValidContentKeys, useFirstValidContentConfig, useIsFirstMenuItem, useLanguage } from '@visikon/mytreatment/src/common/selectors';
import { ContentConfig, contentConfig } from '@visikon/mytreatment/src/common/ContentConfig';
import { useSelector } from 'react-redux';
import { IState } from '@visikon/mytreatment/src/reducers/reducer';
import { usePrevious } from '@visikon/utils';
import { i18n } from '@visikon/mytreatment/src/internationalization/i18n';
import { TextKeys } from '@visikon/core-models/i18n/translations';

interface LocationStore {
  paths: string[];
  level: number;
  prevLevel?: number;
  contentTitle?: string;
  navBackTitle?: string;
}

const store = create<LocationStore>(() => ({
  paths: [],
  level: 0,
}));

function useRecordPath() {
  const location = useLocation();
  const path = location?.pathname;

  React.useEffect(() => {
    store.setState((state) => {
      let newPaths = [...state.paths, path];

      // Ensure we only keep the last 6 paths
      if (newPaths.length > 6) {
        newPaths = newPaths.slice(-6);
      }

      return { paths: newPaths };
    });
  }, [path]);
}

function useTabTitle(tab: keyof ContentConfig) {
  const language = useLanguage();
  const translationKey = contentConfig[tab]?.translationKeys.page;
  const title = i18n(translationKey as TextKeys, language);

  return title;
}

// Used to calculate accurate level for content containers based on resources.content.help
function findContentLevel(content, id, level = 0) {
  if (content?._id === id) {
    return level;
  }

  const isVideolist = content?.typeDescriptor === 'videoList';
  const isNestedContentContainer = content?.typeDescriptor === 'contentContainer' && content.data && Array.isArray(content.data.content);

  if (isVideolist) {
    for (let i = 0; i < content.data.sections.length; i++) {
      for (let j = 0; j < content.data.sections[i].videoList.length; j++) {
        const video = content.data.sections[i].videoList[j].video;

        if (video._id === id) {
          return level;
        }
        const result = findContentLevel(video, id, level + 1);
        if (result !== -1) {
          return result;
        }
      }
    }
  }

  if (isNestedContentContainer) {
    for (let i = 0; i < content.data.content.length; i++) {
      const result = findContentLevel(content.data.content[i], id, level + 1);
      if (result !== -1) {
        return result;
      }
    }
  }

  return -1;
}

function getContentById(content, id) {
  // Base case
  if (content?._id === id) {
    return content;
  }

  if (!content?.data || !Array.isArray(content.data.content)) {
    return null;
  }

  // Recurse through nested content containers
  for (let i = 0; i < content.data.content.length; i++) {
    const result = getContentById(content.data.content[i], id);
    if (result !== null) {
      return result;
    }
  }

  // Content with specified _id not found
  return null;
}

function findParentContent(content, id, parent = null) {
  let closestContentContainer = parent;

  if (content?._id === id) {
    return parent;
  }

  const isVideolist = content?.typeDescriptor === 'videoList';
  const isNestedContentContainer = content?.typeDescriptor === 'contentContainer' && content.data && Array.isArray(content.data.content);

  if (isVideolist) {
    for (let i = 0; i < content.data.sections.length; i++) {
      for (let j = 0; j < content.data.sections[i].videoList.length; j++) {
        const video = content.data.sections[i].videoList[j].video;

        if (video._id === id) {
          return closestContentContainer;
        }
        const result = findParentContent(video, id, content);
        if (result !== null) {
          return result;
        }
      }
    }
  }

  if (isNestedContentContainer) {
    for (let i = 0; i < content.data.content.length; i++) {
      const result = findParentContent(content.data.content[i], id, content);
      if (result !== null) {
        return result;
      }
    }
  }

  return null;
}

function getLevelFromResources(content, isMobileOrHasNoMenu?: boolean) {
  const contentKeys = useSelector(getValidContentKeys);
  const isFirstMenuItem = useIsFirstMenuItem();
  const path = location?.pathname;
  const prevPath = usePrevPath();
  const firstContentConfig = useFirstValidContentConfig();
  const contentIdFromPath = path.includes('content') || path.includes('videoplayer') ? path?.split('/').pop() : null;

  const keysInContent = content ? Object.keys(content).filter((key) => contentKeys.includes(key)) : [];
  const filteredData = keysInContent.map((key) => {
    const path = contentConfig[key]?.path;
    const programContent = content[key];
    return { path, programContent };
  });
  const pathEqualsProgramPath = filteredData.some((subPath) => path?.includes(subPath.path));
  const level2Paths = ['/videoplayer/', '/documents/'];
  const pathEqualsLevel2Path = level2Paths.some((subPath) => path.includes(subPath));

  const comingFromFirstMenuItem = prevPath?.slice(1) === firstContentConfig?.path;
  const comingFromRegularVideoList = ['programVideos', 'instructionVideos'].some(
    (videoPath) => prevPath?.slice(1) === contentConfig[videoPath]?.path,
  );

  const CCModifier = isMobileOrHasNoMenu ? 1 : 0; // +1 to compensate for the help container;
  const CCLevel = findContentLevel(content?.help, contentIdFromPath) + CCModifier;

  const levelMinus1 = path === '/home';
  const level0 = isMobileOrHasNoMenu ? isFirstMenuItem : pathEqualsProgramPath && !pathEqualsLevel2Path;
  const level1Check = isMobileOrHasNoMenu
    ? (pathEqualsProgramPath && !pathEqualsLevel2Path) || path.includes('share')
    : (pathEqualsLevel2Path && CCLevel < 2) || comingFromRegularVideoList || path.includes('share');

  const level1 = !level0 && (level1Check || (isMobileOrHasNoMenu && comingFromFirstMenuItem));
  const level2 = !level1 && ((pathEqualsLevel2Path && CCLevel < 3) || (isMobileOrHasNoMenu && comingFromRegularVideoList));

  let level;

  switch (true) {
    case levelMinus1:
      level = -1;
      break;
    case level0:
      level = 0;
      break;
    case level1:
      level = 1;
      break;
    case level2:
      level = 2;
      break;
    default:
      level = CCLevel;
  }
  return level;
}

// A listener function that updates the nav level based on navLink action and content tree
function updateNavLevel(isMobileOrHasNoMenu?: boolean) {
  const isFirstMenuItem = useIsFirstMenuItem();
  const navLink = useSelector((state: IState) => state.resources.navLink);
  const resources = useSelector((state: IState) => state.resources);
  const prevLevel = usePrevious(store.getState().level);
  const levelFromContent = getLevelFromResources(resources.content, isMobileOrHasNoMenu);

  React.useEffect(() => {
    store.setState((state) => {
      const navLinkPaths = ['/symptoms', '/questions'];
      const pathEqualsNavlinkPath = navLinkPaths.some((subPath) => navLink?.path?.includes(subPath));

      const level1 = pathEqualsNavlinkPath && isFirstMenuItem;
      const level2 = pathEqualsNavlinkPath && !level1;

      let level;
      // Overwriting levels if coming from navLink
      switch (true) {
        case level1:
          level = 1;
          break;
        case level2:
          level = 2;
          break;
        default:
          level = levelFromContent;
      }

      if (level !== prevLevel) {
        return { level, prevLevel };
      }

      return { level };
    });
  }, [navLink, prevLevel]);
}

// A listener function that updates the nav title based on the current level
function updateNavTitle(isMobileOrHasNoMenu?: boolean) {
  const { level } = store.getState();
  const navLink = useSelector((state: IState) => state.resources.navLink);
  const prevPath = usePrevPath();
  const path = location?.pathname;

  const resources = useSelector((state: IState) => state.resources);
  const tabTitle = {
    help: useTabTitle('help'),
    instructions: useTabTitle('instructionVideos'),
    phamplets: useTabTitle('phamplets'),
    videos: useTabTitle('programVideos'),
  };

  React.useEffect(() => {
    const contentIdFromPath = path.includes('content') || path.includes('videoplayer') ? path?.split('/').pop() : null;

    const level2Paths = ['/videoplayer/', '/documents/'];
    const pathEqualsLevel2Path = (index: number) => path.includes(level2Paths[index]);

    const pathEqualsCCPath = [contentConfig['help'].path, 'content'].some((subPath) => path?.includes(subPath));
    const navLinkPaths = ['/symptoms', '/questions'];
    const pathEqualsNavlinkPath = navLinkPaths.some((subPath) => navLink?.path?.includes(subPath));

    const titleFromContent = getContentById(resources.content?.help, contentIdFromPath)?.data?.title;
    const prevTitleFromContent = findParentContent(resources.content?.help, contentIdFromPath)?.data?.title;
    const prevPageTitleFromContent = findParentContent(resources.content?.help, contentIdFromPath)?.data?.pageTitle;

    const ccTitle = isMobileOrHasNoMenu ? level > 2 && prevTitleFromContent : level > 1 && prevTitleFromContent;

    const accessingContentContainer = resources.content?.help && pathEqualsCCPath;
    const helpTabTitle = accessingContentContainer ? prevPageTitleFromContent || tabTitle['help'] : null;
    const instructionsTabTitle = pathEqualsLevel2Path(0) ? tabTitle['instructions'] : null;
    const videosTabTitle = prevPath?.includes(contentConfig['programVideos'].path) ? tabTitle['videos'] : null;
    const phampletsTabTitle = pathEqualsLevel2Path(1) ? tabTitle['phamplets'] : null;

    let navBackTitle = ccTitle;

    if ((isMobileOrHasNoMenu && level !== 2) || (!isMobileOrHasNoMenu && level !== 1)) {
      store.setState((state) => ({
        contentTitle: titleFromContent,
        navBackTitle,
      }));
      return;
    }
    if (!pathEqualsNavlinkPath) {
      navBackTitle = helpTabTitle || phampletsTabTitle || videosTabTitle || instructionsTabTitle;
    }

    store.setState((state) => ({
      contentTitle: titleFromContent,
      navBackTitle,
    }));
  }, [level, path]);
}

function usePaths() {
  return store((state) => state.paths);
}
function useNavLevel() {
  return store((state) => state.level);
}
function useContentTitle() {
  return store((state) => state.contentTitle);
}
function useNavBackTitle() {
  return store((state) => state.navBackTitle);
}
function usePrevLevel() {
  return store((state) => state.prevLevel);
}
function usePrevPath() {
  return store((state) => {
    const prevPath = state.paths[state.paths.length - 2];
    return prevPath;
  });
}

function useMenuPath() {
  const firstContentConfig = useFirstValidContentConfig();
  return firstContentConfig?.path;
}

export const LocationHooks = {
  useRecordPath,
  usePaths,
  useNavLevel,
  updateNavLevel,
  updateNavTitle,
  usePrevPath,
  usePrevLevel,
  useContentTitle,
  useNavBackTitle,
  useMenuPath,
};
