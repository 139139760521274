import * as SizeTokens from './size';
import * as ColorTokens from './colors';
import * as TextTokens from './text';

export namespace Tokens {
  export const colors = ColorTokens.Tokens.colors;
  export const color = ColorTokens.Tokens.color;
  export const text = TextTokens.Tokens.text;
  export const size = SizeTokens.Tokens.size;
}
