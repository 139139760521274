export enum CustomDimensions {
  APP_VERSION = 1,
  /** @deprecated Is not setup as an custom Dimension in Matomo anymore, Remove? */
  LANGUAGE = 2,
  PROGRAM = 3,
  DEPARTMENT = 4,
  ORGANIZATION = 5,
  CLIENT_TYPE = 6,
  USER_TYPE = 7,
  REFERENCED_USER = 8,
}
