import { CI_APPFLOW_BUILD } from './ci';

export enum EnvironmentName {
  Local = 'local',
  QA = 'qa',
  Prod = 'prod',
}

export function getEnvName(): EnvironmentName {
  let environment = process.env.REACT_APP_ENVIRONMENT as EnvironmentName | undefined;

  if (!environment) {
    throw new Error('Environment Variable REACT_APP_ENVIRONMENT not set');
  }

  // If building in CI appflow
  if (CI_APPFLOW_BUILD) {
    environment = CI_APPFLOW_BUILD as EnvironmentName;
  }

  return environment;
}
