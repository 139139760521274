import { Button } from '@visikon/spine/src';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { tracker } from '@visikon/tracker/src';
import { WindowSize, useWindowSizeMatches } from '@visikon/utils/src/responsive';
import * as React from 'react';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import { WithFooter } from '../../components/Footer';
import { FlexWrapper } from '../../components/styled/ContentAreaContainers';
import { I18N, SetDocumentTitle } from '../../internationalization/AppText';
import { i18n } from '../../internationalization/i18n';
import { DocumentWrapper, PrintButtonWrapper } from './PhampletSharedComponents';
import { ContentContainer } from '../../components/ContentContainerSection';

interface IProps {
  webData: string;
  title?: string;
}

const WebDataWrapper = styled.div`
  padding: 30px;

  h1,
  h2 {
    font-weight: normal;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  div.video-block div.video div.video-image {
    display: none;
  }

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    img,
    iframe {
      max-width: 100%;
      height: auto;
    }
  }
  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    padding: 16px;
  }
`;

const Space = styled.div<{ height: number }>`
  min-height: ${(p) => p.height}px;
`;

export const PhampletWebView = ({ webData, title }: IProps) => {
  const docContainerRef = React.useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    tracker.trackEvent('Phamplets', 'printClicked', title);
  };

  const printButton = (
    <PrintButtonWrapper>
      <Button variant="outline" onClick={handlePrint}>
        <I18N text="print_document" />
      </Button>
    </PrintButtonWrapper>
  );

  return (
    <FlexWrapper>
      <SetDocumentTitle text="" textNoTranslation={`${i18n('pamphlets')} - ${title}`} />
      <ContentContainer ref={docContainerRef}>
        <DocumentWrapper>
          <WithFooter>
            <ReactToPrint trigger={() => printButton} content={() => docContainerRef.current} />
            <WebDataWrapper>
              {title && <h1>{title}</h1>}
              {webData && <div dangerouslySetInnerHTML={{ __html: webData }} />}
            </WebDataWrapper>
          </WithFooter>
        </DocumentWrapper>
        <Space height={40} />
      </ContentContainer>
    </FlexWrapper>
  );
};
