import { I18N, i18N } from '../../internationalization/AppText';
import { useLanguage } from '../../common/selectors';
import { tracker } from '@visikon/tracker/src';
import { useHistory } from 'react-router-dom';
import { useBackbone } from '@visikon/backbone';
import { Button, Dialog, DialogActionContainer, DialogBody, DialogHeader } from '@visikon/spine';
import { useEffect, useRef } from 'react';

const SelfServicePopup = () => {
  debugger;
  const dialogRef = useRef<HTMLDialogElement>(null);
  const selfServiceUrl = useBackbone((state) => state.hosts.selfService);
  const lang = useLanguage();
  const history = useHistory();

  const handleConfirm = () => {
    window.location.href = `${selfServiceUrl}${lang}/login`;
  };

  useEffect(() => {
    dialogRef.current?.showModal();
  }, []);

  return (
    <Dialog _ref={dialogRef} dismissable={false}>
      <DialogHeader>{i18N('self_service_redirect_title')}</DialogHeader>
      <DialogBody>
        <p style={{ fontSize: '20px', whiteSpace: 'pre-wrap' }}>{i18N('self_service_redirect')}</p>
      </DialogBody>
      <DialogActionContainer>
        <Button size="small" onClick={handleConfirm}>
          <I18N text="continue" />
        </Button>
        <Button
          variant="ghost"
          size="small"
          onClick={() => {
            tracker.trackEvent('Auth', 'logout');
            history.push('/logout');
          }}
        >
          <I18N text="log_out" />
        </Button>
      </DialogActionContainer>
    </Dialog>
  );
};

export default SelfServicePopup;
