import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { DetailsCard } from '../../components/styled/DetailsCard';

export const CardDescription = styled.div``;

export const ContentCard = styled(DetailsCard)`
  position: relative;
  margin-bottom: 15px;
  width: 100%;
  min-height: 137px;
  height: 100%;
`;

export const Divider = styled.div`
  position: absolute;
  border-right: 1px solid #e6e6e6;
  height: 100%;
  top: 0;
  left: 65%;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    left: 60%;
  }

  @media all and (max-width: ${DeprecatedTokens.breakPointMid}) {
    left: 45%;
  }
  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    left: 50%;
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  padding: 10px;
`;
export const ContentTitle = styled.div`
  font-weight: 700;
`;
export const ContentDescription = styled.div`
  font-weight: 300;
  margin-top: 10px;
`;

export const Card = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin: 15px;
  cursor: pointer;
  padding: 15px;
  border-radius: 3px;
  &:hover {
    background-color: ${DeprecatedTokens.background};
  }
  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    min-height: auto;
    width: -webkit-fill-available;
    padding: 8px 0;
    margin: 10px 0;
  }

  a {
    align-items: center;
    text-decoration: none;
    color: black;
    display: inherit;
    width: 100%;

    img {
      max-height: 120px;

      @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
        max-height: 90px;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 75%;
  @media all and (max-width: ${DeprecatedTokens.breakPointMid}) {
    width: 100%;
  }
`;
