export namespace Tokens {
  export const text = {
    family: {
      default: 'Open Sans, sans-serif',
      monospace: 'DroidSansMono, monospace',
    },

    weight: {
      bold: 700,
      semibold: 600,
      regular: 400,
    },
  };
}
