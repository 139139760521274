import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { Link } from 'react-router-dom';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import color = Tokens.color;

export const Container = styled.div`
  position: relative;
`;

export const LineDivider = styled.div`
  padding-top: 24px;
  border-bottom: 1px solid ${color.border};
`;

export const DepartmentHeader = styled.h2`
  font-weight: normal;
  font-size: 1.2em;
  padding: 0;
  margin: 0;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    font-size: 1em;
  }
`;

export const HeaderProgramSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2 1 0%;
  justify-content: flex-start;
  padding-bottom: 15px;

  h3 {
    color: #455a64;
    font-weight: 400;
    font-size: 1em;
  }

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    justify-content: center;
    padding-top: 0;
  }
`;

export const HeaderOrganizationSection = styled.div`
  min-height: 100px;
  //flex: 1 1 20%;
  padding: 22px 0 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    text-align: left;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1em;
    min-height: 50px;
    order: -1;
    flex-direction: row;
    padding-top: 22px;

    & > div {
      display: none;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
`;

export const OrganizationLogo = styled.img`
  max-height: 50px;
  display: inline-block;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    height: 100%;
    width: 100%;
    max-width: 70px;
  }
`;
export const Header = styled.header`
  display: flex;
  flex-direction: column;
  padding-right: 15px;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    display: flex;
    flex-direction: column;
  }
`;

export const SubHeader = styled.h3`
  margin: 0.2em 0;
`;

export const OperationDateLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const OperationDateLabel = styled(Link)`
  text-decoration: none;
  color: ${Tokens.color.primary};
`;

export const EditTreatmentDateButton = styled.button`
  padding: 8px;

  border-radius: 50%;
  border-width: unset;
  border-style: unset;
  border-color: unset;

  background-color: #2a6ead40;
  color: #2a6dad;
  @media all and (max-width: ${Sizes.breakPointSmall}) {
    margin-right: 16px;
  }
`;
