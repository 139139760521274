import {Content} from '@visikon/core-models';

export function mapLanguageToLocale(language: Content.LanguageCode): string {
    switch (language) {
        // We need a way to determine the region code to use for a locale
        // In core we don't account for different regions having different date formats
        // Eg US English want month first while the british want's the day first
        // US: Jannuary 23
        // UK: 23 Jannuary
        // Because most of our userbase are in european countries and the british date format
        // aligns with the rest of europe assume english will be british english for now
        case 'en':
            return 'en-GB'
        case 'no':
            return 'nb';
        default:
            return language;
    }
}
