import * as React from 'react';
import { FaqComponent } from './faqs/FaqComponent';
import { VideoScreenComponent } from '../components/video/VideoScreen';
import { SymptomsComponent } from './symptoms/SymptomsComponent';
import { PhampletScreenComponent } from './phamplets/PhampletScreenComponent';
import { ProgramInfo, useContent, useProgramInformationFromContent, useWholeContent } from '../common/selectors';
import { GenericScreenHeader, useShowProgramMenu } from './GenericScreenHeader';
import { ContentContainerScreenComponent } from './content/ContentContainerScreenComponent';
import { ContentConfig } from '../common/ContentConfig';
import { ContentContainerKey } from '@visikon/core-models/models/content/content-containers';
import styled from 'styled-components/macro';
import { logger } from '@visikon/utils/src/logger';
import { Wrap, usePromise } from '../common/utils';
import { LocationHooks } from '@visikon/backbone';
import { Fader } from '../common/Fader';
import { MOBILE_SIZE, useWindowSizeMatches } from '@visikon/utils/src/responsive';

const OverFlowWrapper = styled.div`
  overflow: auto;
  height: 100%;
`;

export function GenericScreenComponent(props: { fieldKey: keyof ContentConfig; path: string }) {
  const { fieldKey, path } = props;
  const wholeContent = useWholeContent();
  const content = useContent(fieldKey);
  const programInformation = usePromise<ProgramInfo>(useProgramInformationFromContent, wholeContent);
  let result;

  const showProgramMenu = useShowProgramMenu();
  const [headerLoaded, setHeaderLoaded] = React.useState(false);
  const prevLevel = LocationHooks.usePrevLevel();
  const fromHomeScreen = prevLevel === -1;
  const isMobileView = useWindowSizeMatches(MOBILE_SIZE);

  const handleHeaderLoad = () => {
    setHeaderLoaded(true);
  };

  React.useEffect(() => {
    if (content) {
      handleHeaderLoad();
    }
  }, [content]);

  const componentMap = {
    videoList: VideoScreenComponent,
    faq: FaqComponent,
    symptom: SymptomsComponent,
    document: PhampletScreenComponent,
    [ContentContainerKey]: ContentContainerScreenComponent,
  };

  if (!showProgramMenu || headerLoaded) {
    const ContentTypeComponent = componentMap[content?.typeDescriptor];
    const endpath = getEndPath(path);

    if (ContentTypeComponent) {
      result = (
        <ContentTypeComponent fieldKey={fieldKey} disableFavorites={endpath === 'video' || programInformation?.programType === 'information'}>
          <Wrap expression={fromHomeScreen && isMobileView} decorator={(children) => <Fader seconds={2}>{children}</Fader>}>
            <GenericScreenHeader />
          </Wrap>
        </ContentTypeComponent>
      );
    } else {
      const errorMessage = `Unknown content type for '${fieldKey}`;
      result = <div>{errorMessage} </div>;
      logger.error(errorMessage);
    }
  }
  return <OverFlowWrapper>{result}</OverFlowWrapper>;
}

export function getEndPath(path) {
  const programIdString = '/:programId/';

  if (path.includes(programIdString)) {
    return path.split(programIdString)[1];
  } else {
    console.error(`Error: Path does not include '${programIdString}'`);
    return null;
  }
}
