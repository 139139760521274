import { DialogOrchestrator, DialogPrioity, HookableRender, useDialogContext } from '@visikon/backbone/src';
import { Button, Dialog, DialogActionContainer, DialogBody } from '@visikon/spine/src';
import { useDispatch } from 'react-redux';
import { resetSeenPrivacyPolicy } from '../../actions/userActions';
import { TreatmentCardStorage } from '../../local-storage/treatment-card-storage';
import { useEffect, useRef } from 'react';
import { useEventHandler } from '@visikon/utils';

export const DEBUG_ROUTE = '/debug';
export function DebugScreen() {
  return (
    <div style={{ padding: '0 15px', overflow: 'auto' }}>
      <h1>Bug me not!</h1>
      <Button
        onClick={() => {
          console.log('queue');

          DialogOrchestrator.queue(
            {
              prioity: DialogPrioity.NORMAL,
              component: TestDialog,
              componentProps: { id: '#1' },
            },
            {
              prioity: DialogPrioity.HIGH,
              component: TestDialog,
              componentProps: { id: '#2' },
            },
          );
        }}
      >
        Toggle Test Dialog
      </Button>
      <PrivacyPolicyDebugOptions />
      <TreatMentCardDebugOptions />
      <HookableRender identifier="screen/debug" />
    </div>
  );
}

function TreatMentCardDebugOptions() {
  async function handler() {
    await TreatmentCardStorage.clear();
  }

  return <Button onClick={handler}>Clear TreatmentCard dismissed programs</Button>;
}

function PrivacyPolicyDebugOptions() {
  const dispatch = useDispatch();

  const handler = () => {
    dispatch(resetSeenPrivacyPolicy());
  };

  return <Button onClick={handler}>Mark Privacy Policy Unseen</Button>;
}

function TestDialog({ id }: { id: string }) {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const dialog = useDialogContext();

  // When the dialog orchestrator mounts the dialog, show it
  useEffect(() => {
    console.debug('Showing dialog with id', id, dialogRef.current);

    // debugger;
    dialogRef.current?.showModal();
  }, []);

  // When the dialog HTML element is closed
  // also close the dialog via the dialog orchestrator
  useEventHandler(dialogRef, 'close', () => {
    dialog.close();
  });

  return (
    <Dialog _ref={dialogRef}>
      <DialogBody>
        <p>Dialog id {id}</p>
        Ved brug af Mit Forløb® samler vi oplysninger om dit besøg ved hjælp af egne cookies for at måle hvordan Mit Forløb® bliver brugt, så vi kan
        udvikle indhold og funktioner. Data du indtaster, bliver gemt anonymiseret. <a href="https://visikon.com">Læs vores datapolitik her</a>
      </DialogBody>
      <DialogActionContainer>
        <Button size="small" onClick={dialog.close}>
          Dialog button
        </Button>
        <Button size="small" variant="outline" onClick={dialog.close}>
          Another button
        </Button>
      </DialogActionContainer>
    </Dialog>
  );
}
