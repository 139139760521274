import * as React from 'react';
import Modal from 'react-modal';
import './ModalStyles.css';
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import * as Styles from '../HeaderBar.styles';
import { useNavLinkHelper } from '../../common/NavigationHelper';
import { useSelector } from 'react-redux';
import { IState } from '../../reducers/reducer';
import { MitforlobContent } from '../../reducers/resourcesReducer';
import { TitleComponent } from '../HeaderBar';

interface MobileContentWrapperProps {
  content: JSX.Element;
  isOpen?: boolean;
  handleBackClick(): void;
}

export function MobileContentWrapper(props: MobileContentWrapperProps) {
  const { handleBackClick, isOpen } = props;
  const content = props.content ? React.cloneElement(props.content, { handleBackClick }) : null;

  return (
    <Modal
      className="ReactModal__Content"
      overlayElement={(props, contentElement) => <aside {...props}>{contentElement}</aside>}
      isOpen={!!isOpen}
      ariaHideApp={false}
      style={{
        content: { top: '0', left: '0', right: '0', bottom: '0' },
        overlay: { backgroundColor: 'none', zIndex: 1002 },
      }}
    >
      <IonPage>
        <IonHeader>
          <IonToolbar mode="md">
            <Styles.Container aria-label="Top navigation" isHidden={false} showBack={true}>
              <Styles.LeftCell>
                <BackTitle backClick={handleBackClick} />
              </Styles.LeftCell>
            </Styles.Container>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div style={{ padding: '1rem' }}>{content}</div>
        </IonContent>
      </IonPage>
    </Modal>
  );
}

function BackTitle(props: { backClick?: () => void }) {
  const { homeNavLink, updateNavLink } = useNavLinkHelper();
  const content = useSelector<IState, MitforlobContent | undefined>((state) => state.resources.content);

  function goBack() {
    props.backClick?.() ?? updateNavLink(homeNavLink);
  }

  return <TitleComponent backTitle={content?.name} onBackClick={goBack} showBack />;
}
