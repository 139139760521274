import styled from 'styled-components';
import { DetailsCardContent } from '../../components/styled/DetailsCard';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';

export const FaqDetails = styled(DetailsCardContent)`
  & > div {
    &:first-child {
      border-right: 1px solid ${DeprecatedTokens.border};
    }
  }

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    flex-direction: column;
    margin: 8px 0;

    & > div {
      border-radius: 0 0 8px 8px;

      &:first-child {
        font-weight: bold;
        margin-right: 0;
        padding: 4px;
        min-width: auto;
        max-width: none;
        border-bottom: 1px solid ${(props) => DeprecatedTokens.border};
        border-radius: 8px 8px 0 0;
      }
    }
  }
`;
