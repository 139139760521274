import { useEffect, RefObject } from 'react';

export function useEventHandler<Element extends HTMLElement, EventName extends keyof HTMLElementEventMap>(
  ref: RefObject<Element>,
  eventName: EventName,
  // listener: Parameters<Element['addEventListener']>[1],
  listener: (this: HTMLElement, ev: HTMLElementEventMap[EventName]) => any,
  enabled: boolean = true,
) {
  useEffect(() => {
    if (!enabled) return;
    ref.current?.addEventListener(eventName, listener);

    return () => {
      ref.current?.removeEventListener(eventName, listener);
    };
  }, [enabled]);
}
