import { useEffect } from 'react';

const themeColorMetaTag = document.getElementById('document-meta-theme-color')!;
const defaultThemeColor = themeColorMetaTag?.getAttribute('content')!;

type EffectComponentFunction<Props extends object> = (props: Props) => () => void;
function EffectComponentFactory<Props extends object>(effect: EffectComponentFunction<Props>) {
  return (props: Props) => {
    useEffect(() => effect(props ?? {}));

    return null;
  };
}

function InternalSetThemeColor(color: string) {
  themeColorMetaTag.setAttribute('content', color);
}

function ResetDocumentThemeColor() {
  InternalSetThemeColor(defaultThemeColor);
}

/**
 * Set the theme-color for the page in the HTML head to a given color.
 *
 * On component unmount the color will be reset to the default color
 */
export const SetDocumentThemeColor = EffectComponentFactory<{ color: string }>((props) => {
  InternalSetThemeColor(props.color);

  return () => {
    ResetDocumentThemeColor();
  };
});

/**
 * Adds addtional theme-color for the page in the HTML head with a specific media query
 *
 * On component unmount the addtional theme color will be removed
 */
// Doesnt work as expected right now. May beable to get it working later. ~MS
// export const AddConditionalDocumentThemeColor = EffectComponentFactory<{ mediaQuery: string; color: string }>((props) => {
//   const elm = document.createElement('meta');
//   elm.setAttribute('media', props.mediaQuery);
//   elm.setAttribute('content', props.color);

//   themeColorMetaTag.insertAdjacentElement('afterend', elm);

//   return () => {
//     elm.remove();
//   };
// });
