import React from 'react';
import { Styles } from '../styles';

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  size?: 'large' | 'medium' | 'small' | 'tiny';
  weight?: 'bold' | 'semibold' | 'regular';
  muted?: boolean;
}

export function Span(props: Props) {
  const { size, weight, muted } = props;

  return (
    <span className={Styles.text.format({ size, weight, muted })} {...props}>
      {props?.children}
    </span>
  );
}

export function Div(props: Props) {
  const { size, weight, muted } = props;

  return (
    <div className={Styles.text.format({ size, weight, muted })} {...props}>
      {props?.children}
    </div>
  );
}

export function P(props: Props) {
  const { size, weight, muted } = props;

  return (
    <p className={Styles.text.format({ size, weight, muted })} {...props}>
      {props?.children}
    </p>
  );
}
