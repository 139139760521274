export const protocol = window.location.protocol.startsWith('http') ? window.location.protocol : 'http'; // Handle `capacitor://` when running locally

export const backends: Record<string, string> = {
  // local: `${protocol}//${location.hostname}:3010/v1/`,
  local: 'https://qa.mitforlob.dk/api/v1/',
  qa: 'https://qa.mitforlob.dk/api/v1/',
  prod: 'https://core.visikon.com/api/v1/',
};

export const blobs: Record<string, string> = {
  //local: `${backends.local}dev/storage/`,
  local: `https://visikoncorestorageqa.blob.core.windows.net/blobs-qa/`,
  qa: 'https://visikoncorestorageqa.blob.core.windows.net/blobs-qa/',
  prod: 'https://visikoncorestorage.blob.core.windows.net/blobs-production/',
};

export const selfService: Record<string, string> = {
  local: `http://localhost:3030/`,
  qa: 'https://qa.app.visikon.com/',
  prod: 'https://app.visikon.com/',
};
