import * as React from "react";
import "../radioButton/RadioButton.css";
// tslint:disable-next-line: no-empty-interface
interface IOwnProps {
  disabled?: boolean;
  label?: string;
  checked?: boolean;
  onPress(): void;
}

interface IState {
  checked: boolean;
}

type Props = IOwnProps;

export default class RadioButton extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  public confirmToggle() {
    this.setState({ checked: !this.state.checked });
  }
  public render() {
    const { onPress, disabled, checked, label } = this.props;
    return (
      // tslint:disable-next-line: no-empty
      <div
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 50,
          marginBottom: 0,
          marginLeft: 30,
        }}
        // tslint:disable-next-line: no-empty
        onClick={disabled ? () => {} : onPress}
      >
        <label className="radio">
          <input type="radio" checked={checked} className="hidden" readOnly/>
          <span className="label" />
          {label}
        </label>
      </div>
    );
  }
}
