import { useContentKeys, useFirstValidContentKey } from '../../common/selectors';
import { useShowProgramMenu } from '../GenericScreenHeader';
import * as Styles from './ProgramMenu.styles';
import { FeatureCard } from './FeatureCard';
import { useMemo } from 'react';

export function ProgramFeatures() {
  const contentKeys = useContentKeys();
  const firstContentKey = useFirstValidContentKey();
  const showFeatures = useShowProgramMenu();

  // Don't ever show the FIRST feature card -from validContentConfigs
  const features = useMemo(() => {
    const validContentKeys = contentKeys.filter((key) => key !== firstContentKey) || [];
    return validContentKeys.map((key, index) => <FeatureCard key={index} contentKey={key} />);
  }, []);

  if (!showFeatures || !features || features.length === 0) return null;

  return (
    <>
      <Styles.ProgramFeatures>{features}</Styles.ProgramFeatures>
      <Styles.Divider />
    </>
  );
}
