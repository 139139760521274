import { Button } from '@visikon/spine';
import * as React from 'react';
import styled from 'styled-components';
// import { i18n } from '@visikon/mytreatment/src/internationalization/i18n';
// import { useLanguage } from './selectors';
import { testConnection as probeConnection } from '@visikon/utils/src/utils';
import { updateNetworkStatus } from '@visikon/utils';

type Props = { timeout?: number };
const StyledButton = styled(Button)`
  position: absolute;
  top: 115%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const defaultTimeout = 7;
const secondsToMilliseconds = (seconds: number) => seconds * 1000;

export function TestConnectionButton({ timeout }: Props) {
  const [show, setShow] = React.useState(false);

  const testConnection = React.useCallback(async () => {
    const probeNetwork = await probeConnection({ timeout: 5000 });
    console.log('probeNetwork', probeNetwork);

    if (probeNetwork) {
      updateNetworkStatus(true);
    }
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, secondsToMilliseconds(timeout ?? defaultTimeout));

    return () => clearTimeout(timer);
  }, [timeout]);

  return show ? (
    <StyledButton className="primary rounded" onClick={testConnection}>
      {'RETRY'}
    </StyledButton>
  ) : null;
}
