import { backbone } from ".";

export function usetInitFeatureToggles() {
  const query = new URLSearchParams(window.location.search);
  const toggle = query.get('toggle');

  if (toggle) {
    const featureToggles = toggle.split(',');
    backbone.update({ featureToggles });
  }
}

export function useFeatureToggle(feature: string) {
  const featureToggles = backbone.store.getState().featureToggles || [];
  return featureToggles.includes(feature);
}
