import { MitforlobContent, Video, VideoInfoList, VideoPlaylist, VideoSection } from '../reducers/resourcesReducer';
import { Translation } from '@visikon/core-models/languageTypes';
import { ObjectId } from '@visikon/core-models/base';
import { ContentContainerKey } from '@visikon/core-models/models/content/content-containers';
import { validContentKeys } from './ContentConfig';
import UAParser from 'ua-parser-js';
import { backbone } from '@visikon/backbone/src';
import { useEffect, useState, useCallback } from 'react';
import { isPlatform } from '@ionic/react';

export const ANDROID: string = 'Android';
export const IOS: string = 'iOS';

const parser = new UAParser();
const parserResults = parser.getResult();
const parsedDataIncludes = (keyword: string) => parserResults.ua.toLowerCase().indexOf(keyword) > -1;
// Particular case for detecting iPads as now they are assumed to act like desktop mytreatment
// Solution from stack overflow: https://stackoverflow.com/questions/56934826/distinguish-between-ipad-and-mac-on-ipad-with-ipados
const isIpadChrome = parsedDataIncludes('ipad');
const isIPad = (parsedDataIncludes('macintosh') && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) || isIpadChrome;

export class MobileUtils {
  public static isMobile() {
    const deviceType = parser.getDevice().type;
    const clientType = backbone.store.getState().client?.type;

    let check = false;

    if (deviceType === 'mobile' || isIPad) check = true;

    if (clientType === 'android' || clientType === 'ios') {
      check = true;
    }

    return check;
  }

  public static getIonMode() {
    return backbone.store.getState().client?.type === 'web' || backbone.store.getState().client?.type === 'android' ? 'md' : 'ios';
  }

  public static getMobileOperatingSystem() {
    const OSName = parserResults.os.name;
    const clientType = backbone.store.getState().client?.type;
    if (!OSName) return;

    if (OSName.includes('Windows')) {
      return 'Windows Phone';
    }

    if (OSName.includes(ANDROID) || clientType === 'android') {
      return ANDROID;
    }

    if (OSName.includes(IOS) || isIPad || clientType === 'ios') {
      console.log('OSName - pandora', OSName);
      return IOS;
    }

    return 'unknown';
  }

  public static isNative(): boolean {
    return isPlatform('capacitor');
  }

  public static isAndroid(): boolean {
    return this.isMobile() && this.getMobileOperatingSystem() === ANDROID;
  }
}

// tslint:disable:no-bitwise
export class StringUtils {
  public static hashCode(str: string) {
    let hash = 0;
    if (str.length === 0) {
      return hash;
    }
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  public static capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

export class MediaUtils {
  public static searchForVideoInfo(id: ObjectId, ...videoLists: Array<Translation<VideoPlaylist>>): VideoInfoList[0] | undefined {
    // Iterate over the arbitrary number of video lists
    for (const videoList of videoLists) {
      if (videoList === undefined) {
        continue;
      }

      // Look into all sections
      for (const s of videoList.data.sections) {
        const video = s.videoList.find((v) => v.video._id === id);
        if (video) {
          return video;
        }
      }
    }
    return undefined;
  }

  public static prettyPrintDuration(duration?: number): string | undefined {
    if (!duration) {
      return undefined;
    }

    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.round(duration % 60);
    if (hours > 0) {
      return `${this.padNumber(hours)}:${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
    }
    return `${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }

  private static padNumber(num: number): string {
    if (num < 10) {
      return `0${num}`;
    }
    return `${num}`;
  }

  public static preloadResource(url: string | undefined, callback: (canLoad: boolean) => void) {
    const img = new Image();
    img.src = url || '';

    if (img.complete) {
      callback(true);
    }
    img.onload = () => {
      callback(true);
    };
  }
  public static BLANK_PIXEL = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
}

export function findVideoSections(content: any, sections: VideoSection[]): void {
  if (!content) {
    return;
  }
  if (content?.sections && content.sections.length > 0 && content.sections[0].videoList) {
    sections.push(...content.sections);
  }
  if (content.content && content.content.length > 0) {
    for (let i = 0; i < content.content.length; i++) {
      findVideoSections(content.content[i], sections);
    }
  }
  if (content.typeDescriptor === ContentContainerKey && content.data && content.data.content) {
    for (let i = 0; i < content.data.content.length; i++) {
      findVideoSections(content.data.content[i], sections);
    }
  }
  if (content.typeDescriptor === 'videoList' && content.data) {
    findVideoSections(content.data, sections);
  }
}

export function getVideoInfo(id: string | undefined, content: MitforlobContent | {}) {
  if (id === undefined || content === undefined) {
    return undefined;
  }
  // Find all possible videos
  const sections: VideoSection[] = [];
  for (let i = 0; i < validContentKeys.length; i++) {
    const field = (content! as any)[validContentKeys[i]]?.data;
    findVideoSections(field, sections);
  }
  // Reduce to Video objects
  const videoListInfo: Video[] = sections
    .reduce((acc, s) => acc.concat(...s.videoList), [] as VideoInfoList[0][])
    .map((v) => {
      const result = v.video;
      // Surprise! Overwriting video name with name from playlist
      result.name = v.name;
      return result;
    });
  // Find video
  return videoListInfo.find((v) => v._id === id);
}

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};
/* eslint-disable no-useless-escape */
const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email: string): boolean {
  return emailRegex.test(email);
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function usePromise<T>(func: Function, ...args): T {
  const [value, setValue] = useState();
  const memoizedFunc = useCallback(func, []);

  useEffect(() => {
    (async () => {
      try {
        setValue(await memoizedFunc(...args));
      } catch (error) {
        console.error(error);
        setValue(undefined);
      }
    })();
  }, [...args]);

  return value as T;
}

export const Wrap = ({ expression, decorator, children }) => {
  return expression ? decorator(children) : children;
};

export const isContentContainer = () => window.location.pathname.includes('content');
export const isHomeScreen = () => window.location.pathname === '/home';
