import { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAndSetAuthCode } from '../../actions/authActions';
import { LocalStorage } from '../../local-storage/startcodes-storage';
import { IState } from '../../reducers/reducer';
import { tracker } from '@visikon/tracker/src';
import { backbone } from '@visikon/backbone/src';
import { usePromise } from '../../common/utils';
import { CODE_INPUT_LENGTH } from '../login/EndUserLogin';
import { ROOT_ROUTE } from '../../components/MainContent';

export const AUTH_CODE_ROUTE = '/auth';
export const AUTH_CODE_PATH = `${AUTH_CODE_ROUTE}/:code`;

export function AuthRouter(props: RouteComponentProps<{ code: string }>) {
  const { code } = props.match.params;
  let authCode = code;
  const dispatch = useDispatch();
  const history = useHistory();

  if (!code || code.length === 0) {
    history.push(ROOT_ROUTE);
  }

  if (code.length > CODE_INPUT_LENGTH) {
    authCode = code.substring(0, CODE_INPUT_LENGTH);
  }

  const authState = useSelector((state: IState) => ({
    error: state.auth.error,
  }));

  dispatch(logoutAndSetAuthCode(authCode));

  tracker.trackScreen('AuthCode');

  history.push(ROOT_ROUTE);
  const deviceId = usePromise<string>(LocalStorage.getDeviceId);

  useEffect(() => {
    if (deviceId) {
      backbone.update({ deviceId });
    }
  }, [authState]);

  return <div>{authState.error && <div>{authState.error}</div>}</div>;
}
