import actionCreatorFactory from 'typescript-fsa';
import { MitforlobContent } from '../reducers/resourcesReducer';
import { ProgramInfo } from '../screens/startcode/api';
import { LanguageCode } from '@visikon/core-models/content';
import { Country } from '@visikon/core-models/i18n/languages';

const actionCreator = actionCreatorFactory('ResourcesAction');

export type FetchProgramsPayload = { id?: string };
export const changeProgram = actionCreator<FetchProgramsPayload>('CHANGE_PROGRAM');
export const changeCountry = actionCreator<Country>('CHANGE_COUNTRY');
export const fetchPrograms = actionCreator<FetchProgramsPayload>('FETCH_PROGRAMS');
export const fetchProgramsError = actionCreator<string>('FETCH_PROGRAMS_ERROR');

export type FetchProgramPayload = { id?: string; showPreview?: boolean };
export type FetchProgramFromBlobPayload = { id: string; path: string };
export const fetchProgram = actionCreator<FetchProgramPayload>('FETCH_PROGRAM');
export const fetchProgramFromBlob = actionCreator<FetchProgramFromBlobPayload>('FETCH_PROGRAM_FROM_BLOB');
export const fetchProgramSuccess = actionCreator<MitforlobContent>('FETCH_PROGRAM_SUCCESS');
export const clearResourceContent = actionCreator('CLEAR_RESOURCE_CONTENT');
export const setIsShowingPrivacyPolicy = actionCreator<boolean>('SET_IS_SHOWING_PRIVACY_POLICY');
export const setPrivacyPolicyAcceptedEvent = actionCreator<Function>('SET_PRIVACY_POLICY_ACCEPTED');
export const setProgramInfo = actionCreator<{ ProgramInfo: ProgramInfo; history: any }>('SET_PROGRAM_INFO');
export const setIsUpdating = actionCreator('SET_IS_UPDATING');
export const setIcons = actionCreator<Element[] | undefined>('SET_ICONS');
export const setError = actionCreator<string | undefined>('SET_ERROR');
export const setShowArchivedPrograms = actionCreator<boolean>('SET_SHOW_ARCHIVED_PROGRAMS');
