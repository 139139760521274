// tslint:disable:interface-name

export interface Data {
    [key: string]: any;
}

export interface Mark {
  object: "mark";
  type: string;
  data: { [key: string]: any };
}

export interface Character {
    object: "character";
    marks: Mark[];
    text: string;
}

export interface DocumentJSON {
    nodes?: NodeJSON[];
    key?: string;
    data?: { [key: string]: any };
    object?: "document";
}

export interface BlockJSON {
    type: string;
    key?: string;
    nodes?: NodeJSON[];
    isVoid?: boolean;
    data?: { [key: string]: any };
    object: "block";
}

export interface InlineJSON {
    type: string;
    key?: string;
    nodes?: NodeJSON[];
    isVoid?: boolean;
    data?: { [key: string]: any };
    object: "inline";
}

export interface Leaf {
    marks?: Mark[];
    text: string;
}

export interface TextJSON {
    key?: string;
    characters?: Character[];
    leaves: Leaf[];
    object: "text";
}

export function isDocumentJSON(node: NodeJSON): node is DocumentJSON {
    return node.object === "document";
}
export function isBlockJSON(node: NodeJSON): node is BlockJSON {
    return node.object === "block";
}
export function isInlineJSON(node: NodeJSON): node is InlineJSON {
    return node.object === "inline";
}
export function isTextJSON(node: NodeJSON): node is TextJSON {
    return node.object === "text";
}

export type NodeJSON = DocumentJSON | BlockJSON | InlineJSON | TextJSON;
