import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import './fonts/OpenSans';
import './fonts/DroidSansMono';
import './index.scss';
import { BrowserRouter as ReactRouter } from 'react-router-dom';
import { IonApp, setupIonicReact } from '@ionic/react';
import { useInitRedux } from './init';
import { ErrorBoundary } from './common/ErrorBoundary';
import { ErrorView } from './common/ErrorView';
import { Spinner } from './common/Spinner';
import { AppRouter } from './AppRouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useIsEnv, useIsLoggedIn } from '@visikon/backbone/src/selectors';
import { EnvironmentName } from '@visikon/backbone/src/env';
import { DialogOrchestrator, LocationHooks } from '@visikon/backbone/src/';
import { fetchPrograms } from './actions/resourcesActions';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import styled from 'styled-components';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import { useIsEmbedded } from './reducers/resourcesReducer';
import { usetInitFeatureToggles } from '@visikon/backbone/src/feature-toggles';
import { isStartAuthOrSetUserRoute } from './sagas/utils';
import { useLanguage } from './common/selectors';
import './internationalization/HtmlLanguage';
import { PrefetchFeatureFlags } from './common/FeatureFlags';

const IonicAppWrapper = styled(IonApp)<{ embedded: boolean }>`
  // Remove ionic header when running in embed mode
  ion-header {
    display: ${(props) => (props.embedded ? 'none' : 'block')};
  }
`;

// Setting up ionic with its global config
const MODE = 'ios'; // one mode for constant look across all platforms
setupIonicReact({
  mode: MODE,
  swipeBackEnabled: true,
});

const queryClient = new QueryClient();
PrefetchFeatureFlags(queryClient);

export function initReactApp() {
  createRoot(document.getElementById('root') as HTMLElement).render(<App />);
}

export function App() {
  const store = useInitRedux();
  usetInitFeatureToggles();

  return (
    <ErrorBoundary fallback={<ErrorView />}>
      <React.Suspense fallback={<Spinner />}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <AppWrapper />
          </Provider>
        </QueryClientProvider>
      </React.Suspense>
    </ErrorBoundary>
  );
}

function AppWrapper() {
  const dispatch = useDispatch();
  const loggedIn = useIsLoggedIn();
  const isDev = useIsEnv(EnvironmentName.Local);
  const isEmbedded = useIsEmbedded();
  const language = useLanguage();
  const level = LocationHooks.useNavLevel();
  const isHomeLevel = level === -1;

  // Fetch programs when logged in and on language change (if not on home level)
  useEffect(() => {
    if (loggedIn && !isStartAuthOrSetUserRoute() && !isHomeLevel) {
      dispatch(fetchPrograms({}));
    }
  }, [loggedIn, language]);

  // Fetch programs once when on home level
  React.useEffect(() => {
    if (loggedIn && isHomeLevel) {
      dispatch(fetchPrograms({}));
    }
  }, [loggedIn, isHomeLevel]);

  return (
    <>
      {/* @ts-ignore */}
      <IonicAppWrapper embedded={isEmbedded}>
        <ReactRouter>
          <AppRouter />
        </ReactRouter>
        <DialogOrchestrator.Renderer />
      </IonicAppWrapper>
      {isDev && <ReactQueryDevtools position="bottom-right" />}
    </>
  );
}
