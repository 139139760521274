import { useSelector } from 'react-redux';
import { IVideoProgress } from '../../reducers/userDataReducer';
import { IState } from '../../reducers/reducer';
import { useEffect } from 'react';

declare global {
  interface HTMLVideoElement {
    // requestFullscreen has major support expect for iOS which added support in iOS 16.4
    // override it as possibly undefined to make sure we check before using it
    requestFullscreen?(): Promise<void>;
    mozRequestFullScreen: HTMLVideoElement['requestFullscreen'];
    webkitRequestFullscreen: HTMLVideoElement['requestFullscreen'];
    msRequestFullscreen: HTMLVideoElement['requestFullscreen'];
    webkitEnterFullscreen(): void;
  }
}

export function useVideoWatchedPercentage(videoId: string) {
  return useSelector<IState, number>((state) => {
    return getVideoProgressFromArray(videoId, state.userData.videoProgress);
  });
}

export function getVideoProgressFromArray(videoId: string, list: IVideoProgress[]) {
  const result = list.find((p) => p.id === videoId);
  return result ? result.percentage : 0;
}

export function checkIsFullscreen() {
  const docAsAny = document as any;
  const fullScreenElement = docAsAny.fullscreenElement || docAsAny.webkitFullscreenElement || docAsAny.mozFullscreenElement;
  const isFullscreen = fullScreenElement !== null && fullScreenElement !== undefined;
  return isFullscreen;
}

export async function requestFullscreen(video?: HTMLVideoElement | null) {
  if (!video) return;

  try {
    let result: Promise<void> | void = undefined;
    if (video.requestFullscreen) result = video.requestFullscreen();
    else if (video.webkitEnterFullscreen) video.webkitEnterFullscreen();
    else if (video.webkitRequestFullscreen) result = video.webkitRequestFullscreen();
    else if (video.mozRequestFullScreen) result = video.mozRequestFullScreen();
    else if (video.msRequestFullscreen) result = video.msRequestFullscreen();

    // Await promises to that errors gets caught by our try...catch
    if (result instanceof Promise) await result;
  } catch (e: any) {
    console.log("Couldn't enter fullscreen", e.message);
  }
}

export function onFullScreenChangeEvent(videoElement: HTMLVideoElement, callback: () => void): () => void {
  let eventName = 'fullscreenchange';
  switch (true) {
    default:
    case videoElement.onfullscreenchange !== undefined:
      eventName = 'fullscreenchange';
      break;
    // @ts-ignore
    case videoElement.onmozfullscreenchange !== undefined:
      eventName = 'mozfullscreenchange';
    // @ts-ignore
    case videoElement.onwebkitfullscreenchange !== undefined:
      eventName = 'webkitfullscreenchange';
      break;
  }

  videoElement.addEventListener(eventName, callback);
  return () => videoElement.removeEventListener(eventName, callback);
}

export function useFullScreenChangeEvent(videoElement: HTMLVideoElement | null, callback: () => void) {
  useEffect(() => {
    if (!videoElement) return;

    return onFullScreenChangeEvent(videoElement, callback);
  }, [videoElement]);
}

export function exitFullscreen() {
  const docAsAny = document as any;
  const isFullscreen = checkIsFullscreen();

  // Exit fullscreen
  if (isFullscreen) {
    try {
      if (docAsAny.exitFullscreen) {
        docAsAny.exitFullscreen();
      }
      if (docAsAny.webkitExitFullscreen) {
        docAsAny.webkitExitFullscreen();
      }
      if (docAsAny.mozCancelFullscreen) {
        docAsAny.mozCancelFullscreen();
      }
    } catch (e: any) {
      console.log("Couldn't exit fullscreen", e.message);
    }
  }
}

export function destroyVideo(videoElement: HTMLVideoElement) {
  videoElement.pause();
  videoElement.removeAttribute('src'); // empty source
  videoElement.load();
}
