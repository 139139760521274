import { AvailableProgramsResult } from '../local-storage/programs-storage';
import { getTranslation } from '@visikon/core-models/typeUtils';
import { LanguageCode } from '@visikon/core-models/content';
import { Content } from '@visikon/core-models';
import { ProgramData } from '../reducers/userDataReducer';

export type ProgramInfo = {
  id: string;
  name: string;
  department: string;
  subName: string;
  organization: string;
  anatomy: string;
  enableDirectAccess: boolean;
  startCode: string;
  languages: LanguageCode[];
  image?: Content.Image;
  archived?: boolean;
  order?: number;
  isNew?: boolean;
};

export function listAvailablePrograms(
  availablePrograms: AvailableProgramsResult | undefined,
  language: LanguageCode,
  programsData?: ProgramData[],
): ProgramInfo[] {
  if (!availablePrograms?.programs) return [];

  return availablePrograms.programs
    .map((p) => {
      const translation = getTranslation(p, language);
      if (!translation) {
        return undefined;
      }
      const languageList = p.translations.map((t) => t.language);
      const programData = programsData?.find((d) => d.id === p._id);
      const archived = programData?.archived;
      const order = programData?.order;
      const isNew = programData?.isNew;
      return {
        id: p._id,
        enableDirectAccess: p.enableDirectAccess,
        startCode: p.startCode,
        name: translation.name,
        department: translation.department,
        subName: translation.subName,
        organization: translation.organization,
        anatomy: translation.anatomy,
        languages: languageList,
        image: p.image,
        archived,
        order,
        isNew,
      };
    })
    .filter((p) => p !== undefined) as ProgramInfo[];
}
