export namespace Tokens {
  export const size = {
    text: {
      mammoth: 68,
      elephant: 56,
      epic: 46,

      h1: 38,
      h2: 30,
      h3: 24,
      h4: 20,
      h5: 16,

      large: 20,
      medium: 16,
      small: 14,
      tiny: 12,
    },
  };
}
