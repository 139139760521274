import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';
import { shareProgramSms, shareProgramSuccess, shareProgramFailed, shareProgramClearStatus } from '../actions/userActions';

export interface IShareProgramSmsActionType {
  phoneNumber: string;
}

export interface IShareProgramEmailActionType {
  name: string;
  email: string;
}

export interface IShareProgramState {
  isRunning: boolean;
  errorMsg?: string;
  status: 'none' | 'success' | 'failed';
}

const initialState: IShareProgramState = { isRunning: false, status: 'none', errorMsg: undefined };

export const shareProgramReducer: Reducer<IShareProgramState> = (state = { ...initialState }, action) => {
  // User info
  if (isType(action, shareProgramSms)) {
    return { ...state, isRunning: true };
  }
  if (isType(action, shareProgramSuccess)) {
    return { ...state, isRunning: false, status: 'success', errorMsg: undefined };
  }
  if (isType(action, shareProgramFailed)) {
    return { ...state, isRunning: false, status: 'failed', errorMsg: action.payload };
  }
  if (isType(action, shareProgramClearStatus)) {
    return { ...initialState };
  }

  return state;
};
