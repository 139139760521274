import * as React from 'react';
import { backbone } from '@visikon/backbone';
import { useWholeContent } from '../../common/selectors';
import { MediaFileUtils, useImagePreloader } from '@visikon/utils';
import * as Styles from './ProgramMenu.styles';
import { TreatmentDateContainer } from '@visikon/nativeui/src/program/TreatmentDate';
import { ContactButton } from '../contactPopup/ContactButton';
import { ProgramFeatures } from './ProgramFeatures';

export const ProgramMenu = () => {
  const country = backbone.store.getState().country;
  const content = useWholeContent();

  const logoUri = MediaFileUtils.getUriForImage(content.department?.logo?._id, country.languageCode);
  const [departmentLogo, setDepartmentLogo] = React.useState<React.ReactElement<any> | null>(null);

  const imgSrc = useImagePreloader(logoUri);

  React.useEffect(() => {
    if (imgSrc) {
      setDepartmentLogo(<img src={imgSrc} alt={'logo'} width={107} />);
    } else {
      setDepartmentLogo(<div style={{ width: 107, height: '100%' }} />);
    }
  }, [imgSrc]);

  return (
    <Styles.ProgramContainer>
      <Styles.Container>
        <Styles.Header>
          <Styles.Logo>
            <div style={{ width: '100%' }}>
              {departmentLogo}
              <Styles.SubTitle>{content.department.organization}</Styles.SubTitle>
            </div>
            <Styles.Spacer />
            <ContactButton />
          </Styles.Logo>
          <Styles.Title>{content.name}</Styles.Title>
          <Styles.SubTitle>{content.subName}</Styles.SubTitle>

          <div style={{ marginTop: '8px' }}>
            <TreatmentDateContainer />
          </div>
        </Styles.Header>

        <ProgramFeatures />
      </Styles.Container>
    </Styles.ProgramContainer>
  );
};
