import * as React from 'react';
import { ContentContainer } from '../../reducers/resourcesReducer';
import { ContentCard, ContentDescription, ContentText, ContentTitle, Divider } from './ContentContainerComponent.styles';
import { useHistory } from 'react-router-dom';
import { useImageFromUri } from '@visikon/utils/src/utils';
import Thumbnail from '../../components/Thumbnail';
import { Fader } from '../../common/Fader';
import { Chevron } from '../../components/styled/Chevron';
import { ContentConfig } from '../../common/ContentConfig';
import { i18n } from '../../internationalization/i18n';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import { backbone } from '@visikon/backbone';

interface IProps {
  content: ContentContainer;
  id: string;
  fieldKey?: keyof ContentConfig;
}

export const ContentCardComponent = ({ content, id, fieldKey }: IProps) => {
  const history = useHistory();
  const imgId = content.image?._id;
  const uri = imgId ? useImageFromUri(imgId) : '';
  const fromTitle = fieldKey ? i18n(fieldKey as TextKeys) : content.title;
  const programId = backbone.store.getState().program?.id;
  return (
    <Fader>
      <section style={{ padding: 'unset', margin: 'unset' }}>
        <ContentCard tabIndex={0} onClick={() => history.push({ pathname: `/${programId}/content/${id}`, state: { from: fromTitle } })}>
          <ContentText style={{ justifyItems: 'center' }}>
            <ContentTitle>{content.title}</ContentTitle>
            {content.description ? <ContentDescription>{content.description}</ContentDescription> : null}
          </ContentText>
          <Divider />
          <Thumbnail thumbUrl={uri} aligned={'none'} />
          <Chevron isOpen={false} />
        </ContentCard>
      </section>
    </Fader>
  );
};
