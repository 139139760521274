import { combineReducers } from 'redux';
import { authReducer, IState as IAuthState } from './authReducer';
import { IState as IResourcesState, resourcesReducer } from './resourcesReducer';
import { IShareProgramState, shareProgramReducer } from './shareProgramReducer';
import { IUserDataState, userDataReducer } from './userDataReducer';
import { surveyReducer, ISurveyResult } from './surveyReducer';

export interface IState {
  auth: IAuthState;
  resources: IResourcesState;
  userData: IUserDataState;
  shareProgram: IShareProgramState;
  surveyResult: ISurveyResult;
}

export const reducer = combineReducers({
  auth: authReducer,
  resources: resourcesReducer,
  userData: userDataReducer,
  shareProgram: shareProgramReducer,
  surveyResult: surveyReducer,
});
