import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tracker } from '@visikon/tracker/src';
import { I18N, SetDocumentTitle } from '../../internationalization/AppText';
import { i18n } from '../../internationalization/i18n';
import styled from 'styled-components';
import { shareProgramSms, shareProgramClearStatus, shareProgramEmail } from '../../actions/userActions';
import { FlexView, FlexWrapper, SmallViewSidePadding } from '../../components/styled/ContentAreaContainers';
import { InputText, Label } from '../../components/styled/Inputs';
import SplashImage from '../../images/characterLineup.png';
import { IState } from '../../reducers/reducer';
import { validateEmail } from '../../common/utils';
import { backbone } from '@visikon/backbone/src';
import { useEffect, useState } from 'react';
import { ContentContainerSection } from '../../components/ContentContainerSection';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import { SubmitOrSpinner } from '../../components/Buttons/SubmitOrSpinner';

export const SHARE_PROGRAM_ROUTE = '/share';

const ShareFlexContainer = styled(FlexView)`
  flex-direction: row;
  align-items: center;

  @media all and (max-width: ${Sizes.breakPointMid}) {
    flex-direction: column;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    background-color: #ffffbe;
    border: 1px solid #e4ee00;
    padding: 10px;
    margin-top: 15px;
    border-radius: 8px;
  }
`;
const SuccessMessage = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding: 15px;
`;

interface ILocalState {
  phoneNumber: string;
  phoneNumberError: boolean;
  name: string;
  nameError: boolean;
  email: string;
  emailError: boolean;
  errorMessage?: string;
  successMessage?: string;
  loadingEmail: boolean;
  loadingSms: boolean;
}

export const ShareProgramContainer = () => {
  const dispatch = useDispatch();
  const userType = backbone.store.getState().user?.type;
  const { status, errorMsg } = useSelector((state: IState) => state.shareProgram);

  const [state, setState] = useState<ILocalState>({
    phoneNumber: '',
    phoneNumberError: false,
    name: '',
    email: '',
    nameError: false,
    emailError: false,
    loadingEmail: false,
    loadingSms: false,
  });

  const updateState = (newState: Partial<ILocalState>) => {
    setState({ ...state, ...newState });
  };
  const clearShareState = () => {
    dispatch(shareProgramClearStatus());
  };

  useEffect(() => {
    return () => {
      clearShareState();
    };
  }, []);

  const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNumber = event.target.value;
    updateState({ phoneNumber: newPhoneNumber, phoneNumberError: false });
    clearShareState();
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateState({ name: event.target.value, nameError: false });
    clearShareState();
  };

  const onMailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    const validEmail = validateEmail(newEmail);
    updateState({ email: validEmail ? newEmail : '', emailError: false });
    clearShareState();
  };

  const checkNameAndEmail = () => {
    return state.email !== '' && state.name !== '';
  };

  const checkPhoneNumber = () => state.phoneNumber.startsWith('+') && state.phoneNumber.length > 5;

  const onShareEmailPress = (ev: React.FormEvent) => {
    ev.preventDefault();
    clearShareState();
    updateState({ errorMessage: undefined, successMessage: undefined });

    const { name, email } = state;
    if (!name || name.trim() === '') {
      tracker.trackEvent('Share', 'shareSendPress', 'Missing name');
      updateState({ nameError: true, errorMessage: i18n('share_error_add_name') });
      return;
    }

    if (!email || email.trim() === '') {
      tracker.trackEvent('Share', 'shareSendPress', 'Missing mail');
      updateState({ emailError: true, errorMessage: i18n('enter_valid_mail') });
      return;
    }

    tracker.trackEvent('Share', 'shareSendPress', 'Successful');
    updateState({ loadingEmail: true });
    console.log('name.trim(): ' + name.trim(), 'email.trim(): ' + email.trim());
    dispatch(shareProgramEmail({ name: name.trim(), email: email.trim() }));
  };

  const onShareSmsPress = (ev: React.FormEvent) => {
    ev.preventDefault();
    clearShareState();
    updateState({ errorMessage: undefined, successMessage: undefined });

    const { phoneNumber } = state;

    if (!phoneNumber || phoneNumber.trim() === '') {
      tracker.trackEvent('Share', 'shareSendPress', 'Missing phone number');
      updateState({ phoneNumberError: true, errorMessage: i18n('enter_valid_phone_number') });
      return;
    }

    tracker.trackEvent('Share', 'shareSendPress', 'Successful');
    updateState({ loadingSms: true });
    dispatch(shareProgramSms({ phoneNumber }));
  };

  const renderStatus = () => {
    if (status !== 'success') {
      return null;
    }
    return (
      <SuccessMessage role="alert">
        <I18N text="share_invitation_sent" />
      </SuccessMessage>
    );
  };

  const renderError = () => {
    const { nameError, emailError, phoneNumberError, errorMessage } = state;

    if (phoneNumberError || nameError || emailError) {
      return (
        <ErrorMessage>
          <div>{errorMessage}</div>
        </ErrorMessage>
      );
    }

    if (errorMsg) {
      return (
        <ErrorMessage>
          <div>
            <I18N text="merge_error_unknown_error" />
          </div>
        </ErrorMessage>
      );
    }

    return null;
  };

  const { phoneNumberError, emailError, nameError } = state;

  // If we are loading and we have an error, then we are done loading
  const loadingEmailAndError = state.loadingEmail && (nameError || emailError || errorMsg || status !== 'none');
  const loadingSmsAndError = state.loadingSms && (phoneNumberError || errorMsg || status !== 'none');
  if (loadingEmailAndError) {
    updateState({ loadingEmail: false });
  }
  if (loadingSmsAndError) {
    updateState({ loadingSms: false });
  }

  return (
    <FlexWrapper style={{ backgroundColor: '#fff' }}>
      <SetDocumentTitle text="share_program_title" />
      <ContentContainerSection>
        <SmallViewSidePadding>
          <main>
            <ShareFlexContainer>
              <FlexView
                style={{
                  flexDirection: 'column',
                  flex: 1,
                  textAlign: 'center',
                  lineHeight: '1.5',
                }}
              >
                <h1>
                  <I18N text={'share_allow_friends_and_family'} />
                </h1>
                <h2 style={{ fontSize: '1.2em' }}>
                  <I18N text={'share_body_patient'} />
                </h2>
                <p>
                  <I18N text={'share_no_personal_info'} />
                </p>

                {/*<form onSubmit={onShareSmsPress} style={{ maxWidth: 300, marginTop: 20, alignSelf: 'center' }}>*/}
                {/*  <Label>*/}
                {/*    <I18N text="enter_valid_phone_number" />*/}
                {/*    <InputText*/}
                {/*      inputMode="tel"*/}
                {/*      className={phoneNumberError ? 'error' : ''}*/}
                {/*      onChange={onPhoneNumberChange}*/}
                {/*      aria-label={i18n('accessibility_share_your_phone_number_input', language)}*/}
                {/*    />*/}
                {/*  </Label>*/}
                {/*  <br />*/}
                {/*  <div>*/}
                {/*    <SubmitOrSpinner*/}
                {/*      loading={state.loadingSms}*/}
                {/*      disabled={!checkPhoneNumber()}*/}
                {/*      text={*/}
                {/*        <>*/}
                {/*          <I18N text="share" />*/}
                {/*        </>*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</form>*/}
                {/*<div style={{ marginTop: 40, borderTop: '1px solid #ccc', marginBottom: 10 }}>*/}
                {/*  <span*/}
                {/*    style={{*/}
                {/*      display: 'inline-block',*/}
                {/*      transform: 'translateY(-50%)',*/}
                {/*      padding: '0 15px',*/}
                {/*      background: '#f1f4f8',*/}
                {/*      color: '#666',*/}
                {/*      fontWeight: 'bold',*/}
                {/*      textTransform: 'lowercase',*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <I18N text="or" />*/}
                {/*  </span>*/}
                {/*</div>*/}
                <form onSubmit={onShareEmailPress} style={{ maxWidth: 300, alignSelf: 'center' }}>
                  <Label>
                    <I18N text="your_name" />
                    <InputText inputMode="text" className={nameError ? 'error' : ''} onChange={onNameChange} />
                  </Label>
                  <Label>
                    <I18N text="share_receivers_mail" />
                    <InputText inputMode="email" className={emailError ? 'error' : ''} onChange={onMailChange} />
                  </Label>
                  <div style={{ marginTop: 20 }}>
                    <SubmitOrSpinner
                      loading={state.loadingEmail}
                      disabled={!checkNameAndEmail()}
                      text={
                        <>
                          <I18N text="share" />
                        </>
                      }
                    />
                  </div>
                </form>
                <div style={{ height: 50 }}>
                  {renderError()}
                  {renderStatus()}
                </div>
              </FlexView>
              <div style={{ flex: 1, padding: 25 }}>
                <img src={SplashImage} style={{ maxWidth: '100%' }} alt="" />
              </div>
            </ShareFlexContainer>
          </main>
        </SmallViewSidePadding>
      </ContentContainerSection>
    </FlexWrapper>
  );
};
