import styled from 'styled-components';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;

export const ProgramContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // To cancel out the padding on the container
  margin: 0 -16px;
`;
export const Container = styled.div`
  width: 100%;
`;

export const ProgramFeatures = styled.ul`
  display: flex;
  padding: 24px 16px 34px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-top: 1px solid #ebedef;
`;

export const Divider = styled.div`
  background: rgba(0, 0, 0, 0.06);
  height: 1px;
  margin: 0 24px 0 24px;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  background: ${color.homeBackground};
  padding: 16px;
`;
export const Logo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 24px 0;
  border-bottom: 1px solid ${color.border};
`;
export const Spacer = styled.div`
  width: 0;
  flex-grow: 1;
`;
export const Title = styled.div`
  padding-top: 24px;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px; /* 140% */
`;
export const SubTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;
