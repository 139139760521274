import { Network } from '@capacitor/network';
import { backbone } from '@visikon/backbone/src';
import { MobileUtils } from '@visikon/mytreatment/src/common/utils';

export const updateNetworkStatus = (isConnected: boolean) => {
  backbone?.update({ isOffLine: !isConnected });
};
export function ConnectionMonitor() {
  const isNative = MobileUtils.isNative();

  if (isNative) {
    Network.addListener('networkStatusChange', async (status) => {
      console.log('Network status changed:', status);
      // TODO: find out why on ios NetworkStatusChange is not detecting the first network change
      console.log('isConnected', status.connected);
      updateNetworkStatus(status.connected);
    });
    // Initial check
    Network.getStatus().then((status) => {
      console.log('connected  // Initial check', status.connected);
    });
  } else {
    window.addEventListener('online', () => {
      updateNetworkStatus(navigator.onLine);
    });

    window.addEventListener('offline', () => {
      updateNetworkStatus(navigator.onLine);
    });
  }
}

ConnectionMonitor();
