import * as React from 'react';
import * as Styles from '../components/styled/DetailsCard';
import { LazyImage } from '@visikon/utils';
export type Alignment = 'left' | 'right' | 'none';

type Props = { thumbUrl?: string; aligned?: Alignment } & React.HTMLProps<HTMLImageElement>;
function Thumbnail({ thumbUrl, aligned = 'right', ...rest }: Props) {
  return (
    <Styles.Thumbnail aligned={aligned}>
      <LazyImage imageUrl={thumbUrl} {...rest} />
    </Styles.Thumbnail>
  );
}

export default Thumbnail;
