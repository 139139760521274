import styled from 'styled-components';
import { DetailsCardContent } from '../../components/styled/DetailsCard';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';

export const Details = styled(DetailsCardContent)<{ severity: 'positive' | 'neutral' | 'negative' }>`
  background-color: #fafafa;
  & > div {
    align-items: center;
    &:first-child {
      flex: 1;
      background-color: ${DeprecatedTokens.whiteBackground};
      border-right: 8px solid ${(props) => DeprecatedTokens[props.severity]};
      min-width: inherit; !important;
      max-width: inherit; !important;
    }
  }

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    flex-direction: column;
    margin: 8px 0;

    & > div {
      border-left: 8px solid ${(props) => DeprecatedTokens[props.severity]};
      border-top: 1px solid ${(props) => DeprecatedTokens[props.severity]};
      border-radius: 0 0 8px 8px;
      padding: 15px !important;

      &:first-child {
        align-items: flex-start;
        text-align: left;
        margin-right: 0;
        border: 0;
        padding: 4px 10px;
        border-left: 8px solid ${(props) => DeprecatedTokens[props.severity]};
        border-radius: 8px 8px 0 0;
        padding-left: 10px;

        min-width: auto; !important;
        max-width: auto; !important;

        svg {
          width: 16px;
          height: 16px;
          min-width: 16px;
          padding-right: 4px;
        }
      }
    }
  }
`;

export const TextBlock = styled.span`
  margin-top: 15px;
  max-width: 100%;

  &:first-child {
    margin-top: 0;
  }
`;
