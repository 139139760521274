import { Tokens as ColorsTokens } from '@visikon/spine/src/styles/tokens/colors';
import { Tokens } from '@visikon/spine/src/styles/tokens/size';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import styled from 'styled-components';
import { useWholeContent } from '../common/selectors';
import color = ColorsTokens.color;
import size = Tokens.size;

export const FooterContainer = styled.div`
  color: ${color.disabled};
  font-size: ${size.text.small};
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const WhiteOnMobile = styled.div`
  @media all and (max-width: ${Sizes.breakPointSmall}) {
    background-color: #ffffff;
  }
`;

type Props = {
  children: React.ReactNode;
  whiteBackground?: boolean;
  footerStyle?: React.CSSProperties;
};

export function WithFooter({ children, whiteBackground, footerStyle }: Props) {
  return (
    <FooterContent>
      <div>{children}</div>

      {whiteBackground && (
        <WhiteOnMobile>
          <Footer styling={footerStyle} />
        </WhiteOnMobile>
      )}

      {!whiteBackground && <Footer styling={footerStyle} />}
    </FooterContent>
  );
}

function Footer({ styling }: { styling?: React.CSSProperties }) {
  const programContent = useWholeContent();
  const footerText = programContent?.footer ? programContent.footer : process.env.NODE_ENV !== 'production' ? '-no footer-' : '';

  return <FooterContainer style={styling}>{footerText}</FooterContainer>;
}
