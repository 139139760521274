import React, { Component, ErrorInfo, ReactNode, ReactElement } from 'react';
import { logger } from '@visikon/utils/src/logger';

export type ErrorBoundaryFallbackProps = {
  error?: Error;
  errorInfo?: ErrorInfo;
};

type Props = {
  children: ReactNode;
  fallback: ReactElement<ErrorBoundaryFallbackProps>;
};

type State = {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
};

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo): State {
    return {
      hasError: true,
      error,
      errorInfo,
    };
  }

  public render() {
    const { fallback, children } = this.props;
    const { hasError, error, errorInfo } = this.state;

    if (hasError) {
      logger.error(error, errorInfo);
      return fallback ? React.cloneElement(fallback, { error, errorInfo }) : null;
    }

    return children;
  }
}
