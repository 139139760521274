import * as React from 'react';
import { IonContent, IonFooter, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import * as Styles from './LoginContainer.styles';
import { backbone } from '@visikon/backbone/src';
import { ShowStaffStorage } from '../../local-storage/username-storage';
import { Fader } from '../../common/Fader';
import Splash from '../../images/characterLineup.png';
import { useHistory } from 'react-router-dom';
import { NoConnectionScreen } from '../../common/NoConnectionScreen';
import { LogoLanguage } from './LoginContainer.styles';
import { LanguageSelectorDialog } from '../../internationalization/LanguageSelectorDialog';
import { Country } from '@visikon/core-models/i18n/languages';
import { useCountry } from '../../common/selectors';
import { useSelector } from 'react-redux';
import { IState } from '../../reducers/reducer';
import { EndUserLoginV2 } from './EndUserLogin';
import { useEffect } from 'react';
import { CountryStorage } from '../../local-storage/language-storage';
import { Logo } from '../../components/Logo';
import { MobileUtils } from '../../common/utils';
import { useLanguageCodeHandler } from './LanguageCodeHandler';
import { ROOT_ROUTE } from '../../components/MainContent';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import { SetDocumentThemeColor } from '../../common/DocumentThemeColor';
import { Unstyled } from '../../components/Unstyled';

function uniqueCountryCode(country: Country) {
  return `${country.languageCode}-${country.code.toUpperCase()}`;
}

export function LoginContainerV2() {
  const usedHistory = useHistory();
  const country = useCountry();
  const loginProcess = useSelector((state: IState) => state.auth.loginProcess);
  const { codeVerified, verifyCode, languageCodesToChoose, languageSelectorModal } = useLanguageCodeHandler();

  useEffect(() => {
    const getStaffEnabled = async () => {
      const staffEnabled = await ShowStaffStorage.enabled();
      const backFromStaff = (usedHistory.location.state as { backFrom: string })?.backFrom === '/staff';

      if (staffEnabled && !loginProcess?.startCode) {
        // This is to ensure that the user is not redirected to the staff login screen when they explicitly going back from there
        const location = backFromStaff ? { pathname: ROOT_ROUTE, state: { backFrom: '/' } } : { pathname: '/staff' };
        usedHistory.push(location);
      }
    };
    getStaffEnabled();
  }, []);

  // set stored country code
  useEffect(() => {
    CountryStorage.get().then((storedCountry) => {
      if (storedCountry && uniqueCountryCode(storedCountry) !== uniqueCountryCode(country)) {
        backbone.store.setState({ country: storedCountry });
      }
    });
  }, [country]);

  const ionMode = MobileUtils.getIonMode();

  return (
    <Fader timeout={500} seconds={0.5}>
      <IonPage style={{ height: '100%' }}>
        <SetDocumentThemeColor color={Tokens.color.homeBackground} />
        <IonHeader mode={ionMode} className="ion-no-border" style={{ zIndex: -1 }}>
          <IonToolbar mode={ionMode} style={{ '--min-height': 0, '--background': Tokens.color.homeBackground }} />
        </IonHeader>
        <NoConnectionScreen />
        <IonContent className="ion-padding" forceOverscroll={false} style={{ '--ion-background-color': Tokens.color.homeBackground }}>
          <Styles.ContentWrapper>
            <Styles.LoginSection>
              <LogoLanguage>
                <Unstyled.Heading>
                  <Logo addedHeight={8} />
                </Unstyled.Heading>
                <LanguageSelectorDialog />
              </LogoLanguage>
              <Styles.LoginContentWrapper>
                <Styles.TopBanner style={{ margin: '0' }}>
                  <img src={Splash} alt="" />
                </Styles.TopBanner>

                <EndUserLoginV2 verifyCode={verifyCode} codeVerified={codeVerified} />
                {languageSelectorModal}
              </Styles.LoginContentWrapper>
            </Styles.LoginSection>
          </Styles.ContentWrapper>
        </IonContent>
        <IonFooter className="ion-no-border">
          <IonToolbar style={{ height: 0, '--background': Tokens.color.homeBackground }}></IonToolbar>
        </IonFooter>
      </IonPage>
    </Fader>
  );
}
