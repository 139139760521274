import { usePromise } from '../../common/utils';
import { PreviewInfo, usePreviewForActiveProgram, useSelectPreviewActiveProgram } from '../../common/selectors';
import * as React from 'react';
import { Notice } from '../../components/Notice';
import { fetchProgram } from '../../actions/resourcesActions';
import { useDispatch } from 'react-redux';
import { i18n } from '../../internationalization/i18n';
import { ContactVisikonDialog } from './ContactVisikonDialog';

export const StatusNotice = () => {
  const dispatch = useDispatch();
  const { showPreview, activeProgram } = useSelectPreviewActiveProgram();
  const preview = usePromise<PreviewInfo>(usePreviewForActiveProgram, activeProgram, showPreview);
  const contactDialogRef = React.useRef<HTMLDialogElement>(null);
  const handleProgramChanges = () => {
    dispatch(fetchProgram({ id: activeProgram, showPreview: !preview.isPreview }));
  };

  const actions = [
    { label: showPreview ? i18n('preview_status_preview_link') : i18n('preview_status_live_link'), onClick: handleProgramChanges },
    {
      label: i18n('preview_status_contact_link'),
      onClick: () => {
        contactDialogRef.current?.showModal();
      },
    },
  ];

  const headerText = showPreview ? i18n('preview_status_preview_header') : i18n('preview_status_live_header');
  const bodyText = showPreview ? i18n('preview_status_preview_body') : i18n('preview_status_live_body');
  if (preview && preview.isReviewer && preview.hasPreview) {
    return (
      <>
        <Notice header={headerText} isPreview={!!showPreview} actions={actions} notDismissible={true}>
          {bodyText}
        </Notice>
        <ContactVisikonDialog dialogRef={contactDialogRef} />
      </>
    );
  } else {
    return <></>;
  }
};
