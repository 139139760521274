import * as React from 'react';
import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;
import Eye from '../images/svg/Eye_Outline.svg';
import Alert from '../images/svg/alert-circle.svg';
import colors = Tokens.colors;
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';

const OuterContainer = styled.div<{ isPreview: boolean }>`
  background: ${(props) => (props.isPreview ? colors.primary[100] : 'rgba(0, 109, 179, 0.05)')};
  color: ${(props) => color.primary};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 16px;
  align-items: flex-start;
  gap: 12px;
  margin: 16px 0;

  @media (max-width: ${Sizes.breakPointSmall}) {
    margin: 16px 16px;
  }

  border-radius: 6px;
  border: 1px solid ${(props) => (props.isPreview ? colors.primary[200] : 'rgba(0, 109, 179, 0.05)')};
  overflow: hidden;
  @media (max-width: ${Sizes.breakPointXSmall}) {
    flex-wrap: wrap;
  }
`;
const Content = styled.div<{ isPreview: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: ${(props) => color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  color: ${(props) => (props.isPreview ? color.text : colors.primary[600])};
`;
const Header = styled(Content)`
  font-weight: 800;
`;
const ActionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 8px;
  gap: 24px;
`;
const ActionLink = styled.a<{ isPreview: boolean }>`
  color: ${(props) => (props.isPreview ? color.primary : colors.primary[600])};
  font-weight: 600;
`;

export type NoticeAction = {
  label: string;
  onClick: () => void;
};

export type Props = PropsWithChildren<{
  header: string;
  actions: NoticeAction[];
  notDismissible?: boolean;
  isPreview: boolean;
}>;

function Icon(props: { isPreview: boolean }) {
  let iconSrc = props.isPreview ? Eye : Alert;
  return <img alt="" src={iconSrc} height={22} />;
}

export function Notice(props: Props) {
  const [show, setShow] = React.useState(true);
  const { header, isPreview, actions, children, notDismissible } = props;

  const actionLinks = actions.map((action, index) => {
    return (
      <ActionLink key={index} href="#" onClick={action.onClick} isPreview={isPreview}>
        {action.label}
      </ActionLink>
    );
  });

  return show ? (
    <OuterContainer isPreview={isPreview}>
      <Icon isPreview={isPreview} />
      <Content isPreview={isPreview}>
        <Header isPreview={isPreview}>{header}</Header>
        {children}
        <ActionContainer>{actionLinks}</ActionContainer>
      </Content>
    </OuterContainer>
  ) : null;
}
