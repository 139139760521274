import React from 'react';
import { dialogHeader } from './Dialog.styles';
import { DialogHeadding } from './DialogHeadding';
import { useDialogContext } from './Dialog';
import { DialogDismissButton } from './DialogDismissButton';

interface DialogHeaderProps {
  children: React.ReactNode | string;
}

export function DialogHeader({ children }: DialogHeaderProps) {
  const dialogContext = useDialogContext();
  const isStringHeader = typeof children === 'string';

  return (
    <div className={dialogHeader}>
      {isStringHeader ? (
        <>
          <DialogHeadding children={children} />
          {dialogContext?.dismissable && <DialogDismissButton />}
        </>
      ) : (
        children
      )}
    </div>
  );
}
