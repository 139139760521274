import * as React from 'react';
import {DeprecatedTokens} from "@visikon/spine/src/styles/tokens/deprecated-tokens";

interface IProps {
  active: boolean;
}
export class StarIcon extends React.Component<IProps> {
  render() {
    // star-solid.svg
    if (this.props.active) {
      return (
        <svg
          aria-hidden="false"
          focusable="false"
          data-prefix="fas"
          data-icon="star"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          aria-label=", Video er markeret til min liste"
        >
          <path
            d="m508.1 171.501-146.1-21.3-65.3-132.4c-11.7-23.60005-45.6-23.90005-57.4 0l-65.3 132.4-146.1 21.3c-26.20002 3.8-36.70002 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7l130.7-68.7 130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6Z"
            fill={DeprecatedTokens.favoriteActive}
          />
          <path
            d="m368.6 312.301 23.7 138.4-124.3-65.3-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98Z"
            fill={DeprecatedTokens.favoriteActive}
          />
        </svg>
      );
    }

    // star-regular.svg
    return (
      <svg
        aria-hidden="false"
        focusable="false"
        data-prefix="far"
        data-icon="star"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
        aria-label=", Video er ikke markeret til min liste "
      >
        <path
          d="m508.1 171.501-146.1-21.3-65.3-132.4c-11.7-23.60005-45.6-23.90005-57.4 0l-65.3 132.4-146.1 21.3c-26.20002 3.8-36.70002 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7l130.7-68.7 130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6Z"
          fill={DeprecatedTokens.favoriteInactive}
        />
        <path d="m368.6 312.301 23.7 138.4-124.3-65.3-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98Z" fill="#fff" />
      </svg>
    );
  }
}
