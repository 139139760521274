import {OmitNative, RouteProps} from "react-router";
import * as React from "react";
import {Route} from "react-router-dom";

export class AppRoute<T extends {} = {}, Path extends string = string> extends React.Component<
    RouteProps<Path> & OmitNative<T, keyof RouteProps> & {enabled?: boolean, key: string},
    any
    > {
    render() {
        return this.props.enabled !== false
            ? <Route {...this.props} />
            : undefined;
    }
}