import { I18N } from '../../internationalization/AppText';
import { ContentContainerType } from '../../reducers/resourcesReducer';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useContent } from '../../common/selectors';
import { ContentContainerComponent } from './ContentContainerComponent';
import { ContentConfig } from '../../common/ContentConfig';

export const ContentContainerScreenComponent: React.FC<PropsWithChildren & { fieldKey: keyof ContentConfig }> = ({ fieldKey }) => {
  const contentContainer = useContent<ContentContainerType>(fieldKey);
  return !contentContainer ? (
    <div>
      <I18N text="loading_dotdotdot" />
      ...
    </div>
  ) : (
    <ContentContainerComponent contentContainer={contentContainer} fieldKey={fieldKey} />
  );
};
