import styled from 'styled-components';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;
import { ABOVE_MOBILE_SIZE } from '@visikon/utils/src/responsive';
import { Unstyled } from './Unstyled';

export const Container = styled.nav<{ isHidden: boolean; showBack?: boolean; isHome?: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;

  height: ${({ showBack, isHidden }) => (!isHidden || (isHidden && showBack) ? '40px' : '0px')};
  z-index: 1001;
  padding: ${({ isHome }) => (isHome ? '16px 0 8px' : '8px 0 8px')};
  background: ${({ isHome }) => (isHome ? color.homeBackground : 'inherit')};

  @media all and ${ABOVE_MOBILE_SIZE} {
    background: ${color.white};
    padding: ${({ isHome }) => (isHome ? '16px 0 16px' : 'inherit')};
  }

  @media print {
    display: none;
  }

  // Divider
  &::before {
    content: '';
    display: ${({ isHome }) => (isHome ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: ${color.homeBackground};
    z-index: 1;
    height: 100%;
    padding: 8px 0;
    margin: 0 16px;
    border-bottom: 1px solid ${color.border};

    @media all and ${ABOVE_MOBILE_SIZE} {
      background: unset;
      padding: 0;
      margin: 0;
    }
  }
`;

export const BackButton = styled(Unstyled.Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.2800000011920929px;
  text-align: left;
  color: ${color.primary};
  cursor: pointer;
`;

export const LeftCell = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
  z-index: 2;

  & > a {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-box-align: unset;
    overflow: hidden;
  }

  @media all and (max-width: 500px) {
    & > a > button > svg {
      height: 5vw;
    }
  }
`;
export const RightCell = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
  z-index: 2;
`;
