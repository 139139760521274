import { useEffect, useState } from 'react';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';

export enum WindowSize {
  ExtraSmall = '(max-width: 600px)',
  Small = '(max-width: 767px)',
  Medium = '(max-width: 64em)',
  Tablet = '(max-width: 820px)',
}

export const MOBILE_SIZE = WindowSize.Medium;
export const ABOVE_MOBILE_SIZE = MOBILE_SIZE.replace('max', 'min');

export function useWindowSizeMatches(size: WindowSize) {
  return useMediaQuery(size);
}

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    try {
      media.addEventListener('change', listener);
    } catch (error) {
      console.log('media change listener :>> ', error);
    }

    return () => {
      try {
        return media.removeEventListener('change', listener);
      } catch (error) {
        console.log('media change listener :>> ', error);
      }
    };
  }, [matches, query]);

  return matches;
}

export const MAX_WIDTH_QUERY = `(max-width: ${Sizes.breakPoint})`;

export function useIsMobile(): boolean | undefined {
  return useMediaQuery(MAX_WIDTH_QUERY);
}
export function useIsMobileDevice(): boolean | undefined {
  return useMediaQuery(`screen and ${MAX_WIDTH_QUERY}`);
}
