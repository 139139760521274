import styled from 'styled-components';
import { FlexView } from './styled/ContentAreaContainers';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import { ABOVE_MOBILE_SIZE } from '@visikon/utils/src/responsive';

export const FlexWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  justify-content: center;

  @media print {
    display: block;
  }
`;

export const isHomeLevel = (navLevel?: number) => navLevel && navLevel === -1 && navLevel !== null;

export const MainContainer = styled.div<{ navLevel?: number }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1600px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);

  ${({ navLevel }) =>
    isHomeLevel(navLevel)
      ? `background:${Tokens.color.homeBackground};
         ion-toolbar { --background: ${Tokens.color.homeBackground};}
         ion-content { --ion-background-color: ${Tokens.color.homeBackground};
        }`
      : ``}
`;

export const ContentAndMenuContainer = styled(FlexView)`
  flex: 1;
  overflow: hidden;
`;

export const ContentContainer = styled.div<{ navLevel?: number }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: ${({ navLevel }) => (isHomeLevel(navLevel) ? '16px' : 'inherit')};

  @media all and ${ABOVE_MOBILE_SIZE} {
    margin-top: inherit;
  }
`;
