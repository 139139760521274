export const contentConfig = {
  programVideos: {
    path: 'video',
    icon: 'play',
    translationKeys: {
      menu: 'course',
      page: 'course',
    },
  },
  trainingVideos: {
    path: 'training',
    icon: 'pulse',
    translationKeys: {
      menu: 'exercise',
      page: 'exercise',
    },
  },
  instructionVideos: {
    path: 'instructions',
    icon: 'body',
    translationKeys: {
      menu: 'guides',
      page: 'guides',
    },
  },
  phamplets: {
    path: 'documents',
    icon: 'documents',
    translationKeys: {
      menu: 'pamphlets',
      page: 'pamphlets',
    },
  },
  symptoms: {
    path: 'symptoms',
    icon: 'heart',
    translationKeys: {
      menu: 'symptoms',
      page: 'symptoms',
    },
  },
  faq: {
    path: 'questions',
    icon: 'help',
    translationKeys: {
      menu: 'faq',
      page: 'faqs',
    },
  },
  help: {
    path: 'help',
    icon: 'medkit',
    translationKeys: {
      menu: 'help',
      page: 'help',
    },
  },
};

export const validContentKeys = Object.keys(contentConfig);
export type ContentConfig = { [K in keyof typeof contentConfig]?: (typeof contentConfig)[K] };
