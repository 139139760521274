import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import { Alignment } from '../Thumbnail';

export const DetailsCardContent = styled.section`
  margin: unset;
  padding: unset;
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
  background-color: ${DeprecatedTokens.whiteBackground};
  box-shadow: ${DeprecatedTokens.shadowColor} 0px 0px 5px;
  border-radius: 8px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    &:first-child {
      background-color: #fafafa;
      min-width: 210px;
      max-width: 210px;
      margin-right: 20px;
      align-items: center;
      text-align: center;
    }

    &:last-child {
      flex: 1;
    }
  }
`;

export const DetailsCard = styled.button`
  // reset button style
  padding: 0;
  font: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  text-align: left;

  position: relative;
  margin-top: 24px;
  cursor: pointer;
  border-radius: 8px;
  text-decoration: none;
  color: black;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-color: ${Tokens.color.border};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;

  background-color: ${DeprecatedTokens.whiteBackground};
  height: 137px;
  width: 75%;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    width: 100%;
  }

  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    height: 78px;
  }
`;

export const DetailsCardName = styled.div`
  font-weight: 600;
  font-size: 1em;
  position: relative;

  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    font-size: 0.8em;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  user-select: auto;
  padding: 5px 0;
  padding-left: 24px;

  background: white;
  z-index: 1;
`;

export const DetailsCardContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-bottom: 25px;
  text-align: center;

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    width: 100%;
  }
`;

export const Thumbnail = styled.div<{ aligned: Alignment }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  object-fit: contain;
  aspect-ratio: 16/9;
  width: 30%;

  @supports not (aspect-ratio: 16/9) {
    &::before {
      content: '';
      padding-top: 56.25%; /* 16:9 aspect ratio */
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-right: ${(props) => (props.aligned === 'left' ? '1px solid #e6e6e6' : 'none')};
    border-left: ${(props) => (props.aligned === 'right' ? '1px solid #e6e6e6' : 'none')};
    border-radius: ${(props) => (props.aligned === 'left' ? '8px 0 0 8px' : props.aligned === 'right' ? '0 8px 8px 0' : '0')};
  }

  & > img {
    user-select: none;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media all and (max-width: ${DeprecatedTokens.breakPointMid}) {
    width: 48%;
  }

  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    width: 35%;
  }

  @supports not (aspect-ratio: 16/9) {
    width: ${(props) => (props.aligned === 'none' ? 'none' : '100%')};
  }
`;
