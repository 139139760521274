import * as React from 'react';
import { SymptomData } from '../../reducers/resourcesReducer';
import { SymptomIcon } from '../../images/SymptomIcon';
import * as Styles from '../../components/styled/DetailsCard';
import * as SymStyles from './SymptomCardComponent.styles';
import { useToggle } from '@visikon/utils';
import { useImageFromUri } from '@visikon/utils/src/utils';
import Thumbnail from '../../components/Thumbnail';
import { Fader } from '../../common/Fader';
import { Chevron } from '../../components/styled/Chevron';
import { useEffect } from 'react';

export const renderText = (text: string) => {
  return text
    ?.split('\n')
    .filter((t) => t.trim() !== '')
    .map((t) => <SymStyles.TextBlock key={t}>{t}</SymStyles.TextBlock>);
};

export const renderDetails = (info: SymptomData['infoList'][0]) => {
  return (
    <SymStyles.Details key={info.text} severity={info.severity}>
      <div>{renderText(info.text)}</div>
      <div>
        <SymptomIcon severity={info.severity} />
        <span style={{ maxWidth: '100%' }}>{info.subtext}</span>
      </div>
    </SymStyles.Details>
  );
};


interface IProps {
  symptom: SymptomData;
  isOpen: boolean;
  onClick: () => void;
}

export const SymptomCardComponent = ({ symptom, isOpen, onClick }: IProps) => {
  const [modalVisible, toggleModal] = useToggle();
  const contentRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (isOpen) toggleModal();
  }, [isOpen]);

  const uri = symptom.thumb ? useImageFromUri(symptom.thumb._id) : '';


  useEffect(() => {
    if (isOpen && contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isOpen, contentRef.current]);

  return (
    <Fader>
      <section style={{ padding: 'unset', margin: 'unset' }} ref={contentRef}>
        <Styles.DetailsCard onClick={onClick}>
          <Thumbnail thumbUrl={uri} />
          <Styles.NameContainer>
            <Styles.DetailsCardName>{symptom.name}</Styles.DetailsCardName>
          </Styles.NameContainer>
          <Chevron isOpen={isOpen} />
        </Styles.DetailsCard>
        {isOpen && <Fader><Styles.DetailsCardContainer>{symptom.infoList?.map(renderDetails)}</Styles.DetailsCardContainer></Fader>}
      </section>
    </Fader>
  );
};
