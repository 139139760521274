/* eslint-disable react/jsx-no-script-url */
import React from 'react';
import { SvgContainer } from './svgicon-styles';

interface OwnProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  width?: number;
  height?: number;
  onClick?: () => void;
  className?: string;
}

type DivPropsWithoutRef = Omit<JSX.IntrinsicElements['div'], 'ref'>;
type StyledComponentsAsProp = { as?: string | React.ComponentType<any> };
type SvgIconProps = OwnProps & DivPropsWithoutRef & StyledComponentsAsProp;

export function SvgIcon(props: SvgIconProps) {
  const { Icon, width, height, className } = props;

  if (!Icon) return <></>;

  const svgHeight = height || width;
  return (
    <SvgContainer {...props}>
      <Icon width={width} height={svgHeight} className={className} />
    </SvgContainer>
  );
}
