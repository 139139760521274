import * as React from 'react';
import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';

const OuterWrapper = styled.aside`
  background-color: ${DeprecatedTokens.whiteBackground};
  display: flex;
  flex-direction: column;
  max-width: 329px;
  border-left: 1px solid ${DeprecatedTokens.border};

  // To support Older Safari < 13
  min-height: fit-content;

  @media screen and (max-width: ${DeprecatedTokens.breakPointMid}) {
    background-color: ${DeprecatedTokens.whiteBackground};
    flex-direction: column;
    max-width: 100%;
    box-shadow: unset;
    border-left: unset;
  }

  @media print {
    display: none;
  }
`;

const ContentWrapper = styled.div<{ addPadding?: boolean }>`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${({ addPadding }) => (addPadding ? '0' : '0.8em')};

  @media screen and (max-width: ${DeprecatedTokens.breakPoint}) {
    padding-top: ${({ addPadding }) => (addPadding ? '0' : '1.4em')};
  }
`;

interface OwnProps {
  children?: React.ReactNode;
}

export const SideBarContainer = ({ children }: OwnProps) => {
  if (!children) {
    return null;
  }

  return (
    <OuterWrapper>
      <ContentWrapper addPadding={false}>{children}</ContentWrapper>
    </OuterWrapper>
  );
};
