import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import StartCodeScreen from './StartCodeScreen';
import { tracker } from '@visikon/tracker/src';
import { LocalStorage } from '../../local-storage/startcodes-storage';
import { ProgramInfo, UNKNOWN_CODE, useAccessCode, useProgramInfo } from './api';
import { setProgramInfo } from '../../actions/resourcesActions';
import { backbone } from '@visikon/backbone/src';
import { useAuthenticateByCode } from '../../api/auth-api';
import { logOut, setStartCode } from '../../actions/authActions';
import { usePromise } from '../../common/utils';
import { ROOT_ROUTE } from '../../components/MainContent';

export const START_CODE_ROUTE = '/start';
export const START_CODE_PATH = `${START_CODE_ROUTE}/:startCode`;

export function StartCodeRouter(props: RouteComponentProps<{ startCode: string }>) {
  const { startCode } = props.match.params;
  const dispatch = useDispatch();
  dispatch(logOut());
  dispatch(setStartCode(startCode));
  return <ActualStartCodeRouter startCode={startCode} />;
}

function ActualStartCodeRouter(props: { startCode: string }) {
  const { startCode } = props;
  const programInfo = useProgramInfo(startCode);
  const accessCode = useAccessCode(startCode);
  useDirectAccessForwarder(accessCode, programInfo);
  if (!accessCode || !programInfo || (programInfo && programInfo.enableDirectAccess)) {
    return null;
  }
  return (
    <StartCodeScreen programName={programInfo.name} subName={programInfo.subName} userCode={accessCode !== UNKNOWN_CODE ? accessCode : startCode} />
  );
}

function useDirectAccessForwarder(accessCode?: string, programInfo?: ProgramInfo) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mutate: authenticate } = useAuthenticateByCode();
  const deviceId = usePromise<string>(LocalStorage.getDeviceId);
  useEffect(() => {
    if (!accessCode || !programInfo?.enableDirectAccess) {
      return;
    }

    tracker.trackScreen('StartCode');
    dispatch(setProgramInfo({ ProgramInfo: programInfo, history }));
    authenticate({ code: accessCode });

    if (deviceId) {
      backbone.update({ deviceId });
    }

    // Default direct access
    history.push(ROOT_ROUTE);
  }, [accessCode, programInfo, history]);
}
