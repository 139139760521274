import * as React from 'react';
import styled, { css } from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { IonInput } from '@ionic/react';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';

export const FlexColumnCenter: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const StaffLink = styled.a`
  // Remove button styling
  background: none;
  border: none;
  text-transform: none;
  letter-spacing: 0;
  padding: 0;
  margin: 0;

  margin-top: 24px;
  height: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration-line: underline;
  color: #1a1a1a;
  flex: none;
  order: 2;
  flex-grow: 0;
  cursor: pointer;
`;

export const TopBanner = styled.div`
  max-width: 35rem;
  margin: 0 4.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  // Small mobile screens
  @media all and (max-height: 38rem) {
    margin: 0 6.6rem;
  }
  // screen with height less than 700px
  @media all and (max-height: 700px) {
    max-width: 70vh;
  }
  // screen with less than 340px
  @media all and (max-width: ${Sizes.breakPointXSmall}) {
    max-width: 90vw;
  }
`;

export const LoginSection = styled.div`
  // Parent stuff
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;

  & > button {
    cursor: default;
  }
`;

export const LoginContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -70px;
`;
export const ContentWrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1em;
  justify-content: center;
  height: 100%;
`;
export const ActionButtonBox = styled.div`
  width: 284px;

  @media screen and (min-width: ${Sizes.breakPointSmall}) {
    width: unset;
  }
`;

export const LogoLanguage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0 8px;
  height: 40px;
  z-index: 1;
  background: ${Tokens.color.homeBackground};
`;

export const LoginTitle = styled.h2`
  font-size: 2.6em;
  font-weight: 300;
  color: ${DeprecatedTokens.primary};
  margin: 0.2em 0;

  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    font-size: 1.6em;
  }
`;
export const LoginTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  adjust-items: space-between;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FormLabel = styled.label`
  font-size: 0.88em;
  color: ${DeprecatedTokens.dark};
  font-weight: 400;
  margin-bottom: 0.4em;
  width: 350px;

  @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
    width: 250px;
  }
`;

export const FormTextInput = styled(IonInput)`
  margin-top: 0.4em;
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 22px;
  color: ${DeprecatedTokens.dark};
  padding: 10px 16px;
  border: 1px solid #cfd8dc;
  font-size: 1em;
  text-align: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 2em;

  & > button {
    @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
      width: 100%;
    }
  }

  & > p {
    position: absolute;
    top: 2.7rem;
    margin-top: 0;
    color: ${DeprecatedTokens.negative};
  }
`;

export const LoginImage = styled.img`
  width: 21em;
  text-align: left;
  align-self: start;
  margin-left: -5em;
  margin-top: 1em;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const GuidingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GuidingTitle = styled.button`
  // Remove button styling
  background: none;
  border: none;
  text-transform: none;
  letter-spacing: 0;
  padding: 0;
  margin: 0;

  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration-line: underline;
  color: #1a1a1a;
  cursor: pointer;
`;

export const ErrorMessage = styled.div`
  color: ${Tokens.colors.danger['500']};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  white-space: break-spaces;
  margin-bottom: 16px;
`;
export const SpinnerCodeField = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
  width: 284px;
`;

export const CodeInputLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 16px;
  text-align: center;
`;

const guidingTextStyle = css`
  display: flex;
  flex-direction: column;
  margin-top: 0;

  span {
    padding-top: 1rem;

    &:first-child {
      padding-top: 0;
    }
  }
`;

export const GuidingText = styled.p`
  ${guidingTextStyle}
`;

export const GuidingTextHelp = styled(GuidingText)`
  padding-top: 1rem;

  span {
    padding-top: 0;
    :first-child {
      font-weight: 700;
    }
  }
`;

export const FormContainer = styled.div`
  margin: 0 2em;
  max-width: 400px;
`;
