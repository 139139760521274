import * as React from 'react';
import { Content } from '@visikon/core-models';
import { MediaFileUtils } from '@visikon/utils';
import styled from 'styled-components';

const Image = styled.img`
  background-color: #eee;
  width: 100%;
  max-width: 400px;
  max-height: 300px;
  width: auto;
`;

interface IProps {
  imageId: string;
  language: Content.LanguageCode;
  alt: string;
  style?: any;
}

export class DocumentImage extends React.Component<IProps> {
  public render() {
    const { imageId, language, alt } = this.props;
    const uri = MediaFileUtils.getUriForImage(imageId, language);
    return <Image alt={alt} src={uri} />;
  }
}
