import * as React from 'react';
import styled from 'styled-components';
import { i18N, I18N } from '../../internationalization/AppText';
import { ProgramInfo } from '../../common/listPrograms';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;
import { Button, Dialog, DialogActionContainer, DialogAnimations, DialogBody, DialogHeader } from '@visikon/spine';

const RestoreBody = styled.div`
  color: ${color.text};
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
`;

type Props = {
  dialogRef: React.RefObject<HTMLDialogElement>;
  program: ProgramInfo;
  onConfirm: () => void;
};

export const RestoreProgramDialog = ({ dialogRef, program, onConfirm }: Props) => {
  return (
    <Dialog _ref={dialogRef} className={DialogAnimations.SlideDown}>
      <DialogHeader>{i18N('home_restore_program_title')}</DialogHeader>
      <DialogBody>
        <RestoreBody>
          <I18N text={'home_restore_program_body'} replace={{ $PROGRAM_NAME: program.name }} />
        </RestoreBody>
      </DialogBody>
      <DialogActionContainer>
        <Button size="small" onClick={onConfirm}>
          <I18N text="home_restore_program_accept" />
        </Button>
        <Button variant="ghost" size="small" onClick={() => dialogRef.current?.close()}>
          <I18N text="home_restore_program_cancel" />
        </Button>
      </DialogActionContainer>
    </Dialog>
  );
};
