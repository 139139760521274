import * as React from 'react';
import { useSelector } from 'react-redux';
import { IState } from '../../reducers/reducer';
import { PhampletWebView } from './PhampletWebView';
import { isUrlType } from '../../reducers/resourcesReducer';
import { getTranslation } from '@visikon/core-models/typeUtils';
import { useCountry, useWholeContent } from '../../common/selectors';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavLinkHelper } from '../../common/NavigationHelper';

export const PHAMPLET_WEB_ROUTE = '/documents/mytreatment/:textIndex';
export const PHAMPLET_ROUTE = '/documents/:textIndex';

export function PhampletWebRoute(): JSX.Element {
  const content = useWholeContent();
  const params = useParams<{ textIndex: string }>();
  const [title, setTitle] = React.useState<string>('');
  const [webData, setWebData] = React.useState<string>('');

  const textList = useSelector((state: IState) => state.resources.content?.phamplets?.data.textList) || [];
  const country = useCountry();
  const language = country.languageCode;

  const { isMobileOrHasNoMenu, contentNavLink, translatedPage, updateNavLink } = useNavLinkHelper();
  console.log('sdf');
  useEffect(() => {
    const nav = isMobileOrHasNoMenu ? contentNavLink : { title: translatedPage };
    updateNavLink(nav);
  }, [translatedPage]);

  useEffect(() => {
    if (params !== undefined) {
      const idx = Number(params.textIndex);
      console.log('PhampletWebRoute - idx', idx);
      if (idx >= 0 && idx < textList.length) {
        const result = textList[idx];
        if (result !== undefined && isUrlType(result.text)) {
          const trans = getTranslation(result.text, language);
          if (trans) {
            setWebData(trans.webData);
            setTitle(result.textTitle);
          }
        }
      }
    }
  }, [params, textList, language]);

  if (!content || !content.phamplets) {
    return <></>;
  }

  if (!webData) {
    console.log('PhampletWebRoute - No webData');
    return <></>;
  }

  return <PhampletWebView webData={webData} title={title} />;
}
