// tslint:disable: no-empty-interface
import React from 'react';
import { MapStateToProps, connect } from 'react-redux';
import { IState } from '../../reducers/reducer';
import { MitforlobContent } from '../../reducers/resourcesReducer';
import { I18N } from '../../internationalization/AppText';
import { MediaFileUtils } from '@visikon/utils/src';
import { Content } from '@visikon/core-models';
import styled from 'styled-components';
import { backbone } from '@visikon/backbone/src';
import { Button } from '@visikon/spine/src';

interface IPropsFromState {
  content?: MitforlobContent;
  language: Content.LanguageCode;
}
interface IProps {
  onStartCallback(): void;
}

const WelcomeContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  height: 100%;
  flex: 1;
  flex-direction: column;
`;

const TextAndImageWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  text-align: center;

  & > h1 {
    font-size: 1.6em;
  }

  & > img {
    max-height: 33%;
  }
`;

const ButtonContainer = styled.div`
  align-self: center;
  margin-bottom: 20px;
`;

interface IOwnProps {}

type Props = IOwnProps & IPropsFromState & IProps;

class WelcomeSurveyScreen extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { content, language } = this.props;

    if (content === undefined || content.survey === undefined || content.survey.data === undefined) {
      return null;
    }

    const { title, description, image } = content.survey.data;

    return (
      <WelcomeContainer>
        <TextAndImageWrapper>
          {image && <img alt="" src={MediaFileUtils.getUriForImage(image._id, language)} />}
          <h1>{title}</h1>
          <div>
            {(description || '').split('\n').map((n, index) => (
              <p key={index}>
                <span>{n}</span>
              </p>
            ))}
          </div>
        </TextAndImageWrapper>

        <ButtonContainer>
          <Button type="submit" onClick={this.props.onStartCallback}>
            <I18N text="next" />
          </Button>
        </ButtonContainer>
      </WelcomeContainer>
    );
  }
}
const mapStateToProps: MapStateToProps<IPropsFromState, IOwnProps, IState> = (state) => ({
  content: state.resources.content,
  language: backbone.store.getState().country.languageCode,
});

export default connect(mapStateToProps)(WelcomeSurveyScreen);
