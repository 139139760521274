import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';

export const HeaderIcon = styled.div`
  display: flex;
  flex-direction: column;
  color: ${DeprecatedTokens.primary};

  margin-left: 12px;
  margin-right: 12px;

  img {
    align-self: center;
  }

  a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: ${DeprecatedTokens.primary} img {
      align-self: center;
    }
  }

  .headerIcon-title {
    display: flex;
    padding-left: 6px;
    color: ${DeprecatedTokens.primary};
    font-size: 1em;

    @media all and (max-width: ${DeprecatedTokens.breakPointSmall}) {
      display: none;
    }
  }

  @media all and (max-width: ${DeprecatedTokens.breakPoint}) {
    & > div {
      font-size: 0.85em;
    }
  }
`;
